import React, { useEffect } from "react";
import "./css/apply.css";

function Center_Location({setdata,data,Errors}) {


  function handleChange(e) {
    let val=e.target.value
    let dummy = {...data}
    dummy.center_location = val
    setdata(dummy)
  }

  console.log(data)
  
 
  
  return (
    <div>

    <div className='row g-5 formDetails'>
    <div className="mb-3">
        <label className="form-label">Center Location <b className="text-danger">*</b></label>
        <select className="form-select" name="center_location" id="center_location" selected= {data["center_location"]} onChange={handleChange} >
            <option disabled selected value> -- select an option -- </option>
            <option value="Amritsar">Amritsar</option>
            <option value="Chandigarh">Chandigarh</option>
            <option value="Mohali">Mohali</option>
            <option value="Faridkot">Faridkot</option>
            <option value="Hoshiarpur">Hoshiarpur</option>
            <option value="Ludhiana">Ludhiana</option>
            <option value="Patiala">Faridkot</option>
            <option value="Rampura Phul">Rampura Phul</option>
            <option value="Budhlada">Budhlada</option>
        </select>                        
        <small className="logTxtDnger">{Errors.center_location}</small>                    
    </div>
    </div>

    </div>
  );
}

export default Center_Location;
