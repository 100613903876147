import React, { useEffect } from "react";
import "./css/apply.css";

function PersonalDetails({setdata,data,Errors,setErrors}) {
 

  const checkValidation = (setErrors, data ,key) => {
    if( data.family_detail[key] ==""){
      setErrors(p => (
        {
          ...p ,
          family_detail : {
            ...p.family_detail , 
            [key]: "This field is required"
          }
        }
      ))
      
    }
    else{
      setErrors(p => (
        {
          ...p ,
          family_detail : {
            ...p.family_detail , 
            [key]: ""
          }
        }
      ))
  
    }
   
  }

  const keyUp = (e) => {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.family_detail[id] = val
    
    checkValidation(setErrors, dummy, id)
  };

  function handleChange(e) {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.family_detail[id] = val
    setdata(dummy)
  }
  
 
  
  return (
    <div>

    <div className='row g-5 formDetails'>
          <div className='col-12 pb-3 pb-lg-0 col-lg-6 col-xl-5'> 
              <div className="mb-3">
                  <label className="form-label">Father's Name</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""              
                    value={data["family_detail"]["Father_Name"]}
                    id="Father_Name"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>               
                  <small className="logTxtDnger">{Errors.family_detail.Father_Name}</small>
              </div>

              <div className="mb-3">
                  <label className="form-label">Father's Qualification</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""                    
                    value={data["family_detail"]["Father_Qualification"]}
                    id="Father_Qualification"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>                    
                  <small className="logTxtDnger">{Errors.family_detail.Father_Qualification}</small>
              </div>

              <div className="mb-3">
                <label className="form-label">Mother's Name</label>
                <input className='form-control rounded'  type="text"
                  placeholder=""              
                  value={data["family_detail"]["Mother_Name"]}
                  id="Mother_Name"
                  onChange={handleChange}
                  onKeyUp={keyUp}/>               
                <small className="logTxtDnger">{Errors.family_detail.Mother_Name}</small>
              </div>

              <div className="mb-3">
                  <label className="form-label">Mother's Qualification</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""              
                    value={data["family_detail"]["Mother_Qualification"]}
                    id="Mother_Qualification"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>               
                  <small className="logTxtDnger">{Errors.family_detail.Mother_Qualification}</small>
              </div>             

              
          </div>
          <div className="col-12 col-lg-6 col-xl-5 offset-xl-2">
              <div className="mb-3">
                  <label className="form-label">Family Income</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""                    
                    value={data["family_detail"]["Family_Income"]}
                    id="Family_Income"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>                    
                  <small className="logTxtDnger">{Errors.family_detail.Family_Income}</small>
                </div>

                <div className="mb-3">
                  <label className="form-label">Father's Occupation</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""              
                    value={data["family_detail"]["Father_Occupation"]}
                    id="Father_Occupation"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>               
                  <small className="logTxtDnger">{Errors.family_detail.Father_Occupation}</small>
                </div>

                <div className="mb-3">
                  <label className="form-label">Mother's Occupation</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""                    
                    value={data["family_detail"]["Mother_Occupation"]}
                    id="Mother_Occupation"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>                    
                  <small className="logTxtDnger">{Errors.family_detail.Mother_Occupation}</small>
                </div>

                <div className="mb-3">
                  <label className="form-label">Agriculture Land</label>
                  <input className='form-control rounded'  type="text"
                    placeholder=""                    
                    value={data["family_detail"]["Agriculture_Land"]}
                    id="Agriculture_Land"
                    onChange={handleChange}
                    onKeyUp={keyUp}/>                    
                  <small className="logTxtDnger">{Errors.family_detail.Agriculture_Land}</small>
                </div>
          </div>
    </div>

    </div>
  );
}

export default PersonalDetails;
