import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function TabProgram({filters ,selected ,  setselected }) {

    function changeSelect(e , itm ) {
        console.log(e.target.checked  , itm)
        
        let dt = [...selected['Programme']]
        if (e.target.checked) { 
            if (!dt.includes(itm)) {
                dt.push(itm)
            }
        }
        else{
            dt = dt.filter(item => item !== itm)
        }
        setselected(p => (
            {
                ...p,
                'Programme' : dt 
            }
        ))


    }

    filters['Programme'].sort()


    return (
        <>
            <div className='filterDD'>
                <h2><span>List of Programs</span></h2>
                <form name='programsFltr'>
                    <div className='row'>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                            {filters['Programme'].slice(0,12).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Programme"].includes(itm)} onChange={(e) => changeSelect(e , itm )} type="checkbox" name="programs" />
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                            {filters['Programme'].slice(12,24).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Programme"].includes(itm)} onChange={(e) => changeSelect(e , itm )} type="checkbox" name="programs" />
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>

                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                            {filters['Programme'].slice(24,36).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Programme"].includes(itm)} onChange={(e) => changeSelect(e , itm )} type="checkbox" name="programs" />
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    </div>
                </form>
            </div>
        </>        
    )
}

export default TabProgram