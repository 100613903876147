import React , {useState} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TabYear from './tabs/tab-year';
import TabLocation from './tabs/tab-location';
import TabProgram from './tabs/tab-program';
import TabInstitute from './tabs/tab-institute';

function ResultFilter({results , setfilterResults}) {

    console.log(results , "RES")

    const [toggle, settoggle] = useState(false)
    const [fromToFilter, setfromToFilter] = useState(false)

    const [filters, setfilters] = useState({
        "To" : Array.from( new Set(results.map(itm => parseInt(itm['To'])  )) ).filter(itm => ! isNaN(itm)).sort() ,
        "From" : Array.from( new Set(results.map(itm => parseInt(itm['From']) ))).filter(itm => ! isNaN(itm)).sort() ,
        "Location" : Array.from( new Set(results.map(itm => itm['Location']))).filter(itm => itm != "None" ),
        "Programme" : Array.from( new Set(results.map(itm => itm['Programme']))).filter(itm => itm != "None" ),
        "Institute" : Array.from( new Set(results.map(itm => itm['Institute']))).filter(itm => itm != "None" )
    })
    
    console.log(filters , "FIL")

    const [selected, setselected] = useState({
        "From" : null , 
        "To" : null , 
        "Location" : [] , 
        "Programme" : [] , 
        "Institute" : []
    })

    function filterHandle() {
        let res = [...results]
        console.log(selected  , "SELEC" , fromToFilter)
        for (let k in selected ) {
            console.log(k)
            if (k == 'From' && selected[k] !=null){
                if (fromToFilter) {
                    res = res.filter(itm =>  selected[k] == itm['From'] )
                }
                else{
                    res = res.filter(itm =>  selected[k] <= itm['From'] )
                }
            }
            else if (k=='To' && selected[k] !=null ) {
                if (fromToFilter) {
                    res = res.filter(itm => selected[k] == itm['To'])
                }
                else{
                    res = res.filter(itm => selected[k] >= itm['To'])
                }

            }
            else if (k=='Location' && selected[k].length !=0) {

                res = res.filter(itm => selected[k].includes(itm['Location']))
            }
            else if (k=='Programme' && selected[k].length !=0) {

                res = res.filter(itm =>  selected[k].includes(itm['Programme']))
            }
            else if (k=='Institute' && selected[k].length !=0 ) {
                res = res.filter(itm => selected[k].includes(itm['Institute']))
            }

        }
        console.log(res , typeof(res), "REST")
        setfilterResults(res)

        setfilters({
            "To" : Array.from( new Set(res.map(itm => parseInt(itm['To']) ) ) ).filter(itm => ! isNaN(itm)).sort() ,
            "From" : Array.from( new Set(res.map(itm => parseInt(itm['From']) ))).filter(itm => ! isNaN(itm)).sort() ,
            "Location" : Array.from( new Set(res.map(itm => itm['Location']))).filter(itm => itm != "None" ),
            "Programme" : Array.from( new Set(res.map(itm => itm['Programme']))).filter(itm => itm != "None" ),
            "Institute" : Array.from( new Set(res.map(itm => itm['Institute']))).filter(itm => itm != "None" )
        })
    }

    function clearFilter() {
        setselected({
            "From" : null , 
            "To" : null , 
            "Location" : [] , 
            "Programme" : [] , 
            "Institute" : []
        })
        setfilterResults(results)
        setfilters({
            "To" : Array.from( new Set(results.map(itm => itm['To'] ) ) ).filter(itm => ! isNaN(itm)).sort() ,
            "From" : Array.from( new Set(results.map(itm => itm['From'] ))).filter(itm => ! isNaN(itm)).sort() ,
            "Location" : Array.from( new Set(results.map(itm => itm['Location']))).filter(itm => itm != "None" ),
            "Programme" : Array.from( new Set(results.map(itm => itm['Programme']))).filter(itm => itm != "None" ),
            "Institute" : Array.from( new Set(results.map(itm => itm['Institute']))).filter(itm => itm != "None" )
        })
    }



    return (
        <>
          
            <div className='mb-3 filterBar'>
                <div className='px-3 position-relative'>
                   
                   <div className='applyFltrBtn'>
                
                {toggle ?  
                   <button onClick={()=> clearFilter()} type="button" className='btn btn-secondary rounded-pill me-2 me-lg-0 ms-lg-2  px-xl-4'>Clear</button>
                    : null }

                   {toggle ? 
                    <>
                    
                    
                                        
                    <button onClick={()=> settoggle(p => !p)} type="button" className='btn btn-secondary rounded-pill me-2 me-lg-0 ms-lg-2  px-xl-4'>Search</button>
                     </> : 
                    <button onClick={()=> 
                        {
                            settoggle(p => !p)
                            filterHandle()
                        }
                    } type="button" className='btn btn-secondary rounded-pill me-2 me-lg-0 ms-lg-2 px-xl-4'>Apply</button> }

                    </div>
                    

                    
                    
                    {toggle ? 
                    <Tabs activeKey={toggle}>
                        <Tab eventKey={1} title="Year" onSelect={()=> console.log("fff")}>
                            <TabYear filters={filters} selected={selected} setselected={setselected} fromToFilter={fromToFilter} setfromToFilter={setfromToFilter}/>
                        </Tab>
                        <Tab eventKey={2} title="Location" onSelect={(e)=> console.log("fff")}>
                            <TabLocation filters={filters} selected={selected} setselected={setselected} />
                        </Tab>
                        <Tab eventKey={3} title="Program" onSelect={(e)=> console.log("fff")}>
                            <TabProgram  filters={filters} selected={selected} setselected={setselected} />
                        </Tab>
                        <Tab eventKey={4} title="Institute" onSelect={(e)=> console.log("fff")}>
                            <TabInstitute filters={filters} selected={selected} setselected={setselected}  />
                        </Tab>
                    </Tabs> :

                    <Tabs defaultActiveKey={1}>
                        <Tab eventKey={1} title="Year" onSelect={()=> console.log("fff")}>
                            <TabYear filters={filters} selected={selected} setselected={setselected} fromToFilter={fromToFilter} setfromToFilter={setfromToFilter}/>
                        </Tab>
                        <Tab eventKey={2} title="Location" onSelect={(e)=> console.log("fff")}>
                            <TabLocation filters={filters} selected={selected} setselected={setselected} />
                        </Tab>
                        <Tab eventKey={3} title="Program" onSelect={(e)=> console.log("fff")}>
                            <TabProgram  filters={filters} selected={selected} setselected={setselected} />
                        </Tab>
                        <Tab eventKey={4} title="Institute" onSelect={(e)=> console.log("fff")}>
                            <TabInstitute filters={filters} selected={selected} setselected={setselected} />
                        </Tab>
                    </Tabs> } 
                </div>
            </div>
        </>        
    )
}

export default ResultFilter