import React from 'react';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/stats-info/Renewal-Certificate_10-02-2023.pdf"></iframe>'
};

function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function ForeignDonation() {

    return (
        <div>  
            <div className='py-0'>
                <p className='text-center mb-4'>We got the permission for receiving the foreign donation vide Ministry of Home Affairs foreign Division (FCRA Wing) letter dated 27th September, 2017</p>

                <Iframe iframe={fileUrl["fdFile"]} />
            </div>
            <p>We accept foreign donations in our account with the State bank of India.</p>
            <p><strong>Bank Particulars are:</strong></p>
            
            <div className='pt-2'>
                <table className='table w-auto spacing mb-0'>
                    <tr>
                        <td>Account Name:</td>
                        <td><strong className='text-primary'>Bhai Jaita Jee Foundation India</strong></td>
                    </tr>
                    <tr>
                        <td>Bank Name:</td>
                        <td><strong className='text-primary'>State Bank of India</strong></td>
                    </tr>
                    <tr>
                        <td>Account No:</td>
                        <td><strong className='text-primary'>40088261429</strong></td>
                    </tr>
                    <tr>
                        <td>IFSC Code:</td>
                        <td><strong className='text-primary'>SBIN0000691</strong></td>
                    </tr>
                    <tr>
                        <td>MICR Code:</td>
                        <td><strong className='text-primary'>110002087</strong></td>
                    </tr>
                    <tr>
                        <td>Branch Code:</td>
                        <td><strong className='text-primary'>000691</strong></td>
                    </tr>
                    <tr>
                        <td>SWIFT Code:</td>
                        <td><strong className='text-primary'>SBININBB104</strong></td>
                    </tr>
                </table>
            </div>
        </div>
    )
}

export default ForeignDonation