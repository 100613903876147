import React from 'react';
import Footer from '../../Footer';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/pdf/March-2023/BJF-Stars-2023-25-Selected-List.pdf"></iframe>'
};

function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function SelectedCandidates2023To2025() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>Selected Candidates BJF Stars Program 2023-25</h1>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Iframe iframe={fileUrl["fdFile"]} />
                        <div className='pt-2 text-center'>
                            <a className='btn btn-primary btn-md' href="/pdf/March-2023/BJF-Stars-2023-25-Selected-List.pdf" download="Selected Candidates BJF Stars Program 2023-25">Download</a>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default SelectedCandidates2023To2025