import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

import { useState } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

const getOptions = (type , x_axis , y_axis) => ({
    chart: {
     type,
      width: 940,
      height: 450,
    },
    colors: ['#00355B'],
    title: {
      text: _.startCase(``),
    },

    xAxis: {
      categories: x_axis
  },

    yAxis: {
      title: {
        text: 'Number of Students',
      },
    },

    series: [
      {
        data: y_axis,
      },
    ],
  });

function PassedOutGraph({ x_axis , y_axis }) {    
  console.log(x_axis , y_axis)

    const [startDate, setstartDate] = useState(null)
    const [stopDate, setstopDate] = useState(null)
    
    return (
        <>
           <div className='graphHldr'>
                <div className='d-flex justify-content-between align-items-center flex-wrap mb-3'>
                    <div className='ps-2 pb-3 pb-xl-0'>
                        <h6 className='m-0'>Total Number of Students Passed Out</h6>
                    </div>
                    
                </div>
                <HighchartsReact highcharts={Highcharts} options={getOptions('column' , x_axis , y_axis )} />
           </div> 
        </>        
    )
}

export default PassedOutGraph