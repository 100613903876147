import React, { useState, useEffect, useRef } from 'react';
import '../css/Header.css';
import MainHdrTop from './MainHdrTop';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { URL1 } from "../config";
import { loggedOut } from "../redux/authAction";
import { Navbar} from 'react-bootstrap';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';

function Header() {

  const Data = useSelector(state => state)
  let dispatch = useDispatch();
  let location = useLocation();
  console.log(location)
  let ref = useRef()

  async function logout() {

    let res = await fetch(URL1 + '/logout', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': 'Bearer ' + Data.auth['access_token']
      },

    })
    let resJson = await res.json()
    dispatch(loggedOut())
    setTimeout(() => {
      localStorage.getItem("persist:root");
      localStorage.removeItem("persist:root");
    }, 500);

  }

  const [scroll, setScroll] = useState(false)


  useEffect(() => {

   
    const handleScroll = () => {
      setScroll(window.scrollY > 10)
    }
    const handleMouseOver = () => setIsOpened(true)
    const handleMouseOut = () => setIsOpened(false)
 
    window.addEventListener("scroll", handleScroll)

    if (ref.current) {
      ref.current.addEventListener("mouseover", handleMouseOver);
      ref.current.addEventListener("mouseout", handleMouseOut);
    }

      return () => {
        if(ref.current){
          ref.current.removeEventListener("mouseover", handleMouseOver);
          ref.current.removeEventListener("mouseout", handleMouseOut);

        }
        window.removeEventListener("scroll", handleScroll )
      };

  }, [])

  const checkActive = (match, location) => {
    //some additional logic to verify you are in the home URI
    if(!location) return false;
    const {pathname} = location;
    console.log(pathname);
    return pathname === "/";
}

const [isOpened, setIsOpened] = useState(false);
function menuToggle() {
  setIsOpened(wasOpened => !wasOpened);
}

  return (
    <div className={scroll ? "header scrolled" : "header"}>

    <Navbar expand="xl" className='pb-xl-0'>
        <div className="container-xl">
          <div className='d-flex w-100'>
            <Link to="/" className='navbar-brand'>
              <img className='logo' src='/svg/logo-header.svg' alt="logo" />
            </Link>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav" className="navbar-collapse flex-grow-1 justify-content-end">
                <div className="navbar-nav flex-column ml-auto mb-2 mb-lg-0">
                  <div className='pb-2 text-end'>
                    <MainHdrTop />
                  </div>
                  <div className='text-end'>
                    <ul className="nav d-xl-inline-flex ml-lg-auto">
                      <li className='nav-item'>
                      <NavLink exact to='/' end className='nav-link' activeClassName="active">
                          Home
                        </NavLink>
                        
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/aboutus" end className='nav-link' activeClassName="active">
                          About Us
                        </NavLink>
                      </li>
                      <li ref={ref} className='nav-item position-relative'>
                          <div className='ddItem'>
                            <NavLink className='nav-link' exact activeClassName="active" to="/admission">
                              Admissions
                            </NavLink>
                            <button type="button" className='btnCaret' onClick={menuToggle}>
                              <KeyboardArrowDownIcon />
                            </button>
                          </div>

                          {isOpened && (
                              <div className="ddMenuItems">
                                  <a href="/admission">Admissions</a>
                                  <a href="/admission/bjf_stars">BJF Stars</a>
                                  <a href="/admission/bjf_headstart">BJF Headstart</a>
                                  <a href="/admission/bjf_repeaters">BJF Repeaters</a>
                                  <a href="/admission/bjf_navodaya">BJF Navodaya Mission</a>
                              </div>
                          )}                        
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/life-at-campus" end className='nav-link' activeClassName="active">
                          Life at Campus
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/resultss" end className='nav-link' activeClassName="active">
                          Results
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/statutory-info" end className='nav-link' activeClassName="active">
                          Statutory Information
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/donations" end className='nav-link' activeClassName="active">
                          Donations
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/medias" end className='nav-link' activeClassName="active">
                          Media/News
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink exact to="/contact-us" end className='nav-link' activeClassName="active">
                          Contact Us
                        </NavLink>
                      </li>
                      
                      {
                        Data.auth.isSignedIn ?
                      <li className='nav-item'>
                        <NavLink className='nav-link' exact to="/login"  onClick={()=> {
                          localStorage.getItem("persist:root");
                          localStorage.removeItem("persist:root");
                          localStorage.clear();
                          dispatch(loggedOut())
                        }}>
                          <LogoutIcon /> Log Out
                        </NavLink>
                      </li> : 
                      <li className='nav-item'>
                      <NavLink className='nav-link' exact to="/login">
                        <PersonIcon /> Login/Signup
                      </NavLink>
                    </li>
                      }
                      
                    </ul>
                  </div>
                </div>
            </Navbar.Collapse>
          </div>
        </div>
      </Navbar>
    </div>

  )
}

export default Header