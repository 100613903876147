import React from 'react';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/pdf/screening-test-roll-numbers-2024-2026/mansa.pdf"></iframe>'
};

function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function Mansa() {

    return (
        <div>  
            <div className='py-0'>
                <Iframe iframe={fileUrl["fdFile"]} />
            </div>
        </div>
    )
}

export default Mansa