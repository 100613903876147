import React from "react";
import "./css/apply.css";

function EducationDetails11({setdata,data,Errors,setErrors}) {

  const checkValidation = (setErrors, data ,key) => {
    if( data.education[key] ==""){
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: "This field is required"
          }
        }
      ))
      
    }
    else if (key == 'Pincode' && /^[0-9][0-9][0-9][0-9][0-9][0-9]$/.test(data.education[key]) ==false  ) {
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: "Pincode can only have 6 digits"
          }
        }
      ))
    }

    else if (key == 'Maths_Marks_9' || key == 'Science_Marks_9' ||  key == 'Overall_Percentage_9' ) {
      if (/^(0|[1-9][0-9]?|100)$/.test(data.education[key]) ==false){
        setErrors(p => (
          {
            ...p ,
            education : {
              ...p.education , 
              [key]: "marks percentage should be in 0 to 100"
            }
          }
        ))

      }
      else{
        setErrors(p => (
          {
            ...p ,
            education : {
              ...p.education , 
              [key]: ""
            }
          }
        ))
      }
    }

    else if (data.education[key].length <3 ){
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: "It should have atleast 3 characters"
          }
        }
      ))
    }
    else{
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: ""
          }
        }
      ))
  
    }
   
  }

  const keyUp = (e) => {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.education[id] = val
    
    checkValidation(setErrors, dummy, id)
  };

  function handleChange(e) {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.education[id] = val
    setdata(dummy)
  }


  return (
    <div>
      <div className='row g-5 formDetails'>
              <div className='col-12 pb-3 pb-lg-0 col-lg-6 col-xl-5'> 
                  <div className="mb-3">
                      <label className="form-label">School Name <b className="text-danger">*</b></label>
                        <input className='form-control rounded'  type="text"
                          placeholder=""                          
                          value={data["education"]["School_Name"]}
                          id="School_Name"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.education.School_Name}</small>
                  </div>

                  <div className="mb-3">
                        <label className="form-label">School Address Line 1 <b className="text-danger">*</b></label>
                        <input className='form-control rounded'  type="text"
                          placeholder=""                          
                          value={data["education"]["School_Address"]}
                          id="School_Address"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.education.School_Address}</small>
                  </div>

                  <div className="mb-3">
                      <label className="form-label">School Address Line 2 <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="text"
                        placeholder=""                        
                        value={data["education"]["School_Address2"]}
                        id="School_Address2"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.education.School_Address2}</small>
                  </div>

                  <div className="mb-3">
                     <label className="form-label">Pincode <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="number"
                        placeholder=""                        
                        value={data["education"]["Pincode"]}
                        id="Pincode"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.education.Pincode}</small>                    
                  </div>

              </div>
              <div className="col-12 col-lg-6 col-xl-5 offset-xl-2">
                  <h6>Marks Obtained in IX</h6>
                  <div className="mb-3">
                      <label className="form-label">Board <b className="text-danger">*</b></label>
                      <select className="form-select" name="Board" id="Board" selected= {data["education"]["Board"]} onChange={handleChange} >
                        <option disabled selected value> -- select an option -- </option>
                        <option value="cbse">CBSE</option>
                        <option value="punjab">PUNJAB</option>
                        <option value="icse">ICSE</option>
                      </select>                        
                      <small className="logTxtDnger">{Errors.education.Board}</small>                    
                  </div>

                  <div className="mb-3">
                      <label className="form-label">Maths <b className="text-danger">*</b></label>
                        <input className='form-control rounded'  type="number"
                          placeholder=""                          
                          value={data["education"]["Maths_Marks_9"]}
                          id="Maths_Marks_9"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.education.Maths_Marks_9}</small>
                  </div>

                  <div className="mb-3">
                      <label className="form-label">Science <b className="text-danger">*</b></label>
                      <input className='form-control rounded'  type="number"
                        placeholder=""                        
                        value={data["education"]["Science_Marks_9"]}
                        id="Science_Marks_9"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.education.Science_Marks_9}</small>                    
                  </div>

                  <div className="mb-3">
                      <label className="form-label">Overall Percentage <b className="text-danger">*</b></label>
                        <input className='form-control rounded'  type="number"
                          placeholder=""                          
                          value={data["education"]["Overall_Percentage_9"]}
                          id="Overall_Percentage_9"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.education.Overall_Percentage_9}</small>                    
                  </div>
              </div>
      </div>

    </div>
  );
}

export default EducationDetails11;
