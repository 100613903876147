import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { URL1 } from '../config';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../loading_small';

export const DeleteAnn = ({id , setSmShow ,fetchData}) => {
    const Data = useSelector(state => state)
    const [load, setload] = useState(false)
    const [msg, setmsg] = useState("Are you sure want to delete this Announcement?")
    
    async function deleteAnnouncement(id) {
        try{
          setload(true)
          let res = await fetch(URL1 + `/delete_announcement` , {
            method : "POST" , 
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Data.auth["access_token"],
            },
            body : JSON.stringify({
                "an_id" :id
            })
          })
          let resJson = await res.json()
          if (res.status == 200) {
            fetchData()
            setload(false)
            setSmShow(false)

          }
          else{
            setmsg("Unable to Delete , Please try again")
            setload(false)
          }
        }
        catch (err) {
            setload(false)
        }
        
      }


  return (
    <div>
        <Modal
          size="md"
          show={true}
          onHide={() => setSmShow(false)}
          centered
          >
            <Modal.Body>
              {load ? <Loading/> : 
              <div className='text-center'>
                <h6 className='mb-4'>{msg}</h6>
                <Button className='mx-3' variant="primary" type="button" size="lg" onClick={() => { deleteAnnouncement(id) }}>
                  Yes, Delete
                </Button>
                <Button className='mx-3' variant="outline-secondary" type="button" size="lg" onClick={() => setSmShow(false)}>
                  Close
                </Button>
              </div> }
            </Modal.Body>
      </Modal> 
    </div>
  )
}
