import '../css/LogForm.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { URL1 } from "../config";
import { useDispatch } from 'react-redux';
import { loggedIn } from '../redux/authAction';
import { Loading } from "../loading";
import LogSideBox from './logSidebox.js';

function OTP() {

  let location = useLocation();
  console.log(location)
  const [otp, setotp] = useState("")
  const [Errors, setErrors] = useState({})
  const history = useNavigate()
  const dispatch = useDispatch();
  const [load, setload] = useState(false)

  function inpChange(e) {
    setotp(e.target.value)
  }

  function keyUp(e) {
    let err = validate()
    if (!_.isEmpty(err)) {
      setErrors(err)
    }
    else {
      setErrors({})
    }
  }

  function validate() {
    const errors = {};
    if (!otp) {
      errors.otp = "Enter OTP";
    }


    return errors;
  }

  async function submit() {
    let err = validate()

    if (!_.isEmpty(err)) {
      setErrors(err);
    } else {
      setload(true)
      setErrors({});

      let res = await fetch(URL1 + '/create_user', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": location.state.email,
          "password": location.state.password,
          "name": location.state.name,
          "otp": parseInt(otp)
        })
      })
      let resJson = await res.json()
      if (res.status == 200) {

        dispatch(loggedIn(resJson['auth_token'], resJson['name'], resJson['role'] , resJson['register_id']))
        history('/')
      }
      else {
        let err = { "message": resJson['message'] }
        setErrors(err)
      }
      setload(false)

    }
  }

  useEffect(() => {
    document.body.className = 'logUI';
    return () => { document.body.className = ''; }
  });


  return (

    <div>
          {load ? <Loading /> : <>

          <div className='row g-0'>
            <div className='col-12 col-lg-7'>
              <div className='logFormOuter'>
                  <div className='logForm p-5 text-center'>
                    <div className='mb-4'>
                      <Link to="/" className='navbar-brand d-inline-block text-center'><img className='logo' src='/svg/logo-header.svg' alt="logo" /></Link>
                    </div>
                    <h1 className='mb-5'>Enter OTP</h1>
                    <div className='mb-4 related'>
                      <p>Enter OTP</p>
                      <input className='usern_registered form-control rounded-pill'
                        type='text' placeholder='OTP' value={otp} onKeyUp={keyUp} onChange={inpChange} />
                      <span className='rerrors'>{Errors.otp}</span> 
                    </div>
                    <div className='d-grid mb-3'>
                      <button className='reset_mail_btn btn btn-lg btn-primary rounded-pill' onClick={submit}>Verify OTP</button>                      
                    </div>
                    <p className='text-center'><strong className='rerrors'>{Errors.message}</strong> </p>
                    <p className='account text-center'>Go back to the login page &nbsp;
                        <Link to='/login' className='register'>from here</Link>
                    </p>
                  </div>
              </div>
            </div>

            <div className='col-12 col-lg-5'>
                <LogSideBox />
            </div> 
          </div>            
            
          </>
          }
    </div>
  )
}

export default OTP