import '../css/LogForm.css';
import { Link, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { URL1 } from "../config";
import { useDispatch } from 'react-redux';
import { loggedIn } from '../redux/authAction';
import { Loading } from "../loading";
import { ChangePop } from './changePop';
import LogSideBox from './logSidebox.js';

function ChangePassword() {

  let location = useLocation();
  console.log(location , location.state.email)
  const [otp, setotp] = useState("")
  const history = useNavigate()
  const dispatch = useDispatch();
  const [load, setload] = useState(false)
  const [pop, setpop] = useState(false)
  const [msg, setmsg] = useState("")

  const [passData, setpassData] = useState({
    
    "otp": "" , 
    "password1": "" , 
    "password2": "",
    "email": location.state.email
  })

  const [Errors, setErrors] = useState({
    "otp": "" , 
    "password1": "" , 
    "password2": "",
    "email": ""
  })

  function inpChange(e) {
    switch (e.target.id) {
      case 'otp':
        setpassData({ ...passData, ["otp"]: e.target.value })
        break;
      case 'password1':
        setpassData({ ...passData, ["password1"]: e.target.value })
        break;
      case 'password2':
        setpassData({ ...passData, ["password2"]: e.target.value })
        break;
      default:
        break;
    }
  }

  function keyUp(e) {
    var id = e.target.id 
    var val = e.target.value
    let dummy = {...passData}
    dummy[id] = val 
    let err = validate(id , dummy)
    if (!_.isEmpty(err)) {
      setErrors(err)
    }
    else {
      setErrors({})
    }
  }

  function validate(id , dummy) {


    const errors = {};
    if(id == 'otp'){
        if (dummy[id].length ==0){
            errors['otp'] = "Please enter otp"
        }

    }
    else if(id == 'password1'){
        if (dummy[id].length <6){
            errors['password1'] = "Password must be of atleast 6 characters"
        }

    }
    else if(id == 'password2'){
        if (dummy['password1'] != dummy['password2']){
            errors['password2'] = "Password not match"
        }

    }
    else if(id == 'all'){
        if (dummy[id].length ==0){
            errors['otp'] = "Please enter otp"
        }
        if (dummy[id].length <6){
            errors['otp'] = "Password must be of atleast 6 characters"
        }
        if (dummy['password1'] != dummy['password2']){
            errors['password2'] = "Password not match"
        }

    }




    return errors;
  }

  async function submit() {
    let err = validate()

    if (!_.isEmpty(err)) {
      setErrors(err);
    } else {
      setload(true)
      setErrors({});

      let res = await fetch(URL1 + '/change_password', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email":passData.email,
          "otp": parseInt(passData.otp),
          "new_password_1" : passData.password1 ,
          "new_password_2" : passData.password2 ,
        
        })
      })
      let resJson = await res.json()
      if (res.status == 200) {
        //popup
        setpop(true)
        setmsg("Successfully changed password")

      }
      else {
        let err = { "message": resJson['message'] }
        setErrors(err)
      }
      setload(false)

    }
  }

  console.log(Errors,"FRt")


  useEffect(() => {
    document.body.className = 'logUI';
    return () => { document.body.className = ''; }
  });


  return (

    <div>
      {pop ? <ChangePop  setpopup={setpop} msg={msg} red={'/'}/> : 


        <div className='forgot'>
          {load ? <Loading /> : <>


          <div className='row g-0'>
            <div className='col-12 col-lg-7'>
              <div className='logFormOuter'>
                  <div className='logForm p-5 text-center'>
                    <div className='mb-4'>
                      <Link to="/" className='navbar-brand d-inline-block text-center'><img className='logo' src='/svg/logo-header.svg' alt="logo" /></Link>
                    </div>
                    <h1 className='mb-3'>Change Password</h1>
                    <div className='mb-4 related'>
                      <p>Enter OTP</p>
                      <input id='otp' className='usern_registered form-control rounded-pill'
                        type='text' placeholder='OTP' value={passData.otp} onKeyUp={keyUp} onChange={inpChange} />
                      <span className='rerrors'>{Errors.otp}</span> 
                    </div>
                    
                    <div className='mb-4 related'>
                        <p>Password</p>
                        <input value={passData.password1} id='password1' className='usern_registered form-control rounded-pill' type='text' placeholder='Password' onChange={inpChange} onKeyUp={keyUp} />
                        <span className='rerrors'>{Errors.password1}</span>
                    </div>

                    <div className='mb-4 related'>
                        <p>Confirm Password</p>
                        <input value={passData.password2} id='password2' className='usern_registered form-control rounded-pill' type='text' placeholder='Confirm Password' onChange={inpChange} onKeyUp={keyUp} />
                        <span className='rerrors'>{Errors.password2}</span>
                    </div>

                    <div className='d-grid mb-3'>
                      <button className='reset_mail_btn btn btn-lg btn-primary rounded-pill' onClick={submit}>Done</button>
                      <div className='pt-2 text-center'><strong className='rerrors'>{Errors.message}</strong> </div>
                    </div>
                    <p className='account text-center'>Go back to the login page &nbsp;
                        <Link to='/login' className='register'>from here</Link>
                    </p>
                    <hr className="my-3" />
                    <p className="text-center">
                      In case you are facing any issues, please drop an email at <a href="mailto:indiabjf@gmail.com">indiabjf@gmail.com</a>
                    </p>
                  </div>
              </div>
            </div>

            <div className='col-12 col-lg-5'>
                <LogSideBox />
            </div> 
          </div>  

            
          </>
          }
      </div> }
    </div>
  )
}

export default ChangePassword