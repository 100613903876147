import React , {useState , useEffect} from 'react';
import { SideNav } from './adminSideNav';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import "./css/adminUI.css";
import { useNavigate} from "react-router-dom"
import { EditMedia } from './editMedia';
import { URL1 } from '../config';
import { Loading } from '../loading_small';
import { useSelector } from 'react-redux';
import { AnnouncementPop } from './announcementPop';
import { DeleteNews } from './deleteNews';

export const AdminMediaList = () => {
    const Data = useSelector(state => state)
    const [load, setload] = useState(false)
    const [msg, setmsg] = useState("")
    const [pop, setpop] = useState(false)
    const [smShow, setsmShow] = useState(false)

    let navigate = useNavigate()
    const [newsList, setnewsList] = useState([])
    const [news, setnews] = useState(null)

    function formatDate(date) {
        var date = new Date(date)
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
      
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date =  dd + "/" + mm + "/" + yyyy;
        return date.toString();
        
      }

      async function publishIt(tf , itm) {
        try{
            var pbls = 'no'
            if(tf){
                pbls = 'yes'
            }

            let dt = {...itm}   
 
                dt[3] = pbls
                let fd = new FormData()
                fd.append("news_id" , dt[0])
                fd.append("title" , dt[1])
                fd.append("category" , dt[2])
                fd.append("publish" , pbls)
                fd.append("files[]", [])

                for (let index = 0; index < dt[5].length; index++) {
                    fd.append("photo_ids[]", dt[5][index]) 
                    
                }
                

                setload(true)
                let res = await fetch(URL1 + '/edit_news' , {
                method : "POST" , 
                headers: {
                    Authorization: "Bearer " + Data.auth["access_token"],
                },
    
                body : fd
                })
                if (res.status == 200) {
                    setload(false)
                    setpop(true)
                    setmsg("Successfuly Executed")
                }
                else{
                    setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
                    }

            
            
        }
        catch (err) {      
            setload(false)
            setpop(true)
            setmsg("Error Occured while processing")
        }
    }

    async function fetchData(){
        try {
            setload(true)
            let res= await fetch(URL1+'/get_news' ,{
                method: "GET" , 
                headers: {"Content-Type": "application/json"},

            })
            let resJson = await res.json()
            if(res.status == 200){
                setnewsList(resJson.data)
            }

            setload(false)

        } catch (error) {
            setload(false)

        }
    }

    useEffect(() => {
        fetchData()
     
    }, [])
    


  return (
    <div>
    {pop ? <AnnouncementPop setpopup={setpop} setann={setnews} msg={msg} fetchData={fetchData}/> :

        <div className='inrPageTPAdmin'>
             {smShow !=false ? <DeleteNews id={smShow} setSmShow={setsmShow} fetchData={fetchData} /> :
            <div className='row g-0'>
                <div className='col-12 col-lg-3 bdrR'>
                    <div className='pt-4 ps-3'>
                        <SideNav active={'media'}/>
                    </div>
                </div>
                {news !=null ? <EditMedia news={news} fetchData={fetchData} setnews={setnews} />  :
                <div className='col-12 col-lg-9'>
                    <div className='p-4'>
                        <div className='text-end mb-3'>
                            <button onClick={()=> {setnews(-1) }} className='btn btn-primary'><span className='d-inline-block fs-4' >+</span> Add New Media/News</button>
                        </div>
                        {load ? <div className='pt-5 text-center'><Loading/></div> :  
                        <div className='cListTable announce'>                              
                            <table className='table cTable'>
                                <thead>
                                    <tr>
                                        <th className='col-3'>Title</th>
                                        <th className='col-2'>Picture's count</th>
                                        <th className='col-2'>Category</th>
                                        <th className='col-1'>Publish</th>
                                        <th className='col-2'>Last Modified</th>
                                        <th className='col-2 text-end'>Action</th>             
                                    </tr>
                                </thead>   
                                <tbody>
                                    {newsList.map((itm , ind)  => {
                                        return (
                                            <tr>
                                                <td>
                                                   {itm[1]}
                                                </td>
                                                
                                                <td>
                                                   {itm[5].length}
                                                </td>
                                                <td>{itm[2]}</td>
                                                <td>
                                                    <div className='cstCheckFld'>
                                                        <input type='checkbox' checked={itm[3] == "yes"} onChange={(e)=> publishIt(e.target.checked , itm)} />
                                                        <label></label>
                                                    </div>
                                                </td>
                                                
                                                <td>{formatDate(itm[4]) }</td>

                                                <td>
                                                    <button type='button' className='btn btn-white btn-sm ml-3' onClick={()=> setnews(itm)}><ModeEditIcon />Edit</button>
                                                    <button type='button' className='delBtn ms-3' title='Delete' onClick={()=> setsmShow(itm[0])}></button>
                                                </td>
                                            </tr>  
                                        )
                                    })}
                                                          
                                </tbody>                 
                            </table>
                        </div> }
                    </div>
                </div>}
            </div>}
        </div>}
    </div>
  )
}
