//import React, { useState } from 'react'
import { useEffect, useState } from 'react';
import '../css/LogForm.css';
import { Link, useNavigate } from 'react-router-dom';
import _ from "lodash";
import { useDispatch } from 'react-redux';
import { loggedIn } from '../redux/authAction';
import { URL1 } from "../config";
import { Loading} from "../loading";
import LogSideBox from './logSidebox.js';


function Register() {
  const [registerData, setRegisterData] = useState({ "email": "", "password": "", "confirmpass": "", "username": "" })
  const [Errors, setErrors] = useState({})
  const dispatch = useDispatch();
  const history = useNavigate()
  const [load, setload] = useState(false)

  function inpChange(e) {
    switch (e.target.id) {
      case 'email':
        setRegisterData({ ...registerData, ["email"]: e.target.value })
        break;
      case 'username':
        setRegisterData({ ...registerData, ["username"]: e.target.value })
        break;
      case 'pass1':
        setRegisterData({ ...registerData, ["password"]: e.target.value })
        break;
      case 'pass2':
        setRegisterData({ ...registerData, ["confirmpass"]: e.target.value })
        break;
      default:
        break;
    }
  }

  function keyUp(e) {
    console.log(registerData.email)
    let err = validate(e.target.id)
    if (!_.isEmpty(err)) {
      setErrors(err)
    }
    else {
      setErrors({})
    }
  }

  function validate(key) {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    switch (key) {
      case 'email':
        if (!registerData.email) {
          errors.email = "Email is required";
        }
        else if (!regex.test(registerData.email)) {
          errors.email = "This is not a valid email format";
        }
        break;
      case 'pass1':
        if (!registerData.password) {
          errors.password = "Password is required";
        }
        else if (registerData.password.length < 4) {
          errors.password = "Password must be more than 4 characters";
        }
        else if (registerData.password.length > 10) {
          errors.password = "Password cannot exceed more than 10 characters";
        }
        break;

      case 'pass2':
        if (!registerData.confirmpass) {
          errors.confirmpass = "Confirm Password is required";
        }
        else if (registerData.confirmpass != registerData.password) {
          errors.confirmpass = "Confirm Password doesn't match"
        }

      case 'username':
        if (!registerData.username) {
          errors.username = "Username is required";
        }
        break;

      case 'all':
        if (!registerData.email) {
          errors.email = "Email is required";
        }
        else if (!regex.test(registerData.email)) {
          errors.email = "This is not a valid email format";
        }
        if (!registerData.password) {
          errors.password = "Password is required";
        }
        else if (registerData.password.length < 4) {
          errors.password = "Password must be more than 4 characters";
        }
        else if (registerData.password.length > 10) {
          errors.password = "Password cannot exceed more than 10 characters";
        }
        if (!registerData.confirmpass) {
          errors.confirmpass = "Confirm Password is required";
        }
        else if (registerData.confirmpass != registerData.password) {
          errors.confirmpass = "Confirm Password doesn't match"
        }
        if (!registerData.username) {
          errors.username = "Username is required";
        }

      default:
        break;
    }

    return errors;
  }

  async function submit() {
    let err = validate('all')

    if (!_.isEmpty(err)) {
      console.log(err, "smdjshdjkskfhjkdshfdshkjds")
      setErrors(err);
    } else {
      setload(true)
      setErrors({});

      let res = await fetch(URL1 + '/send_otp', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": registerData.email,
          
        })
      })
      let resJson = await res.json()
      if (res.status == 200) {
        history('/otp',{state:  { "email": registerData.email , "password" : registerData.password , "name" : registerData.username }})
      }
      else {
        let err = { "message": resJson['message'] }
        setErrors(err)
      }
      setload(false)
      // dispatch(loggedIn())
    }

  }

  useEffect(() => {
    document.body.className = 'logUI';
    return () => { document.body.className = ''; }
  });

  return (

    <div>
        {load ? <Loading/> : 
        <div className='row g-0'>
            <div className='col-12 col-lg-7'>
              <div className='logFormOuter'>
                <div className='logForm p-5 text-center'>
                  <div className='mb-4'>
                    <Link to="/" className='navbar-brand d-inline-block text-center'><img className='logo' src='/svg/logo-header.svg' alt="logo" /></Link>
                  </div>
                    <h1 className='mb-4'>Create an Account</h1>
                    <p>Email</p>
                    <div className='mb-4 related'>
                      <input className='form-control rounded-pill' id='email'
                        type='email' placeholder='Email' value={registerData.email} onChange={inpChange} onKeyUp={keyUp} />
                      <span className='rerrors' >{Errors.email}</span>
                    </div>
                    <p>Username</p>
                    <div className='mb-4 related'>
                      <input id='username' className='form-control rounded-pill' type='text' placeholder='Username' onChange={inpChange} onKeyUp={keyUp} />
                      <span className='rerrors'>{Errors.username}</span>
                    </div>
                    <p>Enter Password</p>
                    <div className='mb-4 related'>
                      <input id='pass1' className='form-control rounded-pill' type='password' placeholder="Password" onChange={inpChange} onKeyUp={keyUp} />
                      <span className='rerrors'>{Errors.password}</span>
                    </div>
                    <p>Confirm Password</p>
                    <div className='mb-4 related'>
                      <input id='pass2' className='form-control rounded-pill' type='password' placeholder="Confirm Password" onChange={inpChange} onKeyUp={keyUp} />
                      <span className='rerrors'>{Errors.confirmpass}</span>
                    </div>
                    <div className='d-grid pt-2'>
                      <button className='btn btn-lg btn-primary rounded-pill' onClick={submit}>Done</button>
                    </div>
                    <p className='text-center pt-2'><strong className='rerrors'>{Errors.message}</strong></p>
                    {/* <p className='continue'>or continue with</p>
                    <div className='row conWidth'>
                      <div className='col-6'>
                        <button type='button' className='btn btn-outline-primary rounded-pill'>
                          <img src='/google.png' alt='google' />
                          Google
                        </button>
                      </div>
                      <div className='col-6'>
                        <button type='button' className='btn btn-outline-primary rounded-pill'>
                          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24.0001 12.0735C24.0001 5.4045 18.6271 -0.00149536 12.0001 -0.00149536C5.37007 4.63868e-06 -0.00292969 5.40451 -0.00292969 12.075C-0.00292969 18.1005 4.38607 23.0955 10.1221 24.0015V15.564H7.07707V12.075H10.1251V9.4125C10.1251 6.387 11.9176 4.716 14.6581 4.716C15.9721 4.716 17.3446 4.9515 17.3446 4.9515V7.9215H15.8311C14.3416 7.9215 13.8766 8.853 13.8766 9.8085V12.0735H17.2036L16.6726 15.5625H13.8751V24C19.6111 23.094 24.0001 18.099 24.0001 12.0735Z" fill="#059BE5" />
                          </svg>
                          Facebook
                        </button>
                      </div>
                    </div> */}
                    <p className='account text-center'>Already have an account? &nbsp;
                      <Link to='/Login' className='login_link'>
                        Login from here
                      </Link>
                    </p>
                  <hr className="my-3" />
                  <p className="text-center">
                  In case you are facing any issues, please drop an email at <a href="mailto:indiabjf@gmail.com">indiabjf@gmail.com</a>
                    </p>
                </div> 
              </div>
            </div>
            <div className='col-12 col-lg-5'>
              <LogSideBox />
            </div> 
        </div>
        }
      </div>
  )
}

export default Register