import React, { useEffect } from 'react';
import Footer from '../../Footer';
import CampusCarousel from './carousel';
import CampusDetails from './campus-details';

function LifeAtCampus() {


    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad campus'>

            <section className='banner py-5'>
                <div className='container-lg py-lg-5 text-primary text-center'>
                    <h1 className='m-0'>Life at Campus</h1>
                    {/* <p className='fs-4'>Have a good point of View</p> */}
                    <div className='infoTxt'>Bhai Jaitajee Foundation India (BJFI) is dedicated to empowering rural youngsters who want to reach their full potential but are limited by their socioeconomic circumstances. Through expert coaching and regular mentoring, the goal is to support them in achieving academic excellence and helping them to develop the much needed industry skills with high moral and spiritual values.</div>
                </div>
            </section>

            <section className='contLayout bgBlue py-3 py-lg-5'>
                <div className='container-lg pb-5'>
                    <div className='row g-5 align-items-center pb-5'>
                        <div className='col-12 col-md-6'>
                            <div className='pe-lg-5'>
                                <img className='img-fluid' src='/campus/cont-img-1.png' alt='' />
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <p className='m-0'>Individual students are mentored by successful individuals from the fields of engineering, medicine, business, academia, and many other professions to assist and advise them on all fronts. The whole environment here is favourable for academic success and a student's overall development. As a co-educational institution, mutual respect for all genders is fundamental. The faculty also offers individual mentoring to low performing students.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='contLayout pt-0 pb-3'>
                <div className='container-lg'>
                    <div className='p-4 whitePanelTopM rounded-4'>
                        <CampusCarousel />
                    </div>
                    <div className='px-lg-5 pt-5'>
                        <CampusDetails />
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default LifeAtCampus