import React from 'react';
import { Loading } from '../../../loading';

function BJFStdList({ filterResults }) {

    console.log(filterResults, "c")



    return (
        <>
            <section className='contLayout bdr0 px-0 pb-3'>
                <div className='container-lg p-0'>
                    <div className='norWhiteBG rounded-3 p-3'>
                        <div className='d-flex justify-content-between mb-3'>
                            <div>
                                <h6 className='m-0 text-secondary'>List of Students</h6>
                            </div>
                            <div>
                                <h6 className='m-0 text-secondary'>Total Number of Students: {filterResults != null ? filterResults.length : 'Loading..'}</h6>
                            </div>
                        </div>
                        <div className='table-responsive bdrTop h300p'>
                            <table className='table sList'>
                                <thead>
                                    <tr>
                                        <th className='w80p'>Sr. No.</th>
                                        <th className='pLAName'>Student</th>
                                        <th>Institute</th>
                                        <th>Programme</th>

                                    </tr>
                                </thead>
                                {filterResults == null ? <Loading /> :
                                    <tbody>
                                        {filterResults.map(itm => {
                                            return (
                                                <tr>
                                                    <td>{itm['S.N']}</td>
                                                    <td>
                                                        <span className='avatar me-3' style={{ backgroundImage: `url(${itm['aws_key']}` }}></span>
                                                        {itm['Name']}
                                                    </td>
                                                    <td>{itm['Institute'] !='None' ? itm['Institute'] : '-' }</td>
                                                    <td>{itm['Programme'] !='None' ? itm['Programme'] : '-'  }</td>

                                                </tr>
                                            )
                                        })
                                        }

                                    </tbody>}
                            </table>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default BJFStdList