import React from 'react';
import { useEffect, useState } from 'react';
import '../css/LogForm.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import _ from "lodash";
import { URL1 } from "../config";
import LogSideBox from './logSidebox.js';

function Reset() {

  let location = useLocation()


  const [resetData, setresetData] = useState({ "password": "", "confirmpass": "" })
  const [Errors, setErrors] = useState({})
  const history = useNavigate()

  function inpChange(e) {
    switch (e.target.id) {
      case 'pass1':
        setresetData({ ...resetData, ["password"]: e.target.value })
        break;
      case 'pass2':
        setresetData({ ...resetData, ["confirmpass"]: e.target.value })
        break;
      default:
        break;
    }
  }

  function keyUp(e) {
    let err = validate(e.target.id)
    if (!_.isEmpty(err)) {
      setErrors(err)
    }
    else {
      setErrors({})
    }
  }

  function validate(key) {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    switch (key) {
      case 'pass1':
        if (!resetData.password) {
          errors.password = "Password is required";
        }
        else if (resetData.password.length < 4) {
          errors.password = "Password must be more than 4 characters";
        }
        else if (resetData.password.length > 10) {
          errors.password = "Password cannot exceed more than 10 characters";
        }
        break;

      case 'pass2':
        if (!resetData.confirmpass) {
          errors.confirmpass = "Confirm Password is required";
        }
        else if (resetData.confirmpass != resetData.password) {
          errors.confirmpass = "Confirm Password doesn't match"
        }
      case 'all':
        if (!resetData.password) {
          errors.password = "Password is required";
        }
        else if (resetData.password.length < 4) {
          errors.password = "Password must be more than 4 characters";
        }
        else if (resetData.password.length > 10) {
          errors.password = "Password cannot exceed more than 10 characters";
        }
        if (!resetData.confirmpass) {
          errors.confirmpass = "Confirm Password is required";
        }
        else if (resetData.confirmpass != resetData.password) {
          errors.confirmpass = "Confirm Password doesn't match"
        }

      default:
        break;
    }

    return errors;
  }

  async function submit() {
    let err = validate('all')

    if (!_.isEmpty(err)) {
      setErrors(err);
    } else {
      setErrors({});

      let res = await fetch(URL1 + '/change_password', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": location.state.email,
          "new_password_1": resetData.password,
          "new_password_2": resetData.confirmpass,
          "otp": location.state.otp
        })
      })
      let resJson = await res.json()
      if (res.status == 200) {

        history('/login')
      }
      else {
        let err = { "message": resJson['message'] }
        setErrors(err)
      }
      // dispatch(loggedIn())

    }

  }

  useEffect(() => {
    document.body.className = 'logUI';
    return () => { document.body.className = ''; }
  });


  return (
    <div>
        <div className='row g-0'>
          <div className='col-12 col-lg-7'>
            <div className='logFormOuter'>
              <div className='logForm p-5 text-center'>
                  <div className='mb-4'>
                    <Link to="/" className='navbar-brand d-inline-block text-center'><img className='logo' src='/svg/logo-header.svg' alt="logo" /></Link>
                  </div>
                <h1 className='mb-5'>Password Reset</h1>
                <p>Enter New Password</p>
                <div className='mb-4 related'>
                  <input id='pass1' className='form-control rounded-pill' type='password' placeholder="password" onChange={inpChange} onKeyUp={keyUp} />
                  <span className='rerrors'>{Errors.password}</span>
                </div>
                <p>Confirm New Password</p>
                <div className='mb-4 related'>
                  <input id='pass2' className='form-control rounded-pill' type='password' placeholder="password" onChange={inpChange} onKeyUp={keyUp} />
                  <span className='rerrors'>{Errors.confirmpass}</span>
                </div>
                <div className='d-grid pt-4 mb-3'>
                  <button className='btn btn-lg btn-primary rounded-pill' onClick={submit}>Reset Password</button>
                </div>
                <p className='account'>go back to login page &nbsp;
                  <Link to='/login' className='register'>
                    from here</Link>
                </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-5'>
              <LogSideBox />
          </div> 
        </div>
        
    </div>
  )
}

export default Reset