import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from '../../Footer';
import EnglishTestSample from './english-test-sample';
import MentalMathTestSample from './mentalmath-test-sample';
import ScreenTestSample from './screen-test-sample';

function BJFSampleQsts2023() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='my-4'>BJF Stars 2023-25 Screening Test Sample Questions</h1>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Tab.Container id="genSideNav" defaultActiveKey="01">
                            <div className='row g-0 normVTabs'>
                                <div className='col-12 col-md-3'>
                                    <div className='pe-md-4 h-100'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item><Nav.Link eventKey="01">Screening Test Sample</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="02">Mental Maths Test Sample</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="03">English Paragraph Sample</Nav.Link></Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                <div className='col-12 col-md-9 pe-2'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="01">
                                            <ScreenTestSample />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="02">
                                            <MentalMathTestSample />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="03">
                                            <EnglishTestSample />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default BJFSampleQsts2023