import React from 'react';
import Footer from '../../Footer';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/pdf/March-2023/BJF-Stars-2023-2025_Shortlisted-Candidates-for-Interview-March-9-2023-V2.pdf"></iframe>'
};

function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function ShortlistedInterview() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2023-2025: Shortlisted Candidates For Interview</h1>
                        <p className='mb-0'><strong>BJF Stars 2023-2025: Shortlisted Candidates for Interview</strong>&nbsp;- The students shortlisted for the interview will be communicated the interview date and slot on their registered contact number. Further instructions regarding the interview process will be shared after 20th March 2023 on the website.</p>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Iframe iframe={fileUrl["fdFile"]} />
                        <div className='pt-2 text-center'>
                            <a className='btn btn-primary btn-md' href="/pdf/March-2023/BJF-Stars-2023-2025_Shortlisted-Candidates-for-Interview-March-9-2023-V2.pdf" download="BJF Stars 2023-2025: Shortlisted Candidates for Interview">Download</a>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default ShortlistedInterview