import React from 'react';
import Footer from '../../Footer';


function JeeMainI2024() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>JEE Main (I) 2024 Result</h1>
                        <p className='mb-0'>Outstanding performance of BJF Stars 2024 students in JEE Main (I).</p>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container'>
                        <div className='text-center'>
                            <img className='img-fluid mxW800' src="/results/JEE-Main-2024-Results.jpg" alt="" />
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default JeeMainI2024