import React from "react";
import "./css/apply.css";

function EducationDetails12({setdata,data,Errors,setErrors}) {

  const checkValidation = (setErrors, data ,key) => {
    if( data.education[key] ==""){
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: "This field is required"
          }
        }
      ))
      
    }
    else if ((key == 'Pincode_10' || key == 'Pincode_12') && /^[0-9][0-9][0-9][0-9][0-9][0-9]$/.test(data.education[key]) ==false  ) {
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: "Pincode can only have 6 digits"
          }
        }
      ))
    }
    else if (key == 'Number_Attempt_Neet'){
      if(/^[0-9]$/.test(data.education[key]) ==false){
        setErrors(p => (
          {
            ...p ,
            education : {
              ...p.education , 
              [key]: "It can be between 0 to 9"
            }
          }
        ))

      }
      else{
        setErrors(p => (
          {
            ...p ,
            education : {
              ...p.education , 
              [key]: ""
            }
          }
        ))
      }
     
    }
    else if (key == 'Last_Rank_Cat_Neet' || key == 'Last_Rank_Air_Neet' || key == 'Total_Marks_Neet' || key == 'Chemistry_Marks_Neet' || key == 'Biology_Marks_Neet'  || key == 'Physics_Marks_Neet') {
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: ""
          }
        }
      ))
    }


    else if (key == 'Maths_Marks_10' || key == 'Overall_Percentage_10' ||  key == 'Science_Marks_10' || key == 'Physics_Marks_12' || key == 'Biology_Marks_12'  || key == 'Overall_Percentage_12' || key == 'Chemistry_Marks_12' ) {
      if (/^(0|[1-9][0-9]?|100)$/.test(data.education[key]) ==false){
        setErrors(p => (
          {
            ...p ,
            education : {
              ...p.education , 
              [key]: "marks percentage should be in 0 to 100"
            }
          }
        ))

      }
      else{
        setErrors(p => (
          {
            ...p ,
            education : {
              ...p.education , 
              [key]: ""
            }
          }
        ))

      }
    }

    

    else if (data.education[key].length <3 ){
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: "It should have atleast 3 characters"
          }
        }
      ))
    }
    else{
      setErrors(p => (
        {
          ...p ,
          education : {
            ...p.education , 
            [key]: ""
          }
        }
      ))
  
    }
   
  }

  const keyUp = (e) => {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.education[id] = val
    
    checkValidation(setErrors, dummy, id)
  };

  function handleChange(e) {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.education[id] = val
    setdata(dummy)
  }


  return (
    <div>
      <div className='formDetails'>
            <div className="row">
                <div className="col-12 col-lg-6 col-xl-5">
                    <h6>Class Xth Details</h6>
                    <div className="mb-3">
                        <label className="form-label">School Name <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="text"
                            placeholder=""                          
                            value={data["education"]["School_Name_10"]}
                            id="School_Name_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.School_Name_10}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">School Address Line 1 <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="text"
                            placeholder=""                          
                            value={data["education"]["School_Address_10"]}
                            id="School_Address_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.School_Address_10}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Pincode <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="number"
                            placeholder=""                          
                            value={data["education"]["Pincode_10"]}
                            id="Pincode_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.Pincode_10}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Maths Marks Percentage <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="number"
                            placeholder=""                          
                            value={data["education"]["Maths_Marks_10"]}
                            id="Maths_Marks_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.Maths_Marks_10}</small>
                    </div>

                    <div className="mb-3">
                          <label className="form-label">Board <b className="text-danger">*</b></label>
                          <select className="form-select" name="Board_10" id="Board_10" selected= {data["education"]["Board_10"]} onChange={handleChange} >
                            <option disabled selected value> -- select an option -- </option>
                            <option value="cbse">CBSE</option>
                            <option value="punjab">PUNJAB</option>
                            <option value="icse">ICSE</option>
                          </select>                        
                          <small className="logTxtDnger">{Errors.education.Board_10}</small>                    
                      </div>

                    <div className="mb-3">
                        <label className="form-label">School Address Line 2 <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="text"
                            placeholder=""                          
                            value={data["education"]["School_Address2_10"]}
                            id="School_Address2_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.School_Address2_10}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Overall Percentage <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="number"
                            placeholder=""                          
                            value={data["education"]["Overall_Percentage_10"]}
                            id="Overall_Percentage_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.Overall_Percentage_10}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Science Marks <b className="text-danger">*</b></label>
                          <input className='form-control rounded'  type="number"
                            placeholder=""                          
                            value={data["education"]["Science_Marks_10"]}
                            id="Science_Marks_10"
                            onChange={handleChange}
                            onKeyUp={keyUp}/>                          
                          <small className="logTxtDnger">{Errors.education.Science_Marks_10}</small>
                    </div>

                    <h6 className="mt-4">Class XIIth Details</h6>
                      <div className="mb-3">
                              <label className="form-label">School Name <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="text"
                                  placeholder=""                          
                                  value={data["education"]["School_Name_12"]}
                                  id="School_Name_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.School_Name_12}</small>
                          </div>
                          <div className="mb-3">
                              <label className="form-label">School Address Line 1 <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="text"
                                  placeholder=""                          
                                  value={data["education"]["School_Address_12"]}
                                  id="School_Address_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.School_Address_12}</small>
                          </div>
                          <div className="mb-3">
                              <label className="form-label">Pincode <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="number"
                                  placeholder=""                          
                                  value={data["education"]["Pincode_12"]}
                                  id="Pincode_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.Pincode_12}</small>
                          </div>
                          <div className="mb-3">
                              <label className="form-label">Physics Marks Percentage <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="number"
                                  placeholder=""                          
                                  value={data["education"]["Physics_Marks_12"]}
                                  id="Physics_Marks_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.Physics_Marks_12}</small>
                          </div>

                          <div className="mb-3">
                              <label className="form-label">Biology Marks Percentage <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="number"
                                  placeholder=""                          
                                  value={data["education"]["Biology_Marks_12"]}
                                  id="Biology_Marks_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.Biology_Marks_12}</small>
                          </div>

                          <div className="mb-3">
                                <label className="form-label">Board <b className="text-danger">*</b></label>
                                <select className="form-select" name="Board_12" id="Board_12" selected= {data["education"]["Board_12"]} onChange={handleChange} >
                                  <option disabled selected value> -- select an option -- </option>
                                  <option value="cbse">CBSE</option>
                                  <option value="punjab">PUNJAB</option>
                                  <option value="icse">ICSE</option>
                                </select>                        
                                <small className="logTxtDnger">{Errors.education.Board_12}</small>                    
                            </div>

                          <div className="mb-3">
                              <label className="form-label">School Address Line 2 <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="text"
                                  placeholder=""                          
                                  value={data["education"]["School_Address2_12"]}
                                  id="School_Address2_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.School_Address2_12}</small>
                          </div>
                          <div className="mb-3">
                              <label className="form-label">Overall Percentage <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="number"
                                  placeholder=""                          
                                  value={data["education"]["Overall_Percentage_12"]}
                                  id="Overall_Percentage_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.Overall_Percentage_12}</small>
                          </div>
                          <div className="mb-3">
                              <label className="form-label">Chemistry Marks Percentage <b className="text-danger">*</b></label>
                                <input className='form-control rounded'  type="number"
                                  placeholder=""                          
                                  value={data["education"]["Chemistry_Marks_12"]}
                                  id="Chemistry_Marks_12"
                                  onChange={handleChange}
                                  onKeyUp={keyUp}/>                          
                                <small className="logTxtDnger">{Errors.education.Chemistry_Marks_12}</small>
                          </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-5 offset-xl-2">
                      <h6>NEET Details</h6>
                      <div className="mb-3">
                          <label className="form-label">Number of Attempts <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Number_Attempt_Neet"]}
                              id="Number_Attempt_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Number_Attempt_Neet}</small>
                      </div>
                      <div className="mb-3">
                          <label className="form-label">Last Attemp Roll Number <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="text"
                              placeholder=""                          
                              value={data["education"]["Last_Roll_Neet"]}
                              id="Last_Roll_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Last_Roll_Neet}</small>
                      </div>
                      <div className="mb-3">
                          <label className="form-label">Last Attempt Rank(category) <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Last_Rank_Cat_Neet"]}
                              id="Last_Rank_Cat_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Last_Rank_Cat_Neet}</small>
                      </div>
                      <div className="mb-3">
                          <label className="form-label">Physics Marks <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Physics_Marks_Neet"]}
                              id="Physics_Marks_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Physics_Marks_Neet}</small>
                      </div>
                      <div className="mb-3">
                          <label className="form-label">Biology Marks <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Biology_Marks_Neet"]}
                              id="Biology_Marks_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Biology_Marks_Neet}</small>
                      </div>

                      <div className="mb-3">
                            <label className="form-label">Mode of prepration <b className="text-danger">*</b></label>
                            <select className="form-select" name="Mode_Prep_Neet" id="Mode_Prep_Neet" selected= {data["education"]["Mode_Prep_Neet"]} onChange={handleChange} >
                              <option disabled selected value> -- select an option -- </option>
                              <option value="cbse">Online</option>
                              <option value="punjab">Offline</option>
                            </select>                        
                            <small className="logTxtDnger">{Errors.education.Mode_Prep_Neet}</small>                    
                        </div>

                        <div className="mb-3">
                          <label className="form-label">Last Attempt Rank(AIR) <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Last_Rank_Air_Neet"]}
                              id="Last_Rank_Air_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Last_Rank_Air_Neet}</small>
                      </div>
                      <div className="mb-3">
                          <label className="form-label">Chemistry Marks <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Chemistry_Marks_Neet"]}
                              id="Chemistry_Marks_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Chemistry_Marks_Neet}</small>
                      </div>
                      <div className="mb-3">
                          <label className="form-label">Total Marks <b className="text-danger">*</b></label>
                            <input className='form-control rounded'  type="number"
                              placeholder=""                          
                              value={data["education"]["Total_Marks_Neet"]}
                              id="Total_Marks_Neet"
                              onChange={handleChange}
                              onKeyUp={keyUp}/>                          
                            <small className="logTxtDnger">{Errors.education.Total_Marks_Neet}</small>
                      </div>
                </div>
            </div>
        </div>
    </div>    
  );
}

export default EducationDetails12;
