import React from 'react'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function CampusCarousel() {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,                    
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <>
            <Slider {...settings}>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g01.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g02.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g03.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g04.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g05.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g06.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g07.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g08.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g09.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g10.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g11.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g12.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g13.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g14.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g15.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g16.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g17.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g18.jpg' alt="" />
                </div>
                <div>
                    <img className='img-fluid rounded' src='/campus/gallery/g19.jpg' alt="" />
                </div>
            </Slider>

        </>
    )
}

export default CampusCarousel