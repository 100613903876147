import React from 'react';
import PassedOutGraph from './graphs/passed-out';
import InstituteDataGraph from './graphs/institute-data';
import LocationDataGraph from './graphs/location-data';
import { useState } from 'react';
import { useEffect } from 'react';

function getCounts(key , data) {
    let ret = {}

    data.map(itm => {
        if (itm[key] in ret ) { 
            ret[itm[key]] +=1
        }
        else{
            ret[itm[key]] = 1
        }
        })

        console.log(ret , "RET")
        var x_axis
        var y_axis
        if (key == 'To') {
            Object.keys(ret)
            x_axis =  Object.keys(ret).map(itm => parseInt(itm) ).filter(itm => ! isNaN(itm)).sort()
            console.log(x_axis , ret)
            y_axis = x_axis.map(itm => ret[itm])
        }
        else{
            x_axis =  Object.keys(ret)
            y_axis = x_axis.map(itm => ret[itm])
        }


        return {
            x_axis : x_axis , 
            y_axis :  y_axis
    
        }
}



function GraphsArea({filterResults}) {

    console.log(filterResults , "ING")

    

    const [matrix, setmatrix] = useState({
        "To" : getCounts('To' , filterResults) , 
        "Institute" : getCounts('Institute' , filterResults) , 
        "Location" : getCounts('Location' , filterResults) 
    })

    console.log(matrix , "MAT")
    useEffect(() => {
      setmatrix({
        "To" : getCounts('To' , filterResults) , 
        "Institute" : getCounts('Institute' , filterResults) , 
        "Location" : getCounts('Location' , filterResults) 
    })
    }, [filterResults])
    

    return (
        <>
           <div className='row g-3'>
                <div className='col-12 col-md-8'>
                    <div className='norWhiteBG rounded-3 p-3 h-100'>
                        <PassedOutGraph x_axis={matrix['To']['x_axis']} y_axis={matrix['To']['y_axis']} />
                    </div>
                </div>
                <div className='col-12 col-md-4'>
                    <div className='norWhiteBG rounded-3 p-3'>
                        <div className='mb-3'>
                            <h6>Institute based data</h6>
                            <InstituteDataGraph x_axis={matrix['Institute']['x_axis']} y_axis={matrix['Institute']['y_axis']} />
                        </div>
                        <div className='mb-0'>
                            <h6>Location based data</h6>
                            <LocationDataGraph x_axis={matrix['Location']['x_axis']} y_axis={matrix['Location']['y_axis']} />
                        </div>
                    </div>
                </div>
           </div> 
        </>        
    )
}

export default GraphsArea