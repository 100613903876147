import React from 'react'
import Slider from "react-slick";
import Footer from '../Footer.js';
import { useEffect } from 'react';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function AboutUs() {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };

    return (
        <div className='inrPageTopPad'>

            <section className='contLayout bgLight py-4 py-lg-5'>
                <div className='container-lg text-center'>
                    <img src="svg/logo-header.svg" alt='' />
                    <div className='row justify-content-center'>
                        <div className='col-12 col-lg-8 pt-5'>
                            <h2 className='mb-4'>Achieve academic excellence through specialized coaching and continuous mentoring with us.</h2>
                            <p className='fs-lg-5'>At BJF India, our endeavour has been to help students from socio-economically challenged environment in the rural areas of Punjab and guide them to realize their potential. We prepare them for highly competitive entrance exams and enable them to join top engineering & medical institutes across the country.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='contLayout bgBlue py-4 py-lg-5'>
                <div className='container-lg'>
                    <div className='row g-5 align-items-center'>
                        <div className='col-12 col-md-5 text-center'>
                            <img className='img-fluid' src="svg/bjfi-figure-art.svg" alt="" />
                        </div>
                        <div className='col-12 col-md-7'>
                            <h2 className='mb-lg-5'>Bhai Jaitajee</h2>
                            <p>Bhai Jaitajee Foundation India is an organization in the social entrepreneurship space, and named after a hero from Sikh history. Bhai Jaita was born to a disadvantaged socio-economic background. However, the good fortune of growing up in an enlightened community that exposed him to progressive ideas, holistic education, and practical training gave him the self-esteem to lead a heroic life. Inspired by Bhai Jaita's life and the Gurus who created the communities that transformed people like him, we aim to help the marginalized and dispossessed realize their potential. We seek to be innovative in our approaches.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='contLayout py-3 py-lg-5'>
                <div className='container-lg whoAre'>
                    <div className='row justify-content-center'>
                        <div className='col-12 col-lg-8 text-center mb-5'>
                            <h2 className='mb-4'>Who Are We</h2>
                            <p>The Bhai Jaitajee Foundation India (BJFI) is an organization committed to empowering the rural youth by imparting quality coaching in our residential facility to deserving students facing many socio-economic constraints coupled with accessibility to good coaching institutes. The focus is to help them achieve their academic excellence through specialized coaching and continuous mentoring. Alongside, attention is being paid to groom them as holistic personalities to become good human beings with high moral and spiritual values.</p>
                        </div>
                    </div>
                    <div className='mb-5'>
                        <Slider {...settings}>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-01.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-02.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-03.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-04.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-05.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-06.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-07.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-08.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-09.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-10.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-11.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-12.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-13.jpg' alt="" />
                            </div>
                            <div>
                                <img className='img-fluid rounded' src='about/images-2024/p-14.jpg' alt="" />
                            </div>
                        </Slider>
                    </div>
                    <div className='pt-2'>
                        <img className='img-fluid' src='about/group-students.svg' alt='Groom Students as Holistic Personalities' />
                    </div>
                </div>
            </section>


            <section className='contLayout bgBlue py-3 py-lg-5'>
                <div className='container-lg text-center'>
                    <h2 className='mb-4'>The Need</h2>
                    <p>A study of admission to professional colleges indicates that students from rural areas constitute only 25% of total admissions out of 70% of rural population. Furthermore, underprivileged rural students constitute a miniscule fraction of the 25% rural admissions. There are many reasons why an opportunity for higher education passes by these marginalised rural children. Amongst them, poverty, lack of information/aspiration/guidance, lack of access to intensive coaching facilities, highly competitive and expensive entrance tests are some of the well-documented causes. Higher education being a key factor to a better economic status, the current situation leads to perpetuation of chronic poverty in spite of the high academic potential among the rural poor. Whereas <strong>facilitating higher education to even a single member of a family not only uplifts the family out of poverty but may also impact the social and economic environment of the whole village.</strong></p>
                </div>
            </section>


            <section className='contLayout bgLight py-3 py-lg-5'>
                <div className='container-lg text-center'>
                    <h2 className='mb-4'>Our Team</h2>
                    <p className='mb-5'>The small team of founders and providers of BJF have a track record of successful entrepreneurship, experience in education and healthcare and a passion for catalyzing change especially amongst the poor. Trustees and donors come from a wide variety of backgrounds—from engineering, computer science, architecture and medicine—but all have a strong commitment to social change.</p>
                    <img className='img-fluid' src="about/donors-group.svg" alt="" />
                </div>
            </section>

            <section className='contLayout py-3 py-lg-5'>
                <div className='container-lg text-center'>
                    <h2 className='mb-5'>Our Objectives</h2>
                    <div className='row g-5 objCounter justify-content-center'>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Improve awareness of a wide array of career options through counselling of students and parents.
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Help set professional aspiration consistent with potential measured through aptitude and other tests.
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Provide sustained mentorship and appropriate educational exposure backed by residential programs.
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Foster altruistic values and a commitment to social change among volunteers and students.
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Develop needed partnerships to mobilize resources and deliver value to intended beneficiaries with minimal overhead costs.
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Strengthen our partner organizations.
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4'>
                            <div className='objBox'>
                            Ensure eligible beneficiaries get all available financial and other support from the government by providing needed administrative and liaison support.
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default AboutUs