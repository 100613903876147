import React from 'react'

export const Loading = () => {
  return (
    <div className='d-inline-block vAlign'>
        <div className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        
    </div>    
  )
}