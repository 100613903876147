import React from 'react';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/stats-info/form-fc-4-2012.pdf"></iframe>'
};
function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function BJFFC42012() {
    return (
        <div>  
            <Iframe iframe={fileUrl["fdFile"]} />
        </div>
    )
}

export default BJFFC42012