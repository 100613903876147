import _ from "lodash";
import { LOGGED_IN, LOGGED_IN_ERROR, LOGGED_OUT } from "./actionType";

export const loggedOut = () => {
    // * action return objects which redux takes of to send to the reducers
    console.log("Inside logged Out action creator");
    return {
        type: LOGGED_OUT,
    };
};

export const loggedIn = (auth_token , name , role,applied_for , r_id) => {
    console.log("jfjfjdj",applied_for)
    return async (dispatch, getState) => {
        try {
            dispatch({type: LOGGED_IN , payload: {auth_token: auth_token , name: name , role : role ,applied_for:applied_for , r_id:r_id } })
        } catch (err) {
            console.log("Inside the login action : error occured ");
            console.log(err);
            dispatch({
                type: LOGGED_IN_ERROR,
                payload: err.message,
            });
        }
    };
};

