import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function TabLocation({filters ,selected ,  setselected }) {

    function changeSelect(e , itm ) {
        console.log(e.target.checked  , itm)
        
        let dt = [...selected['Location']]
        if (e.target.checked) { 
            if (!dt.includes(itm)) {
                dt.push(itm)
            }
        }
        else{
            dt = dt.filter(item => item !== itm)
        }
        setselected(p => (
            {
                ...p,
                'Location' : dt 
            }
        ))


    }

    filters['Location'].sort()

    return (
        <>
            <div className='filterDD'>
                <h2><span>List of Locations</span></h2>
                <form name='locationsFltr'>
                    <div className='row'>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Location'].slice(0,12).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Location"].includes(itm)} type="checkbox" name="locations" onChange={(e) => changeSelect(e , itm )}/>
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Location'].slice(12,24).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Location"].includes(itm)} type="checkbox" name="locations" onChange={(e) => changeSelect(e , itm)}/>
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Location'].slice(24,36).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Location"].includes(itm)} type="checkbox" name="locations" onChange={(e) => changeSelect(e , itm)}/>
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Location'].slice(36,48).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Location"].includes(itm)} type="checkbox" name="locations" onChange={(e) => changeSelect(e , itm )}/>
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Location'].slice(48,58).map(itm => {
                                    return (
                                        <li>
                                            <input checked={selected["Location"].includes(itm)} type="checkbox" name="locations" onChange={(e) => changeSelect(e , itm)}/>
                                            <div>
                                                <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                <span>{itm}</span>
                                            </div>                                    
                                        </li>
                                    )
                                })}
                                
                            </ul>
                        </div>
                      
                 

                    </div>
                </form>
            </div>
        </>        
    )
}

export default TabLocation