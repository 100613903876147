import { CheckBox } from '@mui/icons-material';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function TabYear({filters ,selected ,  setselected , fromToFilter , setfromToFilter }) {

    function changeSelect(e  , key) {
     
        
        
            setselected(p=> (
                {...p , 
                 [key] : e.target.value
                }
            ))
        
    }



    console.log(filters , "ff")
    return (
        <>
            <div className='filterDD'>
                <form name='yearFltr'>
                    <div>
                <input checked={fromToFilter} type="checkbox" name="locations" onChange={(e) =>setfromToFilter(p => !p) }/>
                <lable> Check the box to search for a passed out year only</lable>

                    </div>
                    
                    <div className='row'>
                        <div className='col-6 col-md-3 col-lg-2'>
                            <div className='mb-2'><strong>From:</strong></div>
                            
                            <select class="form-select" onChange={(e) => changeSelect(e , "From")} value={selected['From'] !=  null ? selected['From'] : '1' }>
                                <option disabled selected value={'1'} >-From-</option>
                                {filters['From'].map((itm) => {
                                    return (
                                        <option value={itm}>{itm}</option>
                                    )
                                })}
                                
                            </select>                            

                            {/* <ul className='nonList year'>
                                {filters['From'].map((itm) => {
                                    return (
                                        <li>
                                            <input checked={selected['From'] == itm} onChange={(e) => changeSelect(e , itm , "From")}  type="radio" name="from" />
                                            <span>{itm}</span>
                                        </li>
                                    )
                                })}
                            </ul> */}
                        </div>
                        <div className='col-6 col-md-3 col-lg-2'>
                            <div className='mb-2'><strong>To:</strong></div>
                            <select class="form-select" onChange={(e) => changeSelect(e , "To")} value={selected['To'] !=null ? selected['To'] : '1' } >
                                <option disabled selected value={'1'} >-To-</option>
                               
                                {filters['To'].filter(itm => selected['From'] ==null ? true : itm >=selected['From'] ).map(itm => {
                                        return (
                                            <option value={itm}>{itm}</option>
                                            
                                        )
                                    
                                })}         
                                
                            </select>
                        </div>
                        {/* {selected['From'] != null ? 
                        <div className='col-6 col-md-3 col-lg-2 text-center'>
                            <div className='mb-3'><strong>To:</strong></div>
                            <ul className='nonList year'>
                                {filters['To'].filter(itm => itm >=selected['From'] ).map(itm => {
                                        return (
                                            <li>
                                                <input checked={selected['To'] == itm} onChange={(e) => changeSelect(e , itm , "To")} type="radio" name="to" />
                                                <span>{itm}</span>
                                            </li>
                                        )
                                    
                                })}
                            </ul>
                        </div>  : "" }   */}
                    </div> 
                </form>
            </div>
        </>        
    )
}

export default TabYear