import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { SideNav } from './adminSideNav';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import "./css/adminUI.css";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom'
import { URL1 } from '../config';
import { useEffect , useState } from 'react';
import Apply from './admissions/Apply';
import Apply1 from './admissions/Apply1';
import Apply2 from './admissions/Apply2';
import { DeletePopup} from "./deletePopup"
import { Loading } from '../loading_small';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const OnlineAppEdit = () => {
  let navigate = useNavigate();
  let location = useLocation();
    const Data = useSelector(state => state)
    const [srcImg, setsrcImg] = useState(location.state.pic);
    const [detail, setdetail] = useState({ form_type: "", student_detail : {} , education : {} , family_detail : {} })
    const [application_status, setapplication_status] = useState(null)
    const [popup, setpopup] = useState(false)
    const [ldr, setldr] = useState(false)


    async function fetchData() {
        try{
            let res = await fetch(URL1 + `/view_online_application?application_id=${location.state.id}` , {
              method : "GET" , 
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + Data.auth["access_token"],
              },
            })
            let resJson = await res.json()
            setapplication_status(resJson.application_status)
            
            let tp = {...detail}
            tp.application_id = location.state.id
            tp.student_detail.Name = resJson.p_detail[1]
            tp.student_detail.DOB = resJson.p_detail[2]
            tp.student_detail.Gender = resJson.p_detail[3]
            tp.student_detail.Category = resJson.p_detail[4]
            tp.student_detail.Phone_Number = resJson.p_detail[5]
            tp.student_detail.Email = resJson.p_detail[6]
            tp.student_detail.Addr1 = resJson.p_detail[7]
            tp.student_detail.Addr2 = resJson.p_detail[8]
            tp.student_detail.District = resJson.p_detail[9]
            tp.student_detail.State = resJson.p_detail[10]
            tp.student_detail.Pincode = resJson.p_detail[11]
            tp.student_detail.Location = resJson.p_detail[12]
            tp.family_detail.Father_Name = resJson.p_detail[13]
            tp.family_detail.Mother_Name = resJson.p_detail[14]
            tp.family_detail.Father_Qualification = resJson.p_detail[15]
            tp.family_detail.Mother_Qualification = resJson.p_detail[16]
            tp.family_detail.Father_Occupation = resJson.p_detail[17]
            tp.family_detail.Mother_Occupation = resJson.p_detail[18]
            tp.family_detail.Family_Income = resJson.p_detail[19]
            tp.family_detail.Agriculture_Land = resJson.p_detail[20]
            tp.student_detail.Reference = resJson.p_detail[21]
            
            if (resJson.p_detail[0] == '10') {
                tp.form_type = parseInt(resJson.p_detail[0])
                tp.education.School_Name = resJson.e_detail[0]
                tp.education.Location = resJson.e_detail[1]
                tp.education.School_Address = resJson.e_detail[2]
                tp.education.Pincode = resJson.e_detail[3]
                tp.education.Applying = resJson.e_detail[4]
                tp.education.Overall_Percentage_9 = resJson.e_detail[5]
                tp.education.Science_Marks_9 = resJson.e_detail[6]
                tp.education.Maths_Marks_9 = resJson.e_detail[7]
            }
            else if(resJson.p_detail[0] == '11') {
                tp.form_type = parseInt(resJson.p_detail[0])
                tp.education.School_Name = resJson.e_detail[0]
                tp.education.Board = resJson.e_detail[1]
                tp.education.School_Address = resJson.e_detail[2]
                tp.education.School_Address2 = resJson.e_detail[3]
                tp.education.Pincode = resJson.e_detail[4]
                tp.education.Overall_Percentage_9 = resJson.e_detail[5]
                tp.education.Science_Marks_9 = resJson.e_detail[6]
                tp.education.Maths_Marks_9 = resJson.e_detail[7]
                tp.center_location = resJson.p_detail[22]
            }
            else if (resJson.p_detail[0] == 'neet') {
                tp.form_type = resJson.p_detail[0]
                tp.education.School_Name_10 = resJson.e_detail[0]
                tp.education.Board_10 = resJson.e_detail[1]
                tp.education.School_Address_10 = resJson.e_detail[2]
                tp.education.School_Address2_10 = resJson.e_detail[3]
                tp.education.Pincode_10 = resJson.e_detail[4]
                tp.education.Overall_Percentage_10 = resJson.e_detail[5]
                tp.education.Science_Marks_10 = resJson.e_detail[6]
                tp.education.Maths_Marks_10 = resJson.e_detail[7]

                tp.education.School_Name_12 = resJson.e_detail[8]
                tp.education.Board_12 = resJson.e_detail[9]
                tp.education.School_Address_12 = resJson.e_detail[10]
                tp.education.School_Address2_12 = resJson.e_detail[11]
                tp.education.Pincode_12 = resJson.e_detail[12]
                tp.education.Overall_Percentage_12 = resJson.e_detail[13]
                tp.education.Physics_Marks_12 = resJson.e_detail[14]
                tp.education.Chemistry_Marks_12 = resJson.e_detail[15]
                tp.education.Biology_Marks_12 = resJson.e_detail[16]

                tp.education.Number_Attempt_Neet = resJson.e_detail[17]
                tp.education.Mode_Prep_Neet = resJson.e_detail[18]
                tp.education.Last_Roll_Neet = resJson.e_detail[19]
                tp.education.Last_Rank_Air_Neet = resJson.e_detail[20]
                tp.education.Last_Rank_Cat_Neet = resJson.e_detail[21]
                tp.education.Physics_Marks_Neet = resJson.e_detail[22]
                tp.education.Chemistry_Marks_Neet = resJson.e_detail[23]
                tp.education.Biology_Marks_Neet = resJson.e_detail[24]
                tp.education.Total_Marks_Neet = resJson.e_detail[25]

            }
            setdetail(tp)
            console.log(tp)
            setldr(false)
            
            
            
      
          }
          catch (err) {
            setldr(false)
      
          }

    }

    useEffect(() => {
        fetchData()
    }, [])

    const renderForms = () => {
        if (detail.form_type === 10) {
          return (
            <Apply srcImg={srcImg} detail={detail} />
          );
        } 
        else if (detail.form_type === 11) {
          return (
            <Apply1 srcImg={srcImg} detail={detail} />
          );
        } else if (detail.form_type === 'neet') {
          return (
            <Apply2 srcImg={srcImg} detail={detail} />
          );
        }
      };

      async function changeStatus(id ,status) {
        try{
          setldr(true)
          let res = await fetch(URL1 + `/change_online_application_status?application_id=${id}&status=${status}` , {
            method : "GET" , 
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Data.auth["access_token"],
            },
          })
          let resJson = await res.json()
          if (res.status ==200){
            fetchData()
          }
    
        }
        catch (err) {
    
        }
        
      }
    
      
  return (
    <div className='inrPageTPAdmin'>
      <div className='row g-0'>
        <div className='col-12 col-lg-3 bdrR'>
          <div className='pt-4 ps-3'>
          <SideNav active={'onlineforms'} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          {popup ? <DeletePopup id={detail.application_id} setSmShow={setpopup} fetchData={fetchData} type={'onlineApp'}/> : 
          <div className='p-4 position-relative'>   
            <button type="button" className="backBtn" title="Back" onClick={() => navigate(-1)}><ArrowBackIcon /></button>         
            <div className='row align-items-center hdrTitle mb-3'>              
                <div className='col-12 col-lg-7'>
                    
                </div>
                <div className='col-12 col-lg-5'>
                    <div className='d-flex flex-wrap justify-content-end py-2'>
                        {ldr ? <div className='pt-2 pe-3'><Loading/></div>  : ""}
                        {
                          application_status =='Approved' ? 
                          <div className='d-flex align-items-center px-1'>
                              <button type='button' className='actStat approved'>Approved</button> 
                          </div> : 
                          <div className='d-flex align-items-center px-1'>
                          <button type='button' className='actStat approve' onClick={()=> {changeStatus(detail.application_id, 'Approved')}}>Approve</button> 
                      </div>
                        }
                       {
                          application_status=='Rejected' ? 
                          <div className='d-flex align-items-center px-1'>
                              <button type='button' className='actStat rejected'>Rejected</button> 
                          </div> : 
                          <div className='d-flex align-items-center px-1'>
                            <button type='button' className='actStat reject' onClick={()=> {changeStatus(detail.application_id, 'Rejected')}}>Reject</button> 
                        </div>
                        }
                       
                        {/* <div className='d-flex align-items-center px-1'>
                            <Button variant="outline-primary" type="button" size="sm">
                                <EditIcon />Edit
                            </Button>                            
                        </div>   */}
                        <div className='d-flex align-items-center px-1'>
                            <Button variant="outline-primary" type="button" size="sm" onClick={()=> setpopup(true)}>
                                <DeleteIcon  />Delete
                            </Button>                            
                        </div>                                   
                    </div>
                </div>
            </div>

            <div className='tabsWrap'>
            {renderForms()}
            </div>
            

          </div> }
        </div>
      </div>
    </div>
  )
}
