import React from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';


function DownloadSamples() {
    return (
        <>
            <div className='dnldSamples text-center'>
                <p><strong>Sample questions for BJF Stars</strong></p>
                <div className='d-md-flex gap-4 justify-content-center'>
                    <div className='py-1 py-md-0'>
                        <a href="/admissions/BJF_Stars/samples/English-Paragraph-Sample.pdf" download='English Paragraph' className='btn btn-lg btn-outline-secondary'>
                            <CloudDownloadIcon />English Paragraph
                        </a>
                    </div>
                    <div className='py-1 py-md-0'>
                        <a href="/admissions/BJF_Stars/samples/Screening-Test-Sample.pdf" download='Screening Test' className='btn btn-lg btn-outline-secondary'>
                            <CloudDownloadIcon />Screening Test
                        </a>
                    </div>
                    <div className='py-1 py-md-0'>
                        <a href="/admissions/BJF_Stars/samples/Mental-Maths-Test-Sample.pdf" download='Mental Maths Test' className='btn btn-lg btn-outline-secondary'>
                            <CloudDownloadIcon />Mental Maths Test
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DownloadSamples