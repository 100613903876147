import React from 'react'

function AdmissionToISTC () {

    return (
        <div>
            <div className='mb-4'>
                <h6>BJF MISSION "ADMISSIONS to INDO SWISS TRAINING CENTER (ISTC) UNDER CSIO CHANDIGARH"</h6>
                <p>BJF Students selected in ISTC, with their Mentor S. Harpal Singh on their visit to his landmark ten storey building erected in 48 hours in Mohali. With BJF support and guidance 11 students have been able to join Diploma in Engineering in Indo Swiss training Centre (ISTC) Chandigarh. ISTC is a government of India Institute in CSIO campus that provides world class training in Mechatronics (now called Mechanical) and Industrial Automation and Instrumentation (Now named as Electronics). ISTC diploma pass outs are known to have 100% placements in good jobs and a large number ultimately shifts to highly rewarding entrepreneurships. 11 BJF students who have done or are doing their diploma in Engg. through ISTC Chandigarh are:</p>
            </div>

            <div className='row mb-5 justify-content-center'>
                <div className='col-12 col-md-7 col-lg-5'>
                    <div className='d-flex flex-wrap'>
                        <div className='px-3 w-50'>
                            <label className='nameLbl mb-2'>1. Rupinderpal Singh</label>
                            <label className='nameLbl mb-2'>2. Shaveen Kumar</label>
                            <label className='nameLbl mb-2'>3. Jagmeet Singh</label>
                            <label className='nameLbl mb-2'>4. Inderpreet Singh</label>
                            <label className='nameLbl mb-2'>5. Jagjit Singh</label>
                            <label className='nameLbl mb-2'>6. Sonu Kumar</label>
                        </div>      
                        <div className='px-3 w-50'>
                            <label className='nameLbl mb-2'>7. Simranjit Kaur</label>
                            <label className='nameLbl mb-2'>8. Preetinder Singh</label>
                            <label className='nameLbl mb-2'>9. Harwinder Singh</label>
                            <label className='nameLbl mb-2'>10. Khushpreet Singh</label>
                            <label className='nameLbl mb-2'>11. Harpreet Singh</label>
                        </div>                
                    </div>
                </div>
            </div>

            <div className='mb-5'>
                <h6 className='mb-3 text-uppercase'>BJF Supported in Indo Swiss Training Center, Chandigarh</h6>
                <div className='table-responsive'>
                    <table className='table table-striped minWidth'>
                        <thead>
                            <tr>
                                <th>Sr. no.</th>
                                <th>Name</th>
                                <th>Branch</th>
                                <th>Duration of course</th>
                                <th>Year</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Sonu Kumar</td>
                                <td>Electronics</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>Exicom Power Solution, Gurgaon</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Jagmeet Singh</td>
                                <td>Mechanical</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>ALP Nishikawa Co. Pvt. Ltd., Lalru</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Rupinderpal Singh</td>
                                <td>Electronics</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>Electrical Dept., Ludiana Junior Engineer</td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Shaveen Kumar</td>
                                <td>Electronics</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>Punia's Consultancy</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Inderpreet Singh</td>
                                <td>Mechanical</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>Exicom Power Solution, Gurgaon</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Jagjit Singh</td>
                                <td>Electronics</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>Parag Milk Food Ltd.</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Simranjeet Kaur</td>
                                <td>Electronics</td>
                                <td>3 Yrs.</td>
                                <td>2013-14</td>
                                <td>Abroad</td>
                            </tr>
                            <tr>
                                <td>8</td>
                                <td>Harwinder Singh</td>
                                <td>Mechanical</td>
                                <td>3 Yrs.</td>
                                <td>2014-15</td>
                                <td>Gilard Electronics, Phase-8, Mohali</td>
                            </tr>
                            <tr>
                                <td>9</td>
                                <td>Preetinder Singh</td>
                                <td>Electronics</td>
                                <td>3 Yrs.</td>
                                <td>2014-15</td>
                                <td>Hindustan Hydrolics, Jalandhar</td>
                            </tr>
                            <tr>
                                <td>10</td>
                                <td>Khushpreet Singh</td>
                                <td>Mechanical</td>
                                <td>3 Yrs.</td>
                                <td>2015-16</td>
                                <td>Havels India Ltd., Rajasthan</td>
                            </tr>
                            <tr>
                                <td>11</td>
                                <td>Harpreet Singh</td>
                                <td>Electronics</td>
                                <td>4 Yrs.</td>
                                <td>2016-17</td>
                                <td>Studying</td>
                            </tr>
                            <tr>
                                <td>12</td>
                                <td>Kawalpreet Singh</td>
                                <td>Mechanical</td>
                                <td>3 Yrs.</td>
                                <td>2017-18</td>
                                <td>Studying</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='mb-0'>
                <h6 className='mb-3 text-uppercase'>Indo-Swiss Training Program</h6>
                <p>The Indo-Swiss Training Centre offers three year diploma course in Electronics & Mechanical stream with emphasis on hands on training. There is always demand for these students in the industry and placement is offered by companies in the final year. The students supported by BJF are all gainfully employed.</p>
                <p>The basic qualification is 10th class passed with 60% marks. Applications are invited after the declaration of CBSE / State Board results. A written test is conducted followed by interview. Written test is based on 10th class syllabus. BJF supports students for coaching to prepare for the written test and interview.</p>
            </div>
        </div>
    )
}

export default AdmissionToISTC