import React, { useEffect } from 'react';
import BJFHeadStartBanner from './banner.js';
import Footer from '../../../Footer.js';


function BJFHeadStart() {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>

            <BJFHeadStartBanner />

            <section className='py-5 text-primary'>
                <div className='container-lg'>
                    <div className='row align-items-center'>
                        <div className='col-12 col-md-5 p-3 px-lg-5'>
                            <img className='img-fluid' src="/admissions/BJF_HeadStart/cont01.svg" alt="" />
                        </div>
                        <div className='col-12 col-md-7 p-3 px-lg-5'>
                            <p>Bhai Jaitajee Foundation India invites applications for BJF Headstart Program. It is an online program for the students of Classes IX & X to strengthen their Science and Mathematics foundation without shifting them from their homes and schools since strong foundations in these two subjects will prepare them better for:</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5 text-primary'>
                <div className='container-lg px-lg-5'>
                    <ul className='onlineSteps row g-0 mb-lg-5'>
                        <li className='col-6 col-md-3 mb-5 mb-lg-0'>
                            <div className='py-4 pe-4 pe-lg-5'>
                                <p className='m-0'>STEM learning</p>
                            </div>
                        </li>
                        <li className='col-6 col-md-3 mb-5 mb-lg-0'>
                            <div className='py-4 pe-4 pe-lg-5'>
                                <p className='m-0'>Classes XI & XII at school as well as board level</p>
                            </div>
                        </li>
                        <li className='col-6 col-md-3 mb-5 mb-lg-0'>
                            <div className='py-4 pe-4 pe-lg-5'>
                                <p className='m-0'>Govt. scholarship exams such as NTSE (in Class X) & KVPY (Class XI & XII)</p>
                            </div>
                        </li>
                        <li className='col-6 col-md-3 mb-5 mb-lg-0'>
                            <div className='py-4 pe-4 pe-lg-5'>
                                <p className='m-0'>Competitive exams such as IIT JEE & NEET</p>
                            </div>
                        </li>
                    </ul>

                    <p className='px-4 text-center'>The classes will be conducted in post-school hours in online mode.</p>
                </div>
            </section>

            <section className='py-5 grayBoxTB text-primary'>
                <div className='mw800p'>
                    <h2 className='text-center mb-5'>Eligibility Criteria</h2>
                    <div className="row g-5">
                        <div className="col-12 col-md-6">
                            <div className='px-lg-4'>
                                <p><label className='d-inline-block uLStyle'>Minimum <span>80%</span></label></p>
                                <p className='m-0'>marks in Maths as well as Science in Class 9th for students applying for Class 10th</p>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className='px-lg-4'>
                                <p><label className='d-inline-block uLStyle'>Minimum <span>80%</span></label></p>
                                <p className='m-0'>marks in Maths as well as Science in Class 8th for students applying for Class 9th</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5 contLayout'>
                <div className='mw800p'>
                    <div className='row align-items-center g-5'>
                        <div className='col-12 col-lg-6'>
                            <img className='img-fluid' src="/admissions/BJF-Headstart-result-2023.jfif" alt="" />
                        </div>
                        <div className='col-12 col-lg-6'>
                            <h2 className='mb-4'>BJF Headstart Result</h2>
                            <p>The students of first batch of BJF Headstart 2020 (Class X) who joined BJF Stars 2021-23
                                have received incredible success in the competitive exams this year (IIT JEE 2023 &amp; NEET
                                2023) and have given 100% result.</p>
                            <p>284 Students from more than 100 schools across Punjab have benefitted from this program
                                with 76 in Class IX and 70 in Class X enrolled this year.</p>
                        </div>
                    </div>
                </div>
            </section>

            <div className='text-center py-4'>
                <a href="/pdf/Download-Forms-2024/BJF Headstart Application Form.pdf" download="Application Form" class="btn btn-outline-secondary btn-lg">Click here to download the application form</a>
            </div>

            <Footer />
        </div>
    )
}

export default BJFHeadStart