import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from '../../../Footer';

import AboutPunjab from './about-punjab';
import AsalVidya from './asal-vidya';
import Awal from './awal-allah-noor-upayea';
import BabaBullehShah from './baba-bulleh-shah';
import BudhDharm from './budh-dharm';
import ModernPunjabiPoetry from './modern-punjabi-poetry';
import Notice from './notice';
import NuktetoSuccess from './nukte-to-success';
import RabbDiKhoj from './rabb-di-khoj';
import SalokSheikh from './salok-sheikh';
import ShabadGuruArjan from './shabad-sri-guru-arjan-dev';
import SriGuruNanak from './sri-guru-nanak';


function SocialMaterials() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2024-26: Social Awareness Test Study Material</h1>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Tab.Container id="genSideNav" defaultActiveKey="01">
                            <div className='row g-0 normVTabs'>
                                <div className='col-12 col-md-3'>
                                    <div className='pe-md-4 h-100'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item><Nav.Link eventKey="01">Notice</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="02">Awal Allah Noor Upayea</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="03">Sri Guru Nanak Dev Jee</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="04">Salok Sheikh Farid Jee</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="05">Shabad Sri Guru Arjan Dev Jee</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="06">Budh Dharm</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="07">Rabb Di Khoj</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="08">Baba Bulleh Shah</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="09">Modern Punjabi Poetry</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="10">About Punjab</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="11">Nukte to Success</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="12">Asal Vidya</Nav.Link></Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                <div className='col-12 col-md-9 pe-2'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="01">
                                            <Notice />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="02">
                                            <Awal />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="03">
                                            <SriGuruNanak />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="04">
                                            <SalokSheikh />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="05">
                                            <ShabadGuruArjan />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="06">
                                            <BudhDharm />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="07">
                                            <RabbDiKhoj />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="08">
                                            <BabaBullehShah />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="09">
                                            <ModernPunjabiPoetry />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="10">
                                            <AboutPunjab />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="11">
                                            <NuktetoSuccess />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="12">
                                            <AsalVidya />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default SocialMaterials