import React from 'react';
import Footer from '../../Footer';


function BJFRepeaters2025() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-0'>Message for BJF Repeaters 2025</h1>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <p>Students who have cleared or appeared for the XII exams & are interested in preparing for NEET 2025 can apply for admission to the BJF Repeaters 2025 batch.</p> 
                        <p>The entrance test for the BJF Repeaters 2025 batch will be conducted on <strong>14 May 2024 (Tuesday) at AKSIPS (Ajit Karam Singh International School), Sector 65, Mohali Stadium Road, Phase 11, Mohali</strong>. The reporting time is <strong>11:45 am</strong>.</p>
                        <p>Please bring with you: (1) A copy of your school ID card (In case that's not available, bring AADHAR's copy), (2) Rs 100/- registration charges, (3) Two passport-sized photographs, (4) Blue/black ball pen.</p>
                        <a className='btn btn-primary' target='_blank' href="https://forms.gle/DTWcAkpU1b3mrP1e7">Apply here</a>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default BJFRepeaters2025