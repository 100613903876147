import React from 'react';
import { SideNav } from './adminSideNav';
import Dropdown from 'react-bootstrap/Dropdown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import "./css/adminUI.css";
import { Loading } from '../loading_small';
import { EditAnnouncement } from './editAnnouncement';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { URL1 } from '../config';
import { DeleteAnn } from './deleteAnnouncement';
import { PublishedWithChanges } from '@mui/icons-material';
import { AnnouncementPop } from './announcementPop';

export const Announcement = () => {  

    const [announcement, setannouncement] = useState(null)
    const [annList, setannList] = useState([])
    const Data = useSelector(state => state)
    const [smShow, setsmShow] = useState(false)
    const [load, setload] = useState(false)
    const [pop, setpop] = useState(false)
    const [msg, setmsg] = useState("")


    async function fetchData() {
        try{
          let res = await fetch(URL1 + '/get_announcements' , {
            method : "GET" , 
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Data.auth["access_token"],
            },
          })
          let resJson = await res.json()
          let ar = resJson.data
          ar.sort((a,b)=> {
            if( a['an_id'] > b['an_id']){
              return -1
            }
            
            else{
              return 1
            }
          })
          console.log(ar)
          
          setannList(ar)
          
    
        }
        catch (err) {
          
    
        }
        
      }

    useEffect(() => {
        fetchData()

      
    }, [])



    function formatDate(date) {
        var date = new Date(date)
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
      
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date =  dd + "/" + mm + "/" + yyyy;
        return date.toString();
        
      }


    async function publishIt(tf , itm) {
        if (tf){
            try{
                setload(true)
                let res = await fetch(URL1 + '/edit_announcement' , {
                method : "POST" , 
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Data.auth["access_token"],
                },
    
                body : JSON.stringify({
                    
                        "an_id": itm.an_id,
                        "title": itm.title , 
                        "description": itm.description,
                        "btn_link": itm.btn_link,
                        "btn_name": itm.btn_name,
                        "icon": itm.icon,
                        "publish": "yes"
                    
                })
                })
                if (res.status == 200) {
                    setload(false)
                    setpop(true)
                    setmsg("Announcement Published")
                }
    
                }
                
            
            catch (err) {      
                console.log(err)  
                setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
            }
        }
        else{
            try{
                setload(true)
                let res = await fetch(URL1 + '/edit_announcement' , {
                method : "POST" , 
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Data.auth["access_token"],
                },
    
                body : JSON.stringify({
                    
                        "an_id": itm.an_id,
                        "title": itm.title , 
                        "description": itm.description,
                        "btn_link": itm.btn_link,
                        "btn_name": itm.btn_name,
                        "icon": itm.icon,
                        "publish": "no"
                    
                })
                })
                if (res.status == 200) {
                    setload(false)
                    setpop(true)
                    setmsg("Announcement stop publishing")
                }
    
                }
                
            
            catch (err) {      
                console.log(err)  
                setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
            }
        }
    }

    console.log(annList,"R")
    
   
    

  return (
    <>
    {smShow !=false ? <DeleteAnn id={smShow} setSmShow={setsmShow} fetchData={fetchData} /> :
    
    <>
    { pop ? <AnnouncementPop setpopup={setpop} setann={setannouncement} msg={msg} fetchData={fetchData}/> : 
    <div className='inrPageTPAdmin'>
      <div className='row g-0'>
        <div className='col-12 col-lg-3 bdrR'>
          <div className='pt-4 ps-3'>
          <SideNav active={'announcement'} />
          </div>
        </div>
        {announcement != null ? <EditAnnouncement announcement={announcement} setannouncement={setannouncement} fetchData={fetchData}/> :  
        <div className='col-12 col-lg-9'>
            <div className='p-4'>
                <div className='text-end mb-3'>
                    <button className='btn btn-primary' onClick={()=> setannouncement(-1)} ><span className='d-inline-block fs-4' >+</span> Add New Announcement</button>
                </div>
                <div className='cListTable announce'>  
                        {/* { loaddd ? <div className='pt-5 text-center'> <Loading/></div>  :  */}

                    <table className='table cTable'>
                        <thead>
                            <tr>
                                <th className='col-1'>&nbsp;</th>
                                <th className='col-3'>Announcement Title</th>
                                <th className='col-3'>Button Name</th>
                                <th className='col-1'>Active</th>
                                <th className='col-3'>Date</th>
                                <th>&nbsp;</th>                                
                            </tr>
                        </thead>   
                        <tbody>
                            {annList.map(itm => {
                                return (
                                    <tr>
                                        <td>
                                            <span className={`annIco ${itm.icon}`} ></span>
                                        </td>
                                        <td>
                                            <strong className='text-primary'>{itm.title}</strong>
                                        </td>
                                        <td>{itm.btn_name}</td>
                                        <td>
                                            <div className='cstCheckFld'>
                                                <input type='checkbox' checked={itm.publish == "yes"} onChange={(e)=> publishIt(e.target.checked , itm)} />
                                                <label></label>
                                            </div>
                                        </td>
                                        <td>{formatDate(itm.edit_date)}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle>
                                                    <MoreVertIcon />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item>
                                                        <dl onClick={()=> setsmShow(itm.an_id)}>
                                                            <dt><DeleteIcon /></dt>
                                                            <dd>Delete
                                                                <div className='fs-6 text-700'>Delete this announcement</div>
                                                            </dd>
                                                        </dl>                                                 
                                                    </Dropdown.Item>
                                                    <Dropdown.Item>
                                                        <dl onClick={()=> setannouncement(itm)}>
                                                            <dt><ModeEditIcon /></dt>
                                                            <dd>Edit
                                                                <div className='fs-6 text-700'>Edit this announcement</div>
                                                            </dd>
                                                        </dl> 
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td> 
                                    </tr>

                                )
                            })}
                            
                            
                        </tbody>                 
                    </table>
                </div>
            </div>
        </div>}
      </div>
    </div>  } </>  }
    </>
  )
}
