import { LOGGED_OUT, LOGGED_IN, LOGGED_IN_ERROR } from "./actionType";

// ! for now only with login state
const INITIAL_STATE = {
    isSignedIn: false, // these are the componenets of authState
    access_token: null,
    email:null,
    mobile:null,
    name:null,
    profile:null,
    role: null,
    register_id:null,
    username:null, 
    loginError: null,
    applied_for:null
};

export const authReducer = (state = INITIAL_STATE, action) => {
    // ! update the redux state
    console.log("auth....",action)
    switch (action.type) {
        case LOGGED_IN:
            // no processing here
            return {
                ...state,
                isSignedIn: true,
                access_token: action.payload.auth_token,
                name: action.payload.name,
                role: action.payload.role,
                applied_for:action.payload.applied_for,
                loginError: null,
                register_id:action.payload.r_id
            };

        case "APPLIED_FOR":
            return {
                ...state,
                applied_for:action.payload
            };

        case LOGGED_IN_ERROR:
            return {
                ...state,
                loginError: action.payload,
            };

        case LOGGED_OUT:
            return {
                ...state,
                isSignedIn: false,
                email: null,
                mobile: null,
                access_token: null,
                name: null,
                profile: null,
                role: null,
                register_id: null,
                username: null,
                loginError: null,
                applied_for:null
            };
        default:
            return state;
    }
};
