import React from 'react';
import { Link } from 'react-router-dom';
import "./css/adminUI.css";

export const SideNav = ({active}) => {
    return (
        <nav className='adminSideNav'>
            <ul>
                <li>
                    <Link to="/dashboard1" className={`onlineApp ${active == 'dashboard1' ? 'active' : ''}`} >
                        Dashboard
                        {/* <span className='badge'>4</span> */}
                    </Link>
                </li>
                <li>
                    <Link to="/onlineApp" className={`onlineApp ${active == 'onlineforms' ? 'active' : ''}`} >
                        Online Application
                        {/* <span className='badge'>435</span> */}
                    </Link>
                </li>
                <li>
                    <Link to="/uploadedForm" className={`writtenForms ${active == 'writtenforms' ? 'active' : ''}`}>
                        Uploaded Written Forms
                        {/* <span className='badge'>2</span> */}
                    </Link>
                </li>
                <li>
                    <Link to="/announcement" className={`announcement ${active == 'announcement' ? 'active' : ''}`}>
                        Announcement
                        {/* <span className='badge'>0</span> */}
                    </Link>
                </li>
                
                <li>
                    <Link to="/adminMedia" className={`news ${active == 'media' ? 'active' : ''}`} >
                        Media & News
                        {/* <span className='badge'>2</span> */}
                    </Link>
                </li>
                <li>
                    <Link to="/requested-callbacks" className={`emails ${active == 'callback' ? 'active' : ''}`}>
                        Requested call backs
                        {/* <span className='badge'>2</span> */}
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export const SideNav2 = () => {
    return (
        <nav className='adminSideNav'>
            <ul>
                {/* <li>
                    <Link to="/admin" className='dashboard '>
                        Dashboard
                        <span className='badge'>4</span>
                    </Link>
                </li> */}
                <li>
                    <Link to="/onlineApp" className='onlineApp'>
                        Online Application
                        {/* <span className='badge'>435</span> */}
                    </Link>
                </li>
                <li>
                    <Link to="/announcement" className='announcement'>
                        Announcement
                        {/* <span className='badge'>0</span> */}
                    </Link>
                </li>
                <li>
                    <Link to="/uploadedForm" className='writtenForms active'>
                        Uploaded Written Forms
                        {/* <span className='badge'>2</span> */}
                    </Link>
                </li>
                {/* <li>
                    <Link to="/" className='news'>
                        News and Media
                        <span className='badge'>0</span>
                    </Link>
                </li> */}
            </ul>
        </nav>
    )
}


export const SideNav3 = ({active}) => {
    return (
        <nav className='adminSideNav'>
            <ul>
               
                <li>
                    <Link to="/requested-callbacks" className={`emails ${active == 'callback' ? 'active' : ''}`}>
                        Requested call backs
                        {/* <span className='badge'>2</span> */}
                    </Link>
                </li>
            </ul>
        </nav>
    )
}