import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Link, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { URL1 } from "../config";
import { loggedOut } from "../redux/authAction";
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import "./css/adminUI.css";
import { Button } from 'react-bootstrap';
import { ExportPopup } from './exportPopup';

export const AdminHeader = () => {

    const Data = useSelector(state => state);
    let dispatch = useDispatch();

    async function logout() {

        let res = await fetch(URL1 + '/logout', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + Data.auth['access_token']
          },
    
        })
        let resJson = await res.json()
        dispatch(loggedOut())
        setTimeout(() => {
          localStorage.getItem("persist:root");
          localStorage.removeItem("persist:root");
        }, 500);
    
      }

    const [scroll, setScroll] = useState(false)
    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 10)
        })
    }, [])

    const [pop, setpop] = useState(false)

  return (
    <div className={scroll ? "adminHdr scrolled" : "adminHdr"}>
        {pop ? <ExportPopup setpop={setpop}/> : 
        <header className='px-3 py-2'>
            <div className='container-fluid'>
                <div className='row align-items-center justify-content-between'>
                    <div className='col-12 pb-3 pb-md-0 col-md-3'>
                        <Link to="/dashboard1" className='navbar-brand'>
                            <img className='logo mx-sm-auto' src='/svg/logo-header.svg' alt="logo" />
                        </Link>
                    </div>
                    {/* <div className='col-12 col-md-6 pb-3 pb-md-0'>
                        <div className='schBox'>
                            <span className='ico'><SearchIcon /></span>
                            <input type='text' className='form-control' placeholder='Search' />
                        </div>
                    </div> */}
                    <div className='col-12 col-md-3 d-flex justify-content-center justify-content-md-end'>
                        <div className='pe-2 position-relative'> 
                                <Button onClick={()=> setpop(true)} title="Export Data">
                                    <FileUploadIcon />
                                </Button>
                        </div>
                        <Dropdown>
                            <Dropdown.Toggle variant="secondary">
                                <AccountCircle /><span className='unFLetter ms-2'>BJF Admin</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                <Dropdown.Item>
                                    <NavLink className='nav-link' exact activeClassName="active" to="/login" onClick={()=> {
                                        localStorage.getItem("persist:root");
                                        localStorage.removeItem("persist:root");
                                        localStorage.clear();
                                        dispatch(loggedOut())
                                        }}>
                                        Log Out
                                    </NavLink>
                                </Dropdown.Item>                                
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </div>

        </header> }       
    </div>
  )
}
