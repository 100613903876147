import React from 'react';
import { SideNav, SideNav3 } from './adminSideNav';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import "./css/adminUI.css";
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Loading } from '../loading';
import { URL1 } from '../config';
import { useState } from 'react';
import { AnnouncementPop } from './announcementPop';

export const AdminCallBacks = () => {
    const Data = useSelector(state => state)
    const [callList, setcallList] = useState([])
    const [load, setload] = useState(false)
    const [msg, setmsg] = useState("")
    const [pop, setpop] = useState(false)
    const [ann, setann] = useState(null)


    async function EditCall(call_id ,status ){
        try {
            setload(true)
            let res= await fetch(URL1+'/edit_call_status' ,{
                method: "GET" , 
                headers: {"Content-Type": "application/json" , Authorization: "Bearer " + Data.auth["access_token"]},

            })
            let resJson = await res.json()
            if(res.status == 200){
                setpop(true)
                setmsg("Successfuly Executed")
            }
            else{
                setpop(true)
                setmsg("Error Occured")
            }

            setload(false)

        } catch (error) {
            setpop(true)
            setmsg("Error Occured")
            setload(false)
        }
    }

    function formatDate(date) {
        var date = new Date(date)
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
      
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date =  dd + "/" + mm + "/" + yyyy;
        return date.toString();
        
      }

    async function fetchData(){
        try {
            setload(true)
            let res= await fetch(URL1+'/callback_list' ,{
                method: "GET" , 
                headers: {"Content-Type": "application/json" , Authorization: "Bearer " + Data.auth["access_token"]},

            })
            let resJson = await res.json()
            if(res.status == 200){
                console.log(resJson)
                setcallList(resJson.data)
            }

            setload(false)

        } catch (error) {
            console.log(error)
            setload(false)
        }
    }

    useEffect(() => {
      fetchData()
    }, [])


    async function publishIt(tf , idd) {
        try{
            var pbls = 'pending'
            if(tf){
                pbls = 'done'
            }

    
                

                setload(true)
                let res = await fetch(URL1 + `/edit_call_status?call_id=${idd}&status=${pbls}` , {
                method : "GET" , 
                headers: {
                    Authorization: "Bearer " + Data.auth["access_token"],
                }
    
                })
                if (res.status == 200) {
                    setload(false)
                    setpop(true)
                    setmsg("Successfuly Executed")
                }
                else{
                    setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
                    }

            
            
        }
        catch (err) {      
            setload(false)
            setpop(true)
            setmsg("Error Occured while processing")
        }
    }
    


  return (
    <div>
        <div className='inrPageTPAdmin'>
            <div className='row g-0'>
                <div className='col-12 col-lg-3 bdrR'>
                    <div className='pt-4 ps-3'>
                        {Data.auth.role == 'admin' ? 
                        <SideNav active={'callback'} /> : <SideNav3 active={'callback'}/> }
                    </div>
                </div>
                {load ? <Loading/> : 
                <div className='col-12 col-lg-9'>
                    {pop ? <AnnouncementPop setpopup={setpop}  setann={setann} msg={msg}  fetchData={fetchData} /> : 
                    <div className='p-4'>                        
                        <div className='cListTable announce'>  
                                {/* { loaddd ? <div className='pt-5 text-center'> <Loading/></div>  :  */}

                            <table className='table cTable'>
                                <thead>
                                    <tr>
                                        <th className='col-2'>Name</th>
                                        <th className='col-2'>Email</th>
                                        <th className='col-2'>Mobile Number</th>
                                        <th className='col-1'>Class</th> 
                                        <th className='col-1'>Location</th> 
                                        <th className='col-2'>Date of request</th> 
                                        <th className='col-2 text-end'>Action</th> 
                                               
                                    </tr>
                                </thead>   
                                {callList.length !=0 ? 
                                <tbody>
                                    {callList.map((itm , ind) => {
                                        return (
                                            <tr>
                                        <td>{itm[1]}</td>
                                        <td>{itm[2]}</td>
                                        <td>{itm[3]}</td>
                                        <td>{itm[4]}</td>
                                        <td>{itm[5]}</td>
                                        <td>{formatDate(itm[6])}</td>
                                        <td className='text-end'>
                                            <div className='cstCheckFld'>
                                                <input type='checkbox' checked={itm[7] == "done"} onChange={(e)=> publishIt(e.target.checked , itm[0])} />
                                                <label></label>
                                            </div>
                                        </td>
                                    </tr>  

                                        )
                                    })}
                                                          
                                </tbody>     : ""}            
                            </table>
                        </div>
                    </div> }
                </div>}
            </div>
        </div>
    </div>
  )
}
