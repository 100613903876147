export const checkValidation = ( setErrors, data ,key) => {
  let er = {}
  let hasEr = false
  switch (key) {
    case 'student_detail':
      if( data.student_detail.Reference == "") {
        er.Reference = "This Field is required"
        hasEr = true
      }
      else {
        er.Reference = ""
      }

      if( data.student_detail.Name == "") {
        er.Name = "This Field is required"
        hasEr = true
      }
      else {
        er.Name = ""
      }
      if( data.student_detail.Category == "") {
        er.Category = "This Field is required"
        hasEr = true
      }
      else {
        er.Category = ""
      }
      if( data.student_detail.Phone_Number == "") {
        er.Phone_Number = "This Field is required"
        hasEr = true
      }
      else {
        er.Phone_Number = ""
      }
      if( data.student_detail.DOB == "") {
        er.DOB = "This Field is required"
        hasEr = true
      }
      else {
        er.DOB = ""
      }
      if( data.student_detail.Gender == "") {
        er.Gender = "This Field is required"
        hasEr = true
      }
      else {
        er.Gender = ""
      }
      if( data.student_detail.Email == "") {
        er.Email = "This Field is required"
        hasEr = true
      }
      else {
        er.Email = ""
      }
      if( data.student_detail.Location == "") {
        er.Location = "This Field is required"
        hasEr = true
      }
      else {
        er.Location = ""
      }
      if( data.student_detail.Addr1 == "") {
        er.Addr1 = "This Field is required"
        hasEr = true
      }
      else {
        er.Addr1 = ""
      }
      if( data.student_detail.Addr2 == "") {
        er.Addr2 = "This Field is required"
        hasEr = true
      }
      else {
        er.Addr2 = ""
      }
      if( data.student_detail.Pincode == "") {
        er.Pincode = "This Field is required"
        hasEr = true
      }
      else {
        er.Pincode = ""
      }
      if( data.student_detail.District == "") {
        er.District = "This Field is required"
        hasEr = true
      }
      else {
        er.District = ""
      }
      if( data.student_detail.State == "") {
        er.State = "This Field is required"
        hasEr = true
      }
      else {
        er.State = ""
      }
      setErrors(p => ({...p,student_detail: er}))
      return hasEr
      
      
    case 'family_detail':
      if( data.family_detail.Father_Name == "") {
        er.Father_Name = "This Field is required"
        hasEr = true
      }
      else {
        er.Father_Name = ""
      }

      if( data.family_detail.Mother_Name == "") {
        er.Mother_Name = "This Field is required"
        hasEr = true
      }
      else {
        er.Mother_Name = ""
      }

      if( data.family_detail.Father_Qualification == "") {
        er.Father_Qualification = "This Field is required"
        hasEr = true
      }
      else {
        er.Father_Qualification = ""
      }

      if( data.family_detail.Mother_Qualification == "") {
        er.Mother_Qualification = "This Field is required"
        hasEr = true
      }
      else {
        er.Mother_Qualification = ""
      }
      
      if( data.family_detail.family_income == "") {
        er.family_income = "This Field is required"
        hasEr = true
      }
      else {
        er.family_income = ""
      }

      if( data.family_detail.Father_Occupation == "") {
        er.Father_Occupation = "This Field is required"
        hasEr = true
      }
      else {
        er.Father_Occupation = ""
      }

      if( data.family_detail.Mother_Occupation == "") {
        er.Mother_Occupation = "This Field is required"
        hasEr = true
      }
      else {
        er.Mother_Occupation = ""
      }

      if( data.family_detail.Agriculture_Land == "") {
        er.Agriculture_Land = "This Field is required"
        hasEr = true
      }
      else {
        er.Agriculture_Land = ""
      }
      setErrors(p => ({...p,family_detail: er}))
      return hasEr
      
    case 'education_10':
      if( data.education.School_Name == "") {
        er.School_Name = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Name = ""
      }
      if( data.education.School_Address == "") {
        er.School_Address = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address = ""
      }
      if( data.education.Applying == "") {
        er.Applying = "This Field is required"
        hasEr = true
      }
      else {
        er.Applying = ""
      }
      if( data.education.Location == "") {
        er.Location = "This Field is required"
        hasEr = true
      }
      else {
        er.Location = ""
      }
      if( data.education.Pincode == "") {
        er.Pincode = "This Field is required"
        hasEr = true
      }
      else {
        er.Pincode = ""
      }
      if( data.education.Overall_Percentage_9 == "") {
        er.Overall_Percentage_9 = "This Field is required"
        hasEr = true
      }
      else {
        er.Overall_Percentage_9 = ""
      }
      if( data.education.Science_Marks_9 == "") {
        er.Science_Marks_9 = "This Field is required"
        hasEr = true
      }
      else {
        er.Science_Marks_9 = ""
      }
      if( data.education.Maths_Marks_9 == "") {
        er.Maths_Marks_9 = "This Field is required"
        hasEr = true
      }
      else {
        er.Maths_Marks_9 = ""
      }
      setErrors(p => ({...p,education: er}))
      return hasEr
    case 'education_11':
      if( data.education.School_Name == "") {
        er.School_Name = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Name = ""
      }
      if( data.education.Board == "") {
        er.Board = "This Field is required"
        hasEr = true
      }
      else {
        er.Board = ""
      }
      if( data.education.School_Address == "") {
        er.School_Address = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address = ""
      }
      if( data.education.School_Address2 == "") {
        er.School_Address2 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address2 = ""
      }
      if( data.education.Pincode == "") {
        er.Pincode = "This Field is required"
        hasEr = true
      }
      else {
        er.Pincode = ""
      }
      if( data.education.Overall_Percentage_9 == "") {
        er.Overall_Percentage_9 = "This Field is required"
        hasEr = true
      }
      else {
        er.Overall_Percentage_9 = ""
      }
      if( data.education.Science_Marks_9 == "") {
        er.Science_Marks_9 = "This Field is required"
        hasEr = true
      }
      else {
        er.Science_Marks_9 = ""
      }
      if( data.education.Maths_Marks_9 == "") {
        er.Maths_Marks_9 = "This Field is required"
        hasEr = true
      }
      else {
        er.Maths_Marks_9 = ""
      }

      setErrors(p => ({...p , education: er}))
      return hasEr


    case 'education_neet':
      if( data.education.School_Name_10 == "") {
        er.School_Name_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Name_10 = ""
      }
      if( data.education.Board_10 == "") {
        er.Board_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.Board_10 = ""
      }
      if( data.education.School_Address_10 == "") {
        er.School_Address_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address_10 = ""
      }
      if( data.education.School_Address2_10 == "") {
        er.School_Address2_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address2_10 = ""
      }
      if( data.education.Pincode_10 == "") {
        er.Pincode_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.Pincode_10 = ""
      }
      
      if( data.education.Overall_Percentage_10 == "") {
        er.Overall_Percentage_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.Overall_Percentage_10 = ""
      }
      if( data.education.Science_Marks_10 == "") {
        er.Science_Marks_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.Science_Marks_10 = ""
      }
      if( data.education.Maths_Marks_10 == "") {
        er.Maths_Marks_10 = "This Field is required"
        hasEr = true
      }
      else {
        er.Maths_Marks_10 = ""
      }
      if( data.education.School_Name_12 == "") {
        er.School_Name_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Name_12 = ""
      }
      if( data.education.Board_12 == "") {
        er.Board_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.Board_12 = ""
      }
      if( data.education.School_Address_12 == "") {
        er.School_Address_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address_12 = ""
      }
      if( data.education.School_Address2_12 == "") {
        er.School_Address2_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.School_Address2_12 = ""
      }
      if( data.education.Pincode_12 == "") {
        er.Pincode_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.Pincode_12 = ""
      }
      if( data.education.Overall_Percentage_12 == "") {
        er.Overall_Percentage_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.Overall_Percentage_12 = ""
      }
      if( data.education.Physics_Marks_12 == "") {
        er.Physics_Marks_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.Physics_Marks_12 = ""
      }
      if( data.education.Chemistry_Marks_12 == "") {
        er.Chemistry_Marks_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.Chemistry_Marks_12 = ""
      }
      if( data.education.Biology_Marks_12 == "") {
        er.Biology_Marks_12 = "This Field is required"
        hasEr = true
      }
      else {
        er.Biology_Marks_12 = ""
      }
      if( data.education.Number_Attempt_Neet == "") {
        er.Number_Attempt_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Number_Attempt_Neet = ""
      }
      if( data.education.Mode_Prep_Neet == "") {
        er.Mode_Prep_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Mode_Prep_Neet = ""
      }
      if( data.education.Last_Roll_Neet == "") {
        er.Last_Roll_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Last_Roll_Neet = ""
      }
      if( data.education.Last_Rank_Air_Neet == "") {
        er.Last_Rank_Air_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Last_Rank_Air_Neet = ""
      }
      if( data.education.Last_Rank_Cat_Neet == "") {
        er.Last_Rank_Cat_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Last_Rank_Cat_Neet = ""
      }
      if( data.education.Physics_Marks_Neet == "") {
        er.Physics_Marks_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Physics_Marks_Neet = ""
      }
      if( data.education.Chemistry_Marks_Neet == "") {
        er.Chemistry_Marks_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Chemistry_Marks_Neet = ""
      }
      if( data.education.Biology_Marks_Neet == "") {
        er.Biology_Marks_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Biology_Marks_Neet = ""
      }
      if( data.education.Total_Marks_Neet == "") {
        er.Total_Marks_Neet = "This Field is required"
        hasEr = true
      }
      else {
        er.Total_Marks_Neet = ""
      }
      setErrors(p => ({...p , education: er}))
      return hasEr
      

      

  
    }

 
}