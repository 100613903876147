import React, { useEffect } from "react";
import "./css/apply.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { URL1 } from "../../config";

function PersonalDetails({srcImg1 , setdata,data,Errors,setErrors}) {
  console.log("line15" , data);
  // console.log("lin1e16", validationError);
  const [value, setValue] = React.useState("one");
  const [srcImg, setsrcImg] = useState(srcImg1);
 
  const [error, setError] = useState(false);
  const Data = useSelector((state) => state);
  console.log(Data);

  // async function profilePhoto(e) {
  //   console.log(Data.auth["access_token"]);
  //   // setsrcImg( URL1.createObjectURL1(e.target.files[0]) )
  //   const formData = new FormData();
  //   formData.append("file", e.target.files[0]);

  //   const res = await fetch(URL1 + "/upload_photo", {
  //     method: "POST",
  //     body: formData,
  //     headers: {
  //       Authorization: "Bearer " + Data.auth["access_token"],
  //     },
  //   });
  //   console.log(res);
  //   let resJson = await res.json();
  //   console.log(resJson);
  //   if (resJson.message == "Uploaded") {
  //     console.log("here");
  //     const res1 = await fetch(URL1 + "/get_profile", {
  //       method: "GET",
  //       headers: {
  //         Authorization: "Bearer " + Data.auth["access_token"],
  //       },
  //     });
  //     let imgb = await res1.blob();
  //     setsrcImg(URL.createObjectURL(imgb));
  //   }
  // }

  const checkValidation = (setErrors, data ,key) => {
    if( data.student_detail[key] ==""){
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: "This field is required"
          }
        }
      ))
      
    }
    else{
      setErrors(p => (
        {
          ...p ,
          student_detail : {
            ...p.student_detail , 
            [key]: ""
          }
        }
      ))
  
    }
   
  }

  const keyUp = (e) => {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.student_detail[id] = val
    
    checkValidation(setErrors, dummy, id)
  };

  function handleChange(e) {
    let val=e.target.value
    let id = e.target.id;
    let dummy = {...data}
    dummy.student_detail[id] = val
    setdata(dummy)
  }
  console.log(data)
  // useEffect(async () => {
    
  // }, [])
  
 
  
  return (
    <div>

        <div className='row g-5 formDetails'>
              <div className='col-12 pb-3 pb-lg-0 col-lg-6 col-xl-5'> 
                  <div className='proAvatarUpload mb-4' style={{ backgroundImage: `url(${srcImg})` }}>
                    {/* <button type='button' className='uploadBtn'>
                      <input type='file' onChange={profilePhoto} />
                    </button> */}
                  </div>

                  {/* <div className="image">
                    <img src={srcImg} className="image1" alt="img"></img>
                    <button type="button" className="addImgBtn">
                      <div onClick={() => document.getElementById("leaf").click()}>
                        <span className="addIco"></span>
                        <p className="text-center m-0">Upload Image</p>
                        <input
                          id="leaf"
                          type={"file"}
                          hidden={true}
                          onChange={profilePhoto}
                        />
                      </div>
                    </button>
                  </div> */}

                  <div className="mb-3">
                    <label className="form-label">Name</label>
                    <input className='form-control rounded'  type="text"
                      placeholder=""                      
                      value={data["student_detail"]["Name"]}
                      id="Name"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>                      
                    <small className="logTxtDnger">{Errors.student_detail.Name}</small>
                  </div>
                  
                  <div className='mb-3'>
                    <label className="form-label">Email</label>
                    <input className='form-control rounded'  type="email"
                      placeholder=""
                      
                      value={data["student_detail"]["Email"]}
                      id="Email"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>               
                    <small className="logTxtDnger">{Errors.student_detail.Email}</small>
                  </div>                  

                  <div className='mb-3'>
                      <label className="form-label">Phone Number</label>
                      <input className='form-control rounded'  type="number"
                        placeholder=""                          
                        value={data["student_detail"]["Phone_Number"]}
                        id="Phone_Number"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                          
                      <small className="logTxtDnger">{Errors.student_detail.Phone_Number}</small>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Date of Birth</label>
                    <input className='form-control rounded'  type="date"
                      placeholder=""
                      value={data["student_detail"]["DOB"]}
                      id="DOB"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>               
                    <small className="logTxtDnger">{Errors.student_detail.DOB}</small>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Gender</label>
                    <input className='form-control rounded'  type="text"
                      placeholder=""                      
                      value={data["student_detail"]["Gender"]}
                      id="Gender"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>                      
                    <small className="logTxtDnger">{Errors.student_detail.Gender}</small>
                  </div>

              </div>

              <div className='col-12 col-lg-6 col-xl-5 offset-xl-2'>                 

                  <div className="mb-3">
                    <label className="form-label">Category</label>
                    <select className="form-select" name="Category" id="Category" value= {data["student_detail"]["Category"]} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    } }  >
                      <option disabled > -- select an option -- </option>
                      <option value="obc">OBC</option>
                      <option value="gen">GEN</option>
                      <option value="sc">SC</option>
                      <option value="st">ST</option>
                    </select>                      
                    <small className="logTxtDnger">{Errors.student_detail.Category}</small>
                  </div>

                  <div className='mb-3'>
                      <label className="form-label">Address Line 1</label>
                      <input className='form-control rounded'  type="text"
                        placeholder=""                        
                        value={data["student_detail"]["Addr1"]}
                        id="Addr1"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>
                        
                      <small className="logTxtDnger">{Errors.student_detail.Addr1}</small>
                  </div>

                  <div className="mb-3">
                    <label className="form-label">Address Line 2</label>
                    <input className='form-control rounded'  type="text"
                      placeholder=""                      
                      value={data["student_detail"]["Addr2"]}
                      id="Addr2"
                      onChange={handleChange}
                      onKeyUp={keyUp}/>                      
                    <small className="logTxtDnger">{Errors.student_detail.Addr2}</small>
                  </div>

                  <div className='mb-3'>
                      <label className="form-label">City/Town/District</label>
                      <input className='form-control rounded'  type="text"
                        placeholder=""
                        
                        value={data["student_detail"]["District"]}
                        id="District"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.student_detail.District}</small>
                  </div>

                  <div className='mb-3'>
                      <label className="form-label">Pincode</label>
                      <input className='form-control rounded'  type="number"
                        placeholder=""                        
                        value={data["student_detail"]["Pincode"]}
                        id="Pincode"
                        onChange={handleChange}
                        onKeyUp={keyUp}/>                        
                      <small className="logTxtDnger">{Errors.student_detail.Pincode}</small>
                  </div>

                  <div className="mb-3">
                        <label className="form-label">State</label>
                        <input className='form-control rounded'  type="text"
                          placeholder=""                          
                          value={data["student_detail"]["State"]}
                          id="State"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.student_detail.State}</small>
                  </div>

                  {/* <div className="mb-3">
                      <label className="form-label">Location</label>
                      <div className='mb-4'>
                        <input className='form-control rounded'  type="text"
                          placeholder=""
                          
                          value={data["student_detail"]["Location"]}
                          id="Location"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>
                          
                        <small className="logTxtDnger">{Errors.student_detail.Location}</small>
                      </div>
                  </div> */}
                  
                  <div className="mb-3">
                    <label className="form-label">Location</label>
                    <select className="form-select" name="Location" id="Location" value= {data["student_detail"]["Location"]} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    } }  >
                      <option disabled > -- select an option -- </option>
                      <option value="Rural">Rural</option>
                      <option value="Semi Urban">Semi Urban</option>
                      <option value="Urban">Urban</option>
                    </select>                      
                    <small className="logTxtDnger">{Errors.student_detail.Location}</small>
                  </div>

                  <div className="mb-3">
                        <label className="form-label">Reference By</label>
                        <input className='form-control rounded'  type="text"
                          placeholder=""                          
                          value={data["student_detail"]["Reference"]}
                          id="Reference"
                          onChange={handleChange}
                          onKeyUp={keyUp}/>                          
                        <small className="logTxtDnger">{Errors.student_detail.Reference}</small>
                  </div>


              </div>
        </div>
          
    </div>
  );
}

export default PersonalDetails;
