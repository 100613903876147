import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store , {Persistor}from "./redux/store.js"
import {PersistGate} from 'redux-persist/integration/react';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store = {store}>
        <PersistGate loading={null} persistor={Persistor}>
            <App />
        </PersistGate>
    </Provider>
);