import React from 'react'
import { useEffect, useState } from 'react'
import '../css/LogForm.css'
import { Link, useNavigate } from 'react-router-dom';
import _ from "lodash";
import { URL1 } from "../config"
import { Loading } from '../loading';
import LogSideBox from './logSidebox.js';


function Forgot() {

  const [email, setEmail] = useState("")
  const [Errors, setErrors] = useState({})
  const history = useNavigate()
  const [load, setload] = useState(false)

  function inpChange(e) {
    setEmail(e.target.value)
  }

  function keyUp(e) {
    console.log(e.keyCode , "FFFFFFFFFFFF")
    let err = validate()
    if (!_.isEmpty(err)) {
      setErrors(err)
    }
    else {
      setErrors({})
      if (e.keyCode == 13) {
        submit()
      }
    }

  }

  function validate() {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!email) {
      errors.email = "Email is required!";
    }
    else if (!regex.test(email)) {
      errors.email = "This is not a valid email format!";
    }


    return errors;
  }

  async function submit() {
    let err = validate()

    if (!_.isEmpty(err)) {
      setErrors(err);
    } else {
      setErrors({});
      setload(true)


      let res = await fetch(URL1 + '/send_otp_register', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          "email": email,

        })
      })
      let resJson = await res.json()
      if(res.status == 200){

        history('/change-password', {state: { "email": email } } )
      }
      else {
        setload(false)
        let err = { "message": resJson['message'] }
        setErrors(err)
      }

    }
  }

  useEffect(() => {
    document.body.className = 'logUI';
    return () => { document.body.className = ''; }
  });

  return (
      <div>
    {load ? <Loading/> :
        <div className='row g-0'>
          <div className='col-12 col-lg-7'>
            <div className='logFormOuter'>
              <div className='logForm p-5 text-center'>
                  <div className='mb-4'>
                    <Link to="/" className='navbar-brand d-inline-block text-center'><img className='logo' src='/svg/logo-header.svg' alt="logo" /></Link>
                  </div>
                  <h1 className='mb-5'>Forgot Password</h1>
                  <p>Enter Registered Email</p>
                  <div className='mb-4 related'>
                    <input className='form-control rounded-pill'
                      type='text' placeholder='username@gmail.com' value={email} onKeyUp={keyUp} onChange={inpChange} />
                    <span className='rerrors'>{Errors.email}</span>
                  </div>
                  <div className='d-grid pt-2 mb-3'>
                    <button type='button' className='btn btn-lg btn-primary rounded-pill' onClick={submit}>Send reset mail</button>
                  </div>
                  <p className='text-center'><strong className='rerrors'>{Errors.message}</strong></p>
                  <p className='account text-center'>Go back to login page &nbsp; <Link to='/login' className='register'>from here</Link></p>
                  <hr className="my-3" />
                  <p className="text-center">
                    In case you are facing any issues, please drop an email at <a href="mailto:indiabjf@gmail.com">indiabjf@gmail.com</a>
                  </p>
              </div>
            </div>
          </div>

          <div className='col-12 col-lg-5'>
            <LogSideBox />
          </div>
        </div>  }
    </div>

  )
}

export default Forgot