import React from 'react'

function CoachingResults() {

    return (
        <>

            <div className='table-responsive outerBdr mb-5'>
                <table className='table minWidthSml'>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th>1st Year <br/>2013-14</th>
                            <th>2nd Year <br/>2014-15</th>
                            <th>3rd Year <br/>2015-16</th>
                            <th>4th Year <br/>2016-17</th>
                            <th>5th Year <br/>2017-18</th>
                            <th>6th Year <br/>2018-19</th>
                            <th>7th Year <br/>2019-20</th>
                            <th>8th Year <br/>2020-21</th>
                            <th>9th Year <br/>2021-22</th>
                            <th>10th Year <br/>2022-23</th>
                            <th>11th Year <br/>2023-24</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>No. of Students <br/>Registered</td>
                            <td>1285</td>
                            <td>1970</td>
                            <td>2250</td>
                            <td>1340</td>
                            <td>1416</td>
                            <td>1367</td>
                            <td>2574</td>
                            <td>339</td>
                            <td>980</td>
                            <td>1132</td>
                            <td>2675</td>
                        </tr>
                        <tr>
                        <td>No. of Students <br/>Qualified</td>
                            <td>68</td>
                            <td>135</td>
                            <td>120</td>
                            <td>101</td>
                            <td>132</td>
                            <td>132</td>
                            <td>135</td>
                            <td>36</td>
                            <td>99</td>
                            <td>73</td>
                            <td>Exams in <br/>Jan&#39;24</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export default CoachingResults