import React from "react";
import "../css/whoweare.css";

const WhoWeare = () => {
  return (
    <div>
      <div className="who-we-are-section">
        <h1 className="text-center mb-5 text-primary">Who Are We</h1>
        <div className="container-lg">
          <div className="pb-5 px-5 text-center">
          <p>Bhai Jaitajee Foundation India is an organization in the social entrepreneurship space and 
named after a hero from Sikh history. Bhai Jaita Ji was born in socio-economically disadvantaged family. However, the good fortune of growing up in an enlightened community that
exposed him to progressive ideas, holistic education, and practical training gave him the self-
esteem to lead a heroic life.
</p>
          </div>
        </div>
        <div className="p-0">
          <img src="/whoweare.png" className="w-100"/>
        </div>        
        <div className="container-lg pt-5">          
          <div className="row align-items-center">
            <div className="col-12 col-lg-3 offset-xl-1 bdrR pe-lg-5">
              <img src="/home-art-group.png" className="img-fluid"/>
            </div>
            <div className="col-12 col-lg-6 ps-lg-5">
              <p>
              Inspired by Bhai Jaita Ji's life and the Gurus who created the communities that transformed
people like him, we aim to help the marginalized and dispossessed realize their potential. We
seek to be innovative in our approaches so that we can demonstrate measurable results that
are commensurate with our efforts.
              </p>
              <p>
                <a className="btn btn-primary" href="/aboutus">Know More...</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeare;
