import React from 'react';
import Footer from '../../Footer';
import { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import IndianDonation from './indian';
import ForeignDonation from './foreign';
import CSRApproval from './csrApproval';

function Donation() {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>   

            <section className='titleBanner donation py-5'>
                <div className='container-lg'>
                    <h1 className='my-3'>Donations</h1>
                </div>
            </section>         

            <section className='contLayout py-3 py-lg-5'>
                <div className='container-lg'>
                    <div className='hTabs donations'>
                        <Tabs defaultActiveKey="indian">
                            <Tab eventKey="indian" title="Indian Donations">
                                <IndianDonation />
                            </Tab>
                            <Tab eventKey="foreign" title="Foreign Donations">
                                <ForeignDonation />
                            </Tab>   
                            <Tab eventKey="csr" title="CSR Approval Letter">
                                <CSRApproval />
                            </Tab>                 
                        </Tabs>
                    </div>                    
                </div>
            </section>

            <Footer />
        </div>
    )
}

export default Donation