import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./css/apply.css";

import Button from "react-bootstrap/Button";
import Footer from "../Footer";

import FamilyDetails from "./FamilyDetails";
import PersonalDetails from "./PersonalDetails";


import EducationDetails12 from "./EducationDetails12";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL1 } from "../../config";
import { useSelector } from "react-redux";
import {checkValidation , formatData} from "../../util/Validation"
import { Loading} from "../../loading"
import { AppliedPop } from "./appliedPop";
import {useDispatch} from "react-redux"


//save and next -> validate all fields if yes -> next tab and enable t1 ,t2 , stop here
//another tab  t1 ,t2 ,t3

function Apply() {
  const [value, setValue] = React.useState(1);
  const [screen, setScreen] = useState({1 : false , 2 : false , 3 : false});
  const Data = useSelector((state) => state);
  let navigate = useNavigate();
  const [load, setload] = useState(false)
  const [srcImg, setsrcImg] = useState("../add6.png");
  const [success, setsuccess] = useState(false)
  const [msg, setmsg] = useState("")
  const [error, setError] = useState("");
  const [maxVal, setmaxVal] = useState(1)

  let dispatch = useDispatch()

  function changeform(val) {
    if (val =="10") {
      return "BJF Headstart"
    }
    else if (val == "11"){
      return "BJF Stars"
    }
    else if (val == "neet") {
      return "BJF Repeaters"
    }
    
  }

  useEffect(() => {
    document.body.className = 'whiteHdr';
    return () => { document.body.className = ''; }
  });
  useEffect(() => {
    if(Data.auth.applied_for != null) {
      setsuccess(true)
      setmsg(`You Have already applied for ${changeform(Data.auth.applied_for)} Program`)
    }
  }, [])

  const [data, setdata] = useState({
    form_type: 'neet',
    "student_detail": {
      "Name": "",
      "DOB": "",
      "Gender":"" ,
      "Category": "", 
      "Phone_Number": "",
      "Email": "",
      "Addr1": "",
      "Addr2" : "",
      "District" : "",
      "State" : "",
      "Pincode" : "",
      "Location" : "" , 
      "Reference" : ""
  },
  "education": {
      "School_Name_10" : "",
      "Board_10" : "" ,
      "School_Address_10": "" ,
      "School_Address2_10" : "",
      "Pincode_10" : "",
      "Overall_Percentage_10" : "" ,
      "Science_Marks_10" : "",
      "Maths_Marks_10" : "",
      "School_Name_12" : "",
      "Board_12" : "",
      "School_Address_12" : "",
      "School_Address2_12" : "",
      "Pincode_12" : "",
      "Overall_Percentage_12" : "" ,
      "Physics_Marks_12" : "",
      "Chemistry_Marks_12" : "",
      "Biology_Marks_12" : "",
      "Number_Attempt_Neet" : "",
      "Mode_Prep_Neet" : "",
      "Last_Roll_Neet" : "",
      "Last_Rank_Air_Neet" : "",
      "Last_Rank_Cat_Neet" : "",
      "Physics_Marks_Neet" : "",
      "Chemistry_Marks_Neet" : "",
      "Biology_Marks_Neet" : "",
      "Total_Marks_Neet" : ""
  },
  "family_detail": {
      "Father_Name" : "",
      "Mother_Name" : "",
      "Father_Qualification" : "",
      "Mother_Qualification" : "",
      "Father_Occupation" : "",
      "Mother_Occupation" : "",
      "Family_Income" : "",
      "Agriculture_Land" : ""
  },
  "center_location" : ""
  });

  const [Errors, setErrors] = useState(
    {
      "pic" : "",
      "student_detail": {
        "Name": "",
        "DOB": "",
        "Gender":"" ,
        "Category": "", 
        "Phone_Number": "",
        "Email": "",
        "Addr1": "",
        "Addr2" : "",
        "District" : "",
        "State" : "",
        "Pincode" : "",
        "Location" : "",
        "Reference" : ""
    },
    "education": {
        "School_Name_10" : "",
        "Board_10" : "" ,
        "School_Address_10": "" ,
        "School_Address2_10" : "",
        "Pincode_10" : "",
        "Overall_Percentage_10" : "" ,
        "Science_Marks_10" : "",
        "Maths_Marks_10" : "",
        "School_Name_12" : "",
        "Board_12" : "",
        "School_Address_12" : "",
        "School_Address2_12" : "",
        "Pincode_12" : "",
        "Overall_Percentage_12" : "" ,
        "Physics_Marks_12" : "",
        "Chemistry_Marks_12" : "",
        "Biology_Marks_12" : "",
        "Number_Attempt_Neet" : "",
        "Mode_Prep_Neet" : "",
        "Last_Roll_Neet" : "",
        "Last_Rank_Air_Neet" : "",
        "Last_Rank_Cat_Neet" : "",
        "Physics_Marks_Neet" : "",
        "Chemistry_Marks_Neet" : "",
        "Biology_Marks_Neet" : "",
        "Total_Marks_Neet" : ""
    },
    "family_detail": {
        "Father_Name" : "",
        "Mother_Name" : "",
        "Father_Qualification" : "",
        "Mother_Qualification" : "",
        "Father_Occupation" : "",
        "Mother_Occupation" : "",
        "Family_Income" : "",
        "Agriculture_Land" : ""
    }})

    const renderForms = () => {
      if (value === 1) {
        return (
          <PersonalDetails srcImg={srcImg} setsrcImg={setsrcImg} setdata={setdata} data={data} Errors={Errors} setErrors={setErrors} error={error} setError={setError} />
        );
      } 
      else if (value === 2) {
        return (
          <EducationDetails12 setdata={setdata} data={data} Errors={Errors} setErrors={setErrors}/>
        );
      } else if (value === 3) {
        return (
          <FamilyDetails setdata={setdata} data={data} Errors={Errors} setErrors={setErrors} />
        );
      }
    };


  function changeView(e) {
    console.log("line 3289", value);
    if (value === 1) {
      let tf = checkValidation(setErrors, data ,'student_detail');
      let tfp = srcImg == "../add6.png"
      if (tfp) {
        setError("Please Upload Image")
      }
      else{
        setError("")
      }
      if (!tf && !tfp){
        setScreen(p => ({...p , 2: false}))
        setValue(2)
        setmaxVal(Math.max( maxVal , 2))
      }
    } else if (value === 2) {
      let tf1 = checkValidation(setErrors, data ,'student_detail');
      let tf2 = checkValidation(setErrors, data ,'education_neet');
      let tfp = srcImg == "../add6.png"
      if (tfp) {
        setError("Please Upload Image")
      }
      else{
        setError("")
      }
      if (!tf1 && !tf2 && !tfp){
        setScreen(p => ({...p , 3: false}))
        setValue(3)
        setmaxVal(Math.max( maxVal , 3))
      }

    } else if (value === 3) {
      let tf1 = checkValidation(setErrors, data ,'student_detail');
      let tf2=  checkValidation(setErrors, data ,'education_neet');
      let tf3 = checkValidation(setErrors, data ,'family_detail');
      let tfp = srcImg == "../add6.png"
      if (tfp) {
        
        setError("Please Upload Image")
      }
      else{
        setError("")
      }
      if (!tf1 && !tf2 && !tf3 && !tfp){
        submitForm()
      }
    }
  }

  
  async function submitForm() {
    try{
      setload(true)
      let res = await fetch(URL1 + "/apply_online", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Data.auth["access_token"],
        },
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      if (res.status == 200){
        console.log(resJson)
        setload(false)
        setsuccess(true)
        setmsg("Form Uploaded Successfully")
        dispatch({type : "APPLIED_FOR" , payload : 'neet'})
        
        
      }
      else{
        console.log(resJson)
        setload(false)
        setsuccess(true)
        setmsg("Error while Uploading form , Please try again")
      }
    }
    catch (error) {
      setload(false)
    }
    
     
  }


  

  return (
    <div>
      {success ? <AppliedPop setpopup={setsuccess} msg={msg}/> : 
      <>
      <div className="inrPageTopPad">
      {
              load ? <Loading/> :
          <section className='contLayout py-4 py-lg-5'>
              <div className='container-lg'>
                <div className="hdrTitle mb-4">
                  <h3> BJF Repeaters Batch form for NEET aspirants who have cleared XII</h3>
                </div>
                <div className="adMtabsWrap">
                  <Tabs
                      value={value}
                      onChange={(e,nv) => { setValue(nv)}}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="secondary tabs example"
                    >
                      <Tab value={1} label="Personal Details" disabled={1 >maxVal} />
                      <Tab value={2} label="Educational Details" disabled={2 >maxVal} />
                      <Tab value={3} label="Family Details"  disabled={3 >maxVal} />
                    </Tabs>
                    {renderForms()}
                </div>
                <div className='pt-3 footBtn bdrT d-flex justify-content-between'>
                  <div>
                    NOTE: <b className="text-danger">*</b> = Mandatory field
                  </div>
                  <div>
                    <Button variant="primary" type="button" size="md" onClick={changeView}>Save &amp; Next</Button>
                  </div>                      
                </div>
              </div>
          </section>}
      </div>
      <Footer />
      </> }
    </div>
  );
}

export default Apply;
