// Login
export const LOGGED_IN = "LOGGED_IN";
export const LOGGED_IN_ERROR = "LOGGED_IN_ERROR";
// logged out
export const LOGGED_OUT = "LOGGED_OUT";
// AppBar Action types
export const SIDE_BAR = "SIDE_BAR";

// DoctorProfile
export const FETCH_DOC_PROFILE = "FETCH_DOC_PROFILE";
export const FETCH_PATIENT_LIST = "FETCH_PATIENT_LIST";

// PatientProfile
export const FETCH_PATIENT_PROFILE = "FETCH_PATIENT_PROFILE";
export const FETCH_DOC_LIST = "FETCH_DOC_LIST";

// Appbar
export const TOGGLE_PERSONAL_DETAILS = "TOGGLE_PERSONAL_DETAILS";
