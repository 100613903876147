import React from 'react';
import { SideNav, SideNav2 } from './adminSideNav';
import Button from 'react-bootstrap/Button';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import "./css/adminUI.css";
import { useLoaderData, useLocation } from 'react-router-dom';
import { DeletePopup } from './deletePopup';
import { Loading } from '../loading';
import { useState } from 'react';
import { useEffect } from 'react';
import { URL1 } from '../config';
import { useSelector } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';


export const UploadedFormEdit = () => {
  let navigate = useNavigate();
  const [form, setform] = useState(null)
  let location = useLocation()
  const [popup, setpopup] = useState(false)
  const [ldr, setldr] = useState(false)
  const [formId, setformId] = useState(location.state.id)
  const Data = useSelector(state => state)
  const [application_status, setapplication_status] = useState(location.state.status)
  const [formUrl, setformUrl] = useState(location.state.form)
  

  async function changeStatus(id ,status) {
    try{
      setldr(true)
      let res = await fetch(URL1 + `/change_online_application_status?application_id=${id}&status=${status}` , {
        method : "GET" , 
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Data.auth["access_token"],
        },
      })
      let resJson = await res.json()
      if (res.status ==200){
        setapplication_status(status)
      }

    }
    catch (err) {

    }
    
  }

  useEffect(() => {
    console.log("dddssssss")
    console.log(formUrl)
  }, [])

  
  


  return (
    <div className='inrPageTPAdmin'>
      <div className='row g-0'>
        <div className='col-12 col-lg-3 bdrR'>
          <div className='pt-4 ps-3'>
          <SideNav active={'writtenforms'} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='p-4 position-relative'>
            <button type="button" className="backBtn" title="Back" onClick={() => navigate(-1)}><ArrowBackIcon /></button>
            <div className='row align-items-center hdrTitle mb-3'>              
                <div className='col-12 col-lg-7'>
                    {/* <h4>Headstart Application form for classes IX & X</h4> */}
                </div>
                <div className='col-12 col-lg-5'>
                    <div className='d-flex flex-wrap justify-content-end py-2'>
                    {ldr ? <div className='pt-2 pe-3'><Loading/></div>  : ""}
                        {
                          application_status =='Approved' ? 
                          <div className='d-flex align-items-center px-1'>
                              <button type='button' className='actStat approved'>Approved</button> 
                          </div> : 
                          <div className='d-flex align-items-center px-1'>
                          <button type='button' className='actStat approve' onClick={()=> {changeStatus(formId, 'Approved')}}>Approve</button> 
                      </div>
                        }
                       {
                          application_status=='Rejected' ? 
                          <div className='d-flex align-items-center px-1'>
                              <button type='button' className='actStat rejected'>Rejected</button> 
                          </div> : 
                          <div className='d-flex align-items-center px-1'>
                            <button type='button' className='actStat reject' onClick={()=> {changeStatus(formId, 'Rejected')}}>Reject</button> 
                        </div>
                        }
                        <div className='d-flex align-items-center px-1'>
                            <Button variant="outline-primary" type="button" size="sm" onClick={()=> setpopup(true)}>
                                <DeleteIcon />Delete
                            </Button>                            
                        </div>                                   
                    </div>
                </div>
            </div>
            { popup ? <DeletePopup id={location.state.id} setSmShow={setpopup} fetchData={()=> console.log("del")} type={'uploadedForm'} /> : 

            <div className='contRightWrap'>
                <div className='pt-3'>
                    <div className='mb-2 text-end'>
                        <a className='btn btn-primary' href={formUrl} download='Application Form'>Download</a> 
                    </div>

        
                    
                    <iframe src={formUrl} width="100%" height="800" />

                    
                </div>
            </div> }

          </div>
        </div>
      </div>
    </div>
  )
}
