import React, { Component } from 'react';
import Slider from "react-slick";

export default class MediaOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            // title:props.news[1],
            // photos:props.news[6]
        };

        this.title = props.news[1];
        this.photos = props.news[6]
    }
    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {

        return (
            <div>
                <div className='pb-5'>
                    <Slider
                        dots={true}
                        arrows={false}
                        asNavFor={this.state.nav2}
                        adaptiveHeight={true}
                        lazyLoad='progressive'
                        ref={slider => (this.slider1 = slider)}
                    >
                        {this.photos.map((itm) => {
                            return (
                                <div>
                                    <div className='text-center text-white'>
                                        <h6 className='mb-4'>{this.title}</h6>
                                        <figure className='d-inline-block m-0'>
                                            <img className='img-fluid' src={itm} alt="" />
                                        </figure>
                                    </div>
                                </div>

                            )
                        })}

                    </Slider>
                </div>

                <div className='thumbs'>
                    <Slider
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        slidesToShow={6}
                        swipeToSlide={true}
                        focusOnSelect={true}
                        arrows={true}
                        lazyLoad='progressive'
                        speed={500}
                        infinite={false}
                    >
                        {this.photos.map((itm) => {
                            return (
                                <div>
                                    <figure className='d-inline-block m-0 p-2 fxdHThumb'>
                                        <img src={itm} alt="" />
                                    </figure>
                                </div>

                            )
                        })}
                    </Slider>
                </div>

            </div>
        );
    }
}