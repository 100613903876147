import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Footer from '../../Footer';
import MediaOne from './media-one';
import { useState } from 'react';
import { URL1 } from '../../../config';
import { Loading } from '../../loading';

function BJFPress () {
    const [load, setload] = useState(false)
    const [newsList, setnewsList] = useState([])
    

    async function fetchData(){
        try {
            setload(true)
            let res= await fetch(URL1+'/get_news' ,{
                method: "GET" , 
                headers: {"Content-Type": "application/json"},

            })
            let resJson = await res.json()
            if(res.status == 200){
                setnewsList(resJson.data.filter(x => x[3] =='yes'))
            }

            setload(false)

        } catch (error) {
            setload(false)
        }
    }

    useEffect(() => {
        
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    useEffect(() => {
        fetchData()

    }, [])
    

    return (
        <div>
            <div className='inrPageTopPad'>
                <section className='py-5 bgLight text-primary text-center'>
                    <div className='container-lg'>
                        <h1 className='m-0'>BJF Press Coverage</h1>
                    </div>
                </section>
                {newsList.length !=0 ? 

                <section className='pt-4 pb-2 hTabs media'>
                    <Tabs defaultActiveKey="media0">
                        {newsList.reverse().map( (itm , ind) => {
                            return (
                                <Tab eventKey={`media${ind}`} title={itm[2]}>
                                    <MediaOne news={itm}/>
                                </Tab>
                                )
                            } )
                        }
                    </Tabs>
                </section> : 
                    <Loading />                
                }

                <Footer />
            </div>
        </div>                
    )
}

export default BJFPress