import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from '../../../Footer';

import AwanishSharanr from './awanish_sharanr';
import AsalVidhya from './asal_vidhya';
import JaswantSinghNegi from './jaswant-singh-negi';

function BJFInterviewMaterials() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>Interview Reading Materials</h1>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Tab.Container id="genSideNav" defaultActiveKey="01">
                            <div className='row g-0 normVTabs'>
                                <div className='col-12 col-md-3'>
                                    <div className='pe-md-4 h-100'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item><Nav.Link eventKey="01">Awanish_Sharan</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="02">Asal_Vidhya</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="03">Jaswant Singh Negi</Nav.Link></Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                <div className='col-12 col-md-9 pe-2'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="01">
                                            <AwanishSharanr />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="02">
                                            <AsalVidhya />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="03">
                                            <JaswantSinghNegi />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default BJFInterviewMaterials