import React from 'react'
import { useSelector } from 'react-redux'
import { URL1 } from '../../../config'
import { Loading } from '../../../loading'
import { AppliedPopup } from '../../appliedPopup'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation } from "react-router-dom";


function ProgramList() {

  const Data = useSelector(state => state)
  const [success, setsuccess] = useState(false)
  const [msg, setmsg] = useState("")
  const [loadr1, setloadr1] = useState(false)
  const [loadr2, setloadr2] = useState(false)
  const [loadr3, setloadr3] = useState(false)
  let dispatch = useDispatch()



  function changeform(val) {
    if (val == "10") {
      return "BJF Headstart"
    }
    else if (val == "11") {
      return "BJF Stars"
    }
    else if (val == "neet") {
      return "BJF Repeaters"
    }

  }



  async function uploadForm(e, ftt) {
    if (Data.auth.applied_for != null) {
      setsuccess(true)
      setmsg(`You have already applied for ${changeform(Data.auth.applied_for)} Program`)
    }
    else {
      let extension = e.target.files[0].name.slice((Math.max(0, e.target.files[0].name.lastIndexOf(".")) || Infinity) + 1)
      if (extension != 'pdf') {
        setsuccess(true)
        setmsg("only pdf files are alow")
        return
      }

      if (Data.auth.applied_for == '10') {

        setloadr1(true)
      }
      else if (Data.auth.applied_for == '11') {

        setloadr2(true)
      }
      else if (Data.auth.applied_for == 'neet') {

        setloadr3(true)
      }



      try {

        let ky = `students/${Data.auth.register_id}/written_form.pdf`


        const formData = new FormData();
        formData.append("form_type", 'written')
        formData.append("Applied_For", ftt)
        formData.append("key11", ky)
        formData.append("file", e.target.files[0])

        const res = await fetch(URL1 + "/upload_form", {
          method: "POST",
          body: formData,
          headers: {
            Authorization: "Bearer " + Data.auth["access_token"],
          },
        });
        console.log(res);
        let resJson = await res.json();


        console.log(resJson);
        if (resJson.message == "successful") {
          setsuccess(true)
          setmsg("Form Uploaded Successfully")
          dispatch({ type: "APPLIED_FOR", payload: ftt })


        }

        else {
          setsuccess(true)
          setmsg("Error While Uploading Form , Please Try Again")
        }
        if (Data.auth.applied_for == '10') {

          setloadr1(false)
        }
        else if (Data.auth.applied_for == '11') {

          setloadr2(false)
        }
        else if (Data.auth.applied_for == 'neet') {

          setloadr3(false)
        }

      } catch (err) {
        console.log(err);
        setsuccess(true)
        setmsg("Error While Uploading Form , Please Try Again")
        if (Data.auth.applied_for == '10') {

          setloadr1(false)
        }
        else if (Data.auth.applied_for == '11') {

          setloadr2(false)
        }
        else if (Data.auth.applied_for == 'neet') {

          setloadr3(false)
        }
      }



    }

  }











  // async function uploadForm(e, ftt) {
  //   if (Data.auth.applied_for != null){
  //     setsuccess(true)
  //     setmsg(`You have already applied for ${changeform(Data.auth.applied_for)} Program`)
  //   }
  //   else{

  //     let extension = e.target.files[0].name.slice((Math.max(0, e.target.files[0].name.lastIndexOf(".")) || Infinity) + 1)
  //     if (extension != 'pdf'){
  //       setsuccess(true)
  //       setmsg("only pdf files are alow")
  //       return 
  //     }

  //     let content_type = e.target.files[0].type
  //     console.log(content_type,"FFFFFFFFFFFFFFFFFFFFFFFFFFFF")
  //     if (Data.auth.applied_for == '10'){

  //         setloadr1(true)
  //     }
  //     else if (Data.auth.applied_for == '11'){

  //       setloadr2(true)
  //     }
  //     else if (Data.auth.applied_for == 'neet'){

  //         setloadr3(true)
  //     }



  //     const res11 = await fetch(URL1 + `/pre_signed_upload?object_name=written_form&ext=${extension}&content_type=${content_type}`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: "Bearer " + Data.auth["access_token"],
  //       },
  //     });
  //     let resJson11 = await res11.json();

  //     let url = resJson11.data.url
  //     const formData11 = new FormData()
  //     formData11.append('key', resJson11.data.fields.key)
  //     formData11.append("x-amz-algorithm", resJson11.data.fields['x-amz-algorithm'])
  //     formData11.append("x-amz-credential", resJson11.data.fields['x-amz-credential'])
  //     formData11.append("x-amz-date", resJson11.data.fields['x-amz-date'])
  //     formData11.append("policy", resJson11.data.fields.policy)
  //     formData11.append("x-amz-signature", resJson11.data.fields['x-amz-signature'])
  //     formData11.append("file", e.target.files[0])

  //     let res22 = await fetch(url, {
  //       method: 'POST',
  //       body: formData11
  //     });

  //     if(res22.ok){
  //       //
  //       const formData = new FormData();
  //       formData.append("form_type", 'written' )
  //       formData.append("Applied_For", ftt)
  //       formData.append("ext", extension)

  //       const res = await fetch(URL1 + "/upload_form", {
  //         method: "POST",
  //         body: formData,
  //         headers: {
  //           Authorization: "Bearer " + Data.auth["access_token"],
  //         },
  //       });
  //       console.log(res);
  //       let resJson = await res.json();


  //       console.log(resJson);
  //       if (resJson.message == "successful") {
  //         setsuccess(true)
  //         setmsg("Form Uploaded Successfully")
  //         dispatch({type : "APPLIED_FOR" , payload : ftt})
  //       }

  //       if (Data.auth.applied_for == '10'){

  //           setloadr1(true)
  //       }
  //       else if (Data.auth.applied_for == '11'){

  //         setloadr2(true)
  //       }
  //       else if (Data.auth.applied_for == 'neet'){

  //           setloadr3(true)
  //       }

  //     }
  //     else{
  //       if (Data.auth.applied_for == '10'){

  //           setloadr1(true)
  //       }
  //       else if (Data.auth.applied_for == '11'){

  //         setloadr2(true)
  //       }
  //       else if (Data.auth.applied_for == 'neet'){

  //           setloadr3(true)
  //       }

  //       setsuccess(true)
  //       setmsg("Error occured , Please Try Again")

  //     }

  //     }





  // }

  return (
    <div>
      {success ? <AppliedPopup setpopup={setsuccess} msg={msg} /> :
        <ul className='programs d-lg-flex flex-wrap align-items-center'>
          <li>
            <div className='listBox'>
              <h5>BJF Stars</h5>
              <p>Bhai Jaitajee Foundation India invites applications from brilliant students of class X in Punjab for two years (classes XI and XII) free coaching to prepare them for medical and engineering college admissions!!!</p>
              <div className='text-left pt-3'>
                <div className='btnGroup ms-0 mb-0'>
                  {Data.auth.isSignedIn ?
                    <>
                      {loadr2 ? <Loading /> : <> <button type='button' className='btn btn-primary'>Apply Now</button>

                        <div className='hvrDD'>

                          <Link to="/admission/apply-online1" className="btn btn-primary bdrB">Apply Online</Link>
                          <button type='button' className='btn btn-primary'>
                            Upload Form <input type="file" accept="application/pdf" onChange={(e) => uploadForm(e, '11')} />
                          </button>
                        </div>
                      </>}
                    </> : <Link to="/login" className="btn btn-primary bdrB">Login to Apply</Link>}

                </div>
                <a href='/pdf/Download-Forms-2024/BJF Stars Application Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
              </div>
            </div>
          </li>
          <li>
            <div className='listBox'>
              <h5>BJF Headstart</h5>
              <p>It is an online program for the students of Classes IX & X to strengthen their Science and Mathematic.</p>
              <div className='text-left pt-3'>
                <div className='btnGroup ms-0 mb-0'>
                  {Data.auth.isSignedIn ? <>
                    {loadr1 ? <Loading /> :
                      <> <button type='button' className='btn btn-primary'>Apply Now</button>

                        <div className='hvrDD'>
                          <Link to="/admission/apply-online" className="btn btn-primary bdrB">Apply Online</Link>
                          <button type='button' className='btn btn-primary' >
                            Upload Form <input type="file" accept="application/pdf" onChange={(e) => uploadForm(e, '10')} />
                          </button>
                        </div> </>} </> : <Link to="/login" className="btn btn-primary bdrB">Login to Apply</Link>}
                </div>
                <a href='/pdf/Download-Forms-2024/BJF Headstart Application Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
              </div>
            </div>
          </li>
          <li>
            <div className='listBox'>
              <h5>BJF Repeaters</h5>
              <p>Bhai Jaitajee Foundation India invites applications for BJF Repeaters Batch providing coaching for NEET to eligible students who have cleared XII.</p>
              <div className='text-left pt-3'>
                <div className='btnGroup ms-0 mb-0'>
                  {Data.auth.isSignedIn ? <>
                    {loadr3 ? <Loading /> :
                      <> <button type='button' className='btn btn-primary'>Apply Now</button>

                        <div className='hvrDD'>
                          <Link to="/admission/apply-online2" className="btn btn-primary bdrB">Apply Online</Link>
                          <button type='button' className='btn btn-primary'>
                            Upload Form <input type="file" accept="application/pdf" onChange={(e) => uploadForm(e, 'neet')} />
                          </button>
                        </div> </>} </> : <Link to="/login" className="btn btn-primary bdrB">Login to Apply</Link>}
                </div>
                <a href='/pdf/Download-Forms-2024/BJF Repeaters Batch Application Form.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
              </div>
            </div>
          </li>
          <li>
            <div className='listBox'>
              <h5>BJF Navodaya Mission</h5>
              <p>Under this program class 5 students of rural areas are given free coaching on Sundays all holidays for four months, for the entrance test to join Navodaya Vidyalayas in class 6.</p>
              <div className='pt-3'>
                <a download className='btn btn-primary btn-md me-3' href='/pdf/app-forms/v2/BJF-Navodaya-Application-Form.pdf'>Download Form</a>
              </div>
            </div>
          </li>
          <li className='w-100'>
            <div className='listBox'>
              <h5>BJF-supported Coaching for ISTC-CSIO</h5>
              <p>Deserving students are supported for a 40-days extensive coaching program for preparation of ISTC-CSIO
                entrance exam for admission to the Diploma courses offered by Indo-Swiss Training Centre (ISTC) of
                Central Scientific Instruments Organization (CSIO).</p>
              <p>21 students have been supported for this program to date by BJFI out of which the students who have
                completed the course are already placed in various reputed organizations.</p>
                <a href='/pdf/Download-Forms-2024/Application Form_BJF supported ISTC-CSIO Entrance Preparation.pdf' download="Application Form" className='btn btn-outline-secondary'>Download Form</a>
            </div>
          </li>
        </ul>}
    </div>
  )
}

export default ProgramList