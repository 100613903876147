import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';

const getOptions = (type, x_axis , y_axis)  => ({
    chart: {
      type,
      width: 400,
      height: 220,
    },
    colors: ['#4CAF50'],
    title: {
      text: _.startCase(``),
    },
    xAxis: {
      categories: x_axis
  },
    yAxis: {
      title: {
        text: 'Values',
      },
    },
    series: [
      {
        data: y_axis,        
      },
    ],
  });

function LocationDataGraph({ x_axis , y_axis }) {   
    
    return (
        <>
           <div className='graphHldr'>                
                <HighchartsReact highcharts={Highcharts} options={getOptions('bar'  , x_axis , y_axis )} />
           </div> 
        </>        
    )
}

export default LocationDataGraph