import React from 'react'

function CampusDetails() {

    return (
        <>
          <div className='pt-lg-5 px-lg-5'>
            <div className='row g-3 g-md-5 campDtls'>
                <div className='col-12 col-md-6'>
                    <h3><span>Hostel Facilities</span></h3>
                    <p>The BJF hostel provides a secure setting. In our secure and contemporary hostel facilities, there are separate areas for boys and girls.</p>
                </div>
                <div className='col-12 col-md-6 text-center text-md-end'>
                    <img className='img-fluid' src="/campus/collage-01.jpg" alt="Campus" />
                </div>
            </div>

            <div className='row g-3 g-md-5 campDtls'>
                <div className='col-12 col-md-6 order-md-2'>
                    <h3><span>Classrooms and Labs</span></h3>
                    <p>With modernization, BJF has created safe spaces for imparting knowledge. All of the classrooms and computer lab are furnished with modern, functional equipment. They are designed in such a way that it optimizes student learning by providing comfortable surroundings. Students activities include maintaining and keeping things clean.</p>
                </div>
                <div className='col-12 col-md-6 text-center order-md-1 text-md-end'>
                    <img className='img-fluid' src="/campus/collage-02.jpg" alt="Campus" />
                </div>
            </div>

            <div className='row g-3 g-md-5 campDtls'>
                <div className='col-12 col-md-6'>
                    <h3><span>Mess</span></h3>
                    <p>Food is an integral part of our lives which keeps us nourished and energized. BJF ensures that healthy and nutritious food is available to our students as a top most priority. All students are periodically assigned various tasks like deciding menu, serving and cooking. There is also a canteen area where refreshments are available.</p>
                </div>
                <div className='col-12 col-md-6 text-center text-md-end'>
                    <img className='img-fluid' src="/campus/collage-03.jpg" alt="Campus" />
                </div>
            </div>

            <div className='row g-3 g-md-5 campDtls'>
                <div className='col-12 col-md-6 order-md-2'>
                    <h3><span>Playground</span></h3>
                    <p>Co-curricular activities for a student are as important as studies. So, BJF provides a perfect blend of education and sports for the overall development of the student. For physical fitness of the students, we have playgrounds for volleyball and a Physical Training and Yoga Teacher.</p>
                </div>
                <div className='col-12 col-md-6 text-center order-md-1 text-md-end'>
                    <img className='img-fluid' src="/campus/collage-04.jpg" alt="Campus" />
                </div>
            </div>

          </div>
        </>
    )
}

export default CampusDetails