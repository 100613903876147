import React from 'react'

function AdmissionToIIT () {

    return (
        <div>
            <div className='mb-5'>
                <h6>BJF MISSION - "ADMISSIONS TO IIT/NIT/IIIT/IISER AND OTHER HIGH-QUALITY SCIENCE, ENGINEERING AND MEDICAL COLLEGES":</h6>
                <p>From 2014, BJF prepares brilliant EWS students to join IIT/NIT/IIIT/IISER and other High Quality Science, Engineering and Government Medical Colleges through two year on campus program. Students are selected through rigorous screening tests and interviews from the entire state of Punjab. Selected students are admitted to CBSE affiliated good schools of Chandigarh with 75-100% fee concession. Presently Saint Soldier International School Sector 28, Chandigarh (https://www.ssis.ac.in/) and Ajit Karam Singh International School (AKSIPS) Mohali (http://aksips65.aksips.in/) are two schools associated with BJF for this purpose. Very competent teachers known in Chandigarh for excellent results prepare these students for IIT JEE and Medical entrance tests through regular coaching in BJF premises.</p>
            </div>

            <div className='mwImgCont'>
                <div className='row g-3 align-items-center'>
                    <div className='col-12 col-lg-4 order-lg-2'>
                        <h3 className='text-primary'>HOSTEL FACILITIES</h3>
                        <p>Secure and modern hostel facilities SEPARATELY for boys and girls are available in our premises only.</p>
                    </div>
                    <div className='col-12 col-lg-8 px-5 order-lg-1'>
                        <img className='img-fluid' src='/admissions/IIT/cont01.jpg' alt="" />
                    </div>                    
                </div>
            </div>

            <div className='mwImgCont'>
                <div className='row g-3 align-items-center'>                    
                    <div className='col-12 col-lg-4'>
                        <h3 className='text-primary'>PHYSICAL FITNESS</h3>
                        <p>For physical fitness of the students we have play grounds for volleyball and a Physical Training and Yoga Teacher.</p>
                    </div>
                    <div className='col-12 col-lg-8 px-5'>
                        <img className='img-fluid' src='/admissions/IIT/cont02.jpg' alt="" />
                    </div>
                </div>
            </div>

            <div className='container-lg'>
                <div className='row g-3 align-items-center'>
                    <div className='col-12 col-lg-8 order-lg-2'>
                        <h3 className='text-primary'>MENTOSHIP & COACHING</h3>
                        <p>BJF is a unique institution where students are mentored 24×7 by the supervisors and mentors even in their self study hours after the school and coaching classes. The whole environment here is conducive to studies and all round growth of students. BJF students have shown excellent results in various entrance tests. In 2017 from 9 students of Medical stream 7 got selected for the MBBS course in Govt. Medical Colleges in Punjab and 2 joined B.Sc Nursing in AIIMS. In Non-medical stream out of 24 students, 2 joined IIT; 1 joined IIIT; 4 got NITs; 6 went to PEC University Chandigarh and 4 got into UIET other good Institutes of higher education. In 2016, when our first regular batch passed out success rate was nearly the same. From 2011 to 2013 BJF selected 5 students for super 30 program of Mr. Anand Kumar (Patna). 2013 onwards, BJF started supporting Navodaya Vidyalaya during their vacations. And it was in 2014 that a full-fledged two year program started at our campus in Sector 28, Chandigarh.</p>
                    </div>
                    <div className='col-12 col-lg-4 px-5 order-lg-1'>
                        <img className='img-fluid' src='/admissions/IIT/cont03.jpg' alt="" />
                    </div>                    
                </div>
            </div>

        </div>
    )
}

export default AdmissionToIIT