import React from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

function TabInstitute({filters ,selected ,  setselected }) {

    function changeSelect(e , itm ) {
        console.log(e.target.checked  , itm)
        
        let dt = [...selected['Institute']]
        if (e.target.checked) { 
            if (!dt.includes(itm)) {
                dt.push(itm)
            }
        }
        else{
            dt = dt.filter(item => item !== itm)
        }
        setselected(p => (
            {
                ...p,
                'Institute' : dt 
            }
        ))
    }
    console.log(filters['Institute'].length , "frtt")
    filters['Institute'].sort()

    return (
        <>
            <div className='filterDD'>
                <h2><span>List of Institutes</span></h2>
                <form name='institutesFltr'>
                    <div className='row'>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Institute'].slice(0,20).map(itm => {
                                        return (
                                        
                                            <li>
                                                <input checked={selected["Institute"].includes(itm)} onChange={(e) => changeSelect(e , itm )}  type="checkbox" name="institutes" />
                                                <div>
                                                    <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                    <span>{itm}</span>
                                                </div>                                    
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Institute'].slice(20,40).map(itm => {
                                        return (
                                        
                                            <li>
                                                <input checked={selected["Institute"].includes(itm)} onChange={(e) => changeSelect(e , itm )}  type="checkbox" name="institutes" />
                                                <div>
                                                    <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                    <span>{itm}</span>
                                                </div>                                    
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Institute'].slice(40,60).map(itm => {
                                        return (
                                        
                                            <li>
                                                <input checked={selected["Institute"].includes(itm)} onChange={(e) => changeSelect(e , itm )}  type="checkbox" name="institutes" />
                                                <div>
                                                    <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                    <span>{itm}</span>
                                                </div>                                    
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                        <div className='col-6 col-md-4 col-xl-3'>
                            <ul className='nonList checkFlds'>
                                {filters['Institute'].slice(60,83).map(itm => {
                                        return (
                                        
                                            <li>
                                                <input checked={selected["Institute"].includes(itm)} onChange={(e) => changeSelect(e , itm )}  type="checkbox" name="institutes" />
                                                <div>
                                                    <CheckBoxOutlineBlankIcon className='cBlank' /> <CheckBoxIcon className='cChecked' /> 
                                                    <span>{itm}</span>
                                                </div>                                    
                                            </li>
                                        )
                                    })}
                            </ul>
                        </div>
                 
                    </div>
                </form>
            </div>
        </>        
    )
}

export default TabInstitute