import React from 'react';
import { SideNav } from './adminSideNav';
import InputGroup from 'react-bootstrap/InputGroup';
import "./css/adminUI.css";
import DashLineChart from './dashboard-linechart';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { URL1 } from '../config';
import { useNavigate } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import Button from 'react-bootstrap/Button';
import { CleaningServices } from '@mui/icons-material';
import { time } from 'highcharts';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';



export const AdminDashboard = () => {
    const [load, setload] = useState(false)
    const [filter, setfilter] = useState(false)
    const [filterData, setfilterData] = useState({
        online10 : {} , 
        online11: {},
        onlineneet : {} , 
        written10 : {} , 
        written11: {}, 
        writtenneet: {}})


        var getDaysArray = function(start, end) {
            for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                arr.push(formatDate(new Date(dt)));
            }
            return arr;
        };
    
              

    var dateM = new Date();
    const [startDate, setstartDate] = useState(null)
    const [endDate, setendDate] = useState(null)
    const [hasFilter, sethasFilter] = useState(false)
    const [options, setoptions] = useState(
        {
          chart: {
            type: 'spline'
          },
          xAxis: [{
            type: 'date',
          }],
          title: {
            text: ''
          },
          series: []
        }
      )

    // const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];


    let navigate = useNavigate()

    const Data = useSelector(state => state) 
    const [datadb, setdatadb] = useState({
        online10 : {} , 
        online11: {},
        onlineneet : {} , 
        written10 : {} , 
        written11: {}, 
        writtenneet: {}})
    
        const [countT,setcountT] = useState({
            online10 : 0 , 
            online11: 0,
            onlineneet : 0 , 
            written10 : 0 , 
            written11: 0, 
            writtenneet: 0})

    



    function formatDate(date) {
        var date = new Date(date)
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
      
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date =  dd + "/" + mm + "/" + yyyy;
        return date.toString();
        
      }
    
    async function fetchData() {
        try{
            setload(true)
          let res = await fetch(URL1 + '/dashboard' , {
            method : "GET" , 
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Data.auth["access_token"],
            },
          })


        if(res.status == 200){
            var timeC = {
                online10 : {} , 
                online11: {},
                onlineneet : {} , 
                written10 : {} , 
                written11: {}, 
                writtenneet: {}}
            let resJson = await res.json()
            let keys = Object.keys(resJson)
            for (let key of keys ) {
              let lst = resJson[key]
              for (let index = 0; index < lst.length; index++) {
                let dfT = formatDate(lst[index]['date'])
                if( dfT in timeC[lst[index]['form_type'] + key] ){
                    timeC[lst[index]['form_type'] + key][dfT] +=1
                }
                else{
                    timeC[lst[index]['form_type'] + key][dfT] =1
                }
              }
            }

            console.log(timeC , "TTTTT")

            setdatadb(timeC)
            

        }

        setload(false)

          
          
    
        }
        catch (err) {
            setload(false)
        }
        
      }

    useEffect(() => {
        fetchData()

      
    }, [])


 
    


    useEffect(() => {
        if( startDate !=null || endDate !=null) {
            sethasFilter(true)
        }
        
        let series_10 = []
        let series_11 = []
        let series_neet = []
        let x_axis = new Set()
        let cnt = {
            online10 : 0 , 
            online11: 0,
            onlineneet : 0 , 
            written10 : 0 , 
            written11: 0, 
            writtenneet: 0}
        if(startDate !=null && endDate !=null) {
            
            x_axis = getDaysArray(startDate , endDate)
        }
        for (let name in datadb){
            for (let time in datadb[name]){
                let spl = time.split('/')
                let dd = spl[0]
                let mm = parseInt(spl[1]) -1
                let yy = spl[2]
                if(startDate !=null && endDate !=null && new Date(yy , mm , dd) >= new Date(startDate) && new Date(yy , mm , dd) <= new Date(endDate) ){
                cnt[name]+=datadb[name][time]
                if (name == 'online10' || name == 'written10'){
                    var c = 0
                    series_10.map(itm => {
                        if(itm[0] == time){
                            itm[1]+=datadb[name][time]
                        }
                        else{
                            c+=1 
                        }
                    })
                    if(c==series_10.length){
                        series_10.push([time , datadb[name][time]])
                    }
                    
                }
                if (name == 'online11' || name == 'written11'){
                    var c = 0
                    series_11.map(itm => {
                        if(itm[0] == time){
                            itm[1]+=datadb[name][time]
                        }
                        else{
                            c+=1 
                        }
                    })
                    if(c==series_11.length){
                        series_11.push([time , datadb[name][time]])
                    }
                }
        
                if (name == 'onlineneet' || name == 'writtenneet'){
                    var c = 0
                    series_neet.map(itm => {
                        if(itm[0] == time){
                            itm[1]+=datadb[name][time]
                        }
                        else{
                            c+=1 
                        }
                    })
                    if(c==series_neet.length){
                        series_neet.push([time , datadb[name][time]])
                    }
                }
                }
                else if( startDate == null && endDate == null){
                cnt[name]+=datadb[name][time]
                if (name == 'online10' || name == 'written10'){
                    var c = 0
                    series_10.map(itm => {
                        if(itm[0] == time){
                            itm[1]+=datadb[name][time]
                        }
                        else{
                            c+=1 
                        }
                    })
                    if(c==series_10.length){
                        series_10.push([time , datadb[name][time]])
                    }
                    
                }
                if (name == 'online11' || name == 'written11'){
                    var c = 0
                    series_11.map(itm => {
                        if(itm[0] == time){
                            itm[1]+=datadb[name][time]
                        }
                        else{
                            c+=1 
                        }
                    })
                    if(c==series_11.length){
                        series_11.push([time , datadb[name][time]])
                    }
                }
        
                if (name == 'onlineneet' || name == 'writtenneet'){
                    var c = 0
                    series_neet.map(itm => {
                        if(itm[0] == time){
                            itm[1]+=datadb[name][time]
                        }
                        else{
                            c+=1 
                        }
                    })
                    if(c==series_neet.length){
                        series_neet.push([time , datadb[name][time]])
                    }
                }
        
                }
                
            }
            }
            series_10.sort((x, y) => {
                let spl = x[0].split('/')
                let dd = spl[0]
                let mm = parseInt(spl[1]) -1
                let yy = spl[2]

                let spl1 = y[0].split('/')
                let dd1 = spl[0]
                let mm1 = parseInt(spl[1]) -1
                let yy1 = spl[2]

                return new Date(yy ,mm , dd ) < new Date(yy1,mm1,dd1) ? -1 : 1
            })
            series_11.sort((x, y) => {
                let spl = x[0].split('/')
                let dd = spl[0]
                let mm = parseInt(spl[1]) -1
                let yy = spl[2]

                let spl1 = y[0].split('/')
                let dd1 = spl1[0]
                let mm1 = parseInt(spl1[1]) -1
                let yy1 = spl1[2]

                return new Date(yy ,mm , dd ) < new Date(yy1,mm1,dd1) ? -1 : 1
            })
            series_neet.sort((x, y) => {
                let spl = x[0].split('/')
                let dd = spl[0]
                let mm = parseInt(spl[1]) -1
                let yy = spl[2]

                let spl1 = y[0].split('/')
                let dd1 = spl[0]
                let mm1 = parseInt(spl[1]) -1
                let yy1 = spl[2]

                return new Date(yy ,mm , dd ) < new Date(yy1,mm1,dd1) ? -1 : 1
            })
            let tmp = {
            chart: {
                type: 'spline'
            },
            xAxis: [{
                type: 'date',
              }],
            title: {
                text: ''
            },
            series: []
            }
            tmp.series.push({ name : 'bjf stars' , data : series_11})
            tmp.series.push({ name : 'bjf headstart' , data : series_10})
            tmp.series.push({ name : 'bjf repeater' , data : series_neet})
            // tmp.xAxis.categories = x_axis
            setoptions(tmp)
            setcountT(cnt)

        
    }, [startDate , endDate , datadb])


    


  return (
    <div className='inrPageTPAdmin'>
      <div className='row g-0'>
        <div className='col-12 col-lg-3 bdrR'>
          <div className='pt-4 ps-3'>
            <SideNav active={'dashboard1'} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          <div className='p-4'>

            <div className='row g-3 g-lg-0 mb-4'>
                <div className='col-12 col-lg-4'>
                    <h4 className='mb-2'>Number of New Applications Recieved</h4>
                    {/* <p>April 2023</p> */}
                </div>
                <div className='col-12 col-lg-8'>
                    <div className='d-flex justify-content-end w-100'>      
                    {hasFilter ? 
                    <div className='d-flex align-items-center px-1'>
                        <Button variant="primary" type="button" size="sm" onClick={() => {
                        sethasFilter(false)
                        setstartDate(null)
                        setendDate(null)
                        }}>
                            <ClearIcon />Clear Filter
                        </Button>
                        </div> : "" }          
                        <div className='d-flex align-items-center px-1'>
                            <label className='mx-3 my-0 fs-6 fw-normal'>From:</label>
                            <InputGroup className='dtW'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(date) => setstartDate(date)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            {/* <input value={startDate} defaultValue={startDate} className='form-control' type="date" placeholder='DD/MM/YYYY' onChange={(e)=> handleInpChange(e)}/> */}
                            </InputGroup>
                        </div>
                        <div className='d-flex align-items-center px-1'>
                            <label className='mx-3 my-0 fs-6 fw-normal'>To:</label>
                            <InputGroup className='dtW'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={endDate}
                                onChange={(date) => setendDate(date)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            </InputGroup>
                        </div>
                    </div>

                </div>
            </div>
            
            <div className='row g-3 g-lg-5 mb-5'>
                <div className='col-12 col-md-6 col-lg-4'>
                    <div className='dashStatBox'>
                        <div className='hdr'>
                            BJF Headstart <span>{countT['online10'] + countT['written10']}</span>
                        </div>
                        <div className='dBody row g-0'>
                            <div className='col-6 p-3 text-center'>
                                <label className='d-block pb-2'>{countT['written10']}</label>
                                <button type='button' className='btn btn-white' onClick={()=> navigate('/uploadedForm', {state : {"filter": "10"}})}>Uploaded Forms</button>
                            </div>
                            <div className='col-6 p-3 text-center'>
                                <label className='d-block pb-2'>{countT['online10']}</label>
                                <button type='button' className='btn btn-white' onClick={ ()=>navigate('/onlineApp' , {state : {"filter": "10"}})}>View Online</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <div className='dashStatBox'>
                        <div className='hdr'>
                            BJF Stars <span>{countT['online11'] + countT['written11']}</span>
                        </div>
                        <div className='dBody row g-0'>
                            <div className='col-6 p-3 text-center'>
                                <label className='d-block pb-2'>{countT['written11']}</label>
                                <button type='button' className='btn btn-white' onClick={()=> navigate('/uploadedForm' , {state : {"filter": "11"}})}>Uploaded Files</button>
                            </div>
                            <div className='col-6 p-3 text-center'>
                                <label className='d-block pb-2'>{countT['online11']}</label>
                                <button type='button' className='btn btn-white' onClick={()=>navigate('/onlineApp' , {state : {"filter": "11"}})}>View Online</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-6 col-lg-4'>
                    <div className='dashStatBox'>
                        <div className='hdr'>
                            BJF Repeaters <span>{ countT['onlineneet'] + countT['writtenneet']}</span>
                        </div>
                        <div className='dBody row g-0'>
                            <div className='col-6 p-3 text-center'>
                                <label className='d-block pb-2'>{countT['writtenneet']}</label>
                                <button type='button' className='btn btn-white' onClick={()=>navigate('/uploadedForm' , {state : {"filter": "neet"}})} >Uploaded Files</button>
                            </div>
                            <div className='col-6 p-3 text-center'>
                                <label className='d-block pb-2'>{countT['onlineneet']}</label>
                                <button type='button' className='btn btn-white' onClick={()=>navigate('/onlineApp', {state : {"filter": "neet"}}  )}>View Online</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mb-0'>
                <h4 className='text-primary mb-4'>Graphs</h4>
                <>
                    <HighchartsReact highcharts={Highcharts} options={options} />
                </>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}
