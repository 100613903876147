import React from 'react';


function BJFHeadStartBanner () {
    return (
        <div>
            <section className='py-5 text-center navyBanner'>
                <div className='bannerWrap'>
                    <h1 className='mb-4'>BJF HEADSTART</h1>

                    <div className='pt-3 d-flex flex-wrap justify-content-center'>
                        <div className='p-2'>
                            <a href="/admission/apply-online" className="btn btn-primary btn-lg">Apply Online</a>
                        </div>
                        <div className='p-2'>
                            <a href="https://forms.gle/cFQMG65Q1bnPhYMM8" target='_blank' class="btn btn-lg btn-primary">Google Form Link to Apply</a>
                        </div>
                        <div className='p-2'>
                            <a href="/pdf/Download-Forms-2024/BJF Headstart Application Form.pdf" download="Application Form" class="btn btn-primary btn-lg">Download Form</a>
                        </div>    
                    </div>

                </div>
            </section>
        </div>
    )
}

export default BJFHeadStartBanner