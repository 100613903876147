import React from 'react';
import Footer from '../../Footer';


function CbseResult2023() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2023 CBSE XII result</h1>
                        <p className='mb-0'>Outstanding performance of BJF Stars 2023 students in CBSE XII exams.</p>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <div className='text-center'>
                            <img className='img-fluid' src="/results/BJF-Stars-2023-CBSE-Result-Poster.jpg" alt="" />
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default CbseResult2023