import React from 'react'
import Home from './Home';
import Admissions from './Admissions';
import WhoWeare from './Whoweare';
import HereIt from './Hereit';
import Footer from './Footer';

export const RenderHome = () => {
  return (
    <div>
        <Home />
        <Admissions/>
        <WhoWeare />
        <HereIt />
        <Footer />
    </div>
  )
}
