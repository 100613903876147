import React from 'react';
import Footer from '../../Footer';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/selection/BJF-Stars-2024-26_Candidates-shortlisted-for-2nd-Round_5-Mar-24.pdf"></iframe>'
};

function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function BJFSelection2ndRound2024() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='navyBanner py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2024-26: Candidates shortlisted for the Second Round of Selection</h1>
                        <p className='mb-0 fs-5'>BJF Stars 2024-26: Congratulations to all the students who have been shortlisted for the second round of screening. Please check your interview dates. This round will consist of an Academic Interview, Social Awareness Assessment & Aptitude Test.</p>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Iframe iframe={fileUrl["fdFile"]} />
                        <div className='pt-2 text-center'>
                            <a className='btn btn-primary btn-md' href="/selection/BJF-Stars-2024-26_Candidates-shortlisted-for-2nd-Round_5-Mar-24.pdf" download="BJF-Stars-2024-26_Candidates-shortlisted-for-2nd-Round_5-Mar-24">Download</a>
                        </div>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default BJFSelection2ndRound2024