import React , {useState , useEffect} from 'react';
import { SideNav } from './adminSideNav';
import "./css/adminUI.css";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCardActionsUtilityClass } from '@mui/material';
import { useSelector } from 'react-redux';
import { URL1 } from '../config';
import { Loading } from '../loading_small';
import { AnnouncementPop } from './announcementPop';


export const EditMedia = ({news , fetchData , setnews}) => {
    const [fileBlobsLocal, setfileBlobsLocal] = useState([])
    const [load, setload] = useState(false)
    const [pop, setpop] = useState(false)
    const [msg, setmsg] = useState("")

    const Data = useSelector(state => state)
    const [cats, setcats] = useState([])
    const [hascatInfo, sethascatInfo] = useState(false)

    const [data, setdata] = useState(()=>{
        if(news !=-1){
            return {
                title :news[1] ,
                category:news[2],
                publish:news[3],
                files:[],
                photo_ids:news[5],
                photos_db: news[6],
                news_id:news[0]
            }
        }
        else{
            return {
                title :"" ,
                category:"",
                publish:"",
                files:[],
                photo_ids:[],
                photos_db:[],
                news_id:news
            }
        } } )
    

    const [Errors, setErrors] = useState({
        title :"" ,
        category:"",
        publish:"",
        cat_select:""
    })

    async function getCats() {
        try{
            let res = await fetch(URL1 + '/news_cat' , {
                method : "GET" , 
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + Data.auth["access_token"],
                }
               
                })
            let resJson = await res.json()
            if(res.status == 200){
                setcats(resJson.data)
            }

        }
        catch (err) {

        }
        
    }

    useEffect(() => {
        getCats()    
    }, [])

    function handleFiles(e) {
        let dm = {...data}
        let dm2 = [...fileBlobsLocal]
        for (let i=0;i<e.target.files.length;i++){
            dm.files.push(e.target.files[i])
            dm2.push(URL.createObjectURL(e.target.files[i]))
        }
        setfileBlobsLocal(dm2)
        setdata(dm)

        
    }


    const checkValidation = (setErrors, data ,key) => {
        let tf=false
         if( data[key] =="" ){
            tf= true
          setErrors(p => (
            {
              ...p ,
                [key]: "This field is required"
              
            }
          ))                  
        }
          else{
            setErrors(p => (
              {
                ...p ,
                  [key]: ""
              }
            ))
        
          }
          return tf
        }

    const checkAll = (setErrors , data) => {
        let tf = false
        let ks = Object.keys(data)
        for (var k in ks ){
            if (ks[k] == 'news_id' || ks[k] == 'publish'|| ks[k] == 'files' || ks[k] =='photo_ids' || ks[k] =='photos_db' ) {
                continue
            }
            if (checkValidation(setErrors , data , ks[k])){
                tf= true 
            }
        }
        return tf
    }


        const keyUp = (e) => {
            let val=e.target.value
            let id = e.target.id;
            let dummy = {...data}
            dummy[id] = val
            if(e.target.id == 'category'){
                if(e.target.value == ""){
                    sethascatInfo(true)
                }
                else{
                    sethascatInfo(false)
                }
            }
            
            checkValidation(setErrors, dummy, id)
          };
        
          function handleChange(e) {
            let val=e.target.value
            let id = e.target.id;
            let dummy = {...data}
            dummy[id] = val
            setdata(dummy)

          }


    async function submit(publish) {   
        console.log("HERE" , data )
        if (data.news_id ==-1){
            try{
            
                let dt = {...data}
                
                if (checkAll(setErrors , dt )){
                    
                }
                else{
                    dt['publish'] = publish
                    let fd = new FormData()
                    fd.append("title" , dt.title)
                    fd.append("category" , dt.category)
                    fd.append("publish" , dt.publish)
                    for (let index = 0; index < dt.files.length; index++) {
                        fd.append("files[]", dt.files[index]) 
                        
                    }
                    

                    setload(true)
                    let res = await fetch(URL1 + '/add_news' , {
                    method : "POST" , 
                    headers: {
                        Authorization: "Bearer " + Data.auth["access_token"],
                    },
        
                    body : fd
                    })
                    if (res.status == 200) {
                        setload(false)
                        setpop(true)
                        setmsg("Successfuly Executed")
                    }
                    else{
                        setload(false)
                    setpop(true)
                    setmsg("Error Occured while processing")
                        }
    
                }
                
            }
            catch (err) {      
                setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
            }

        }
        else{
            try{
                let dt = {...data}
                
                if (checkAll(setErrors , dt )){
                    
                }
                else{
                    dt['publish'] = publish
                    let fd = new FormData()
                    fd.append("news_id" , dt.news_id)
                    fd.append("title" , dt.title)
                    fd.append("category" , dt.category)
                    fd.append("publish" , dt.publish)
                    for (let index = 0; index < dt.files.length; index++) {
                        fd.append("files[]", dt.files[index]) 
                        
                    }
                    for (let index = 0; index < dt.photo_ids.length; index++) {
                        fd.append("photo_ids[]", dt.photo_ids[index]) 
                        
                    }
                    

                    setload(true)
                    let res = await fetch(URL1 + '/edit_news' , {
                    method : "POST" , 
                    headers: {
                        Authorization: "Bearer " + Data.auth["access_token"],
                    },
        
                    body : fd
                    })
                    if (res.status == 200) {
                        setload(false)
                        setpop(true)
                        setmsg("Successfuly Executed")
                    }
                    else{
                        setload(false)
                    setpop(true)
                    setmsg("Error Occured while processing")
                        }
    
                }
                
            }
            catch (err) {      
                setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
            }
        }

    }

    function deleteFile(ind , type) {
        if(type ==0){
            let dm = {...data}
            let dm2 = [...fileBlobsLocal]
            if (dm.files.length == 1) {
                dm.files = []
                setdata(dm)
                setfileBlobsLocal([])
              }
              else {
                
                let fn = dm.files.slice(0, ind).concat(dm.files.slice(ind + 1, dm.files.length))
                let fn2 = dm2.slice(0, ind).concat(dm2.slice(ind + 1, dm2.length))
                dm.files = fn
                setdata(dm)
                setfileBlobsLocal(fn2)
              }
        }
        else{
            let dm = {...data}
            if (dm.photo_ids.length == 1) {
                dm.photo_ids = []
                dm.photos_db = []
                setdata(dm)
                
              }
              else{
                let fn = dm.photo_ids.slice(0, ind).concat(dm.photo_ids.slice(ind + 1, dm.photo_ids.length))
                dm.photo_ids = fn
                let fn2 = dm.photos_db.slice(0, ind).concat(dm.photos_db.slice(ind + 1, dm.photos_db.length))
                dm.photos_db = fn2
                setdata(dm)
              }


        }

        
    }
    
    

  return (<>
        {pop ? <AnnouncementPop setpopup={setpop} setann={setnews} msg={msg} fetchData={fetchData}/> :
                <div className='col-12 col-lg-9'>
                    {load ? <div className='p-4 text-center'><Loading/><div className='small'>Loading...</div></div> : 
                    <div className='p-4 editAnnouncement'>
                        <h4 className='mb-4'>Add/Edit News and Media Information</h4>
                        <form name='announcement'>
                            <div className='mb-4'>
                                <p className='mb-2'><strong>Media/News Title</strong></p>
                                <input id='title' type="text" className='form-control py-2' 
                                placeholder=""   
                                onKeyUp={keyUp}
                                onChange={handleChange}
                                value={data.title}
                                autoComplete="off"
                                />
                                <small className="logTxtDnger">{Errors.title}</small>
                            </div>
                            <div className='mb-4'>
                                <div className='row align-items-center'>
                                    <div className='col-12 col-lg-6 pb-4 pb-lg-0'>
                                        <p className='mb-2'><strong>Add Category</strong></p>
                                        <input id='category'  type="text" className='form-control py-2'
                                        placeholder=""  
                                        autoComplete="off"
                                        onKeyUp={keyUp}
                                onChange={handleChange}
                                value={data.category}
                                        />
                                        <small className="logTxtDnger">{Errors.category}</small>

                                    </div>
                                    
                                    {cats.length !=0 && hascatInfo ? 
                                    <>
                                    <div className='col-12 col-lg-1 pb-4 pb-lg-0 text-center pt-4'>or</div>
                                    <div className='col-12 col-lg-5'>
                                        <p className='mb-2'><strong>Choose Category</strong></p>
                                        {cats.length !=0 ?
                                        <select id='cat_select' className='form-select py-2' value={data.category} onChange={(e)=> {
                                            let tmp = {...data}
                                            tmp.category = e.target.value
                                            setdata(tmp)
                                            let er = {...Errors}
                                            er.category = ""
                                            setErrors(er)
                                        }}>
                                            <option disabled selected value> -- select an option -- </option>
                                            {cats.map(itm => {
                                                return <option value={itm}>{itm}</option>
                                            })}
                                            
                                        </select> : ""}
                                        
                                        {/* <small className="logTxtDnger">{Errors.btn_name}</small> */}

                                    </div> </> : "" }
                                </div>
                            </div>
                            <div className='mb-4'>
                                <p className='mb-2'><strong>Upload Pictures</strong></p>
                                <div className='btnBrowse'>
                                    <input onChange={handleFiles} type="file" title='' multiple accept="image/*" />
                                    <CloudUploadIcon /> 
                                    <label>Browse Pictures</label>
                                    <div className='small'>Supported formats: JPEG, PNG</div>
                                </div>
                                {/* <small className="logTxtDnger">{Errors.title}</small> */}
                            </div>
                            {fileBlobsLocal.length !=0  ? 
                            
                            <div className='uploadedMedia d-flex flex-wrap justify-content-start'>
                                {fileBlobsLocal.map( (itm , ind) => {
                                    return (
                                        <div key={ind} className='p-2 gItem col-6 col-md-2'>
                                        <button className='delMediaBtn' title='Delete' onClick={(e)=> {
                                        e.preventDefault()
                                        deleteFile(ind , 0)
                                    }}><HighlightOffIcon /></button>
                                        <figure className='m-0'>
                                            <img className='img-fluid rounded-3 m-0' src={itm} alt='' />
                                        </figure>
                                    </div>
                                    )
                                } )}
                               
                            </div> : ""}
                            {data.photos_db.length !=0  ? 
                            
                            <div className='uploadedMedia d-flex flex-wrap justify-content-start'>
                                {data.photos_db.map( (itm , ind) => {
                                    return (
                                        <div key={ind} className='p-2 gItem col-6 col-md-2'>
                                        <button className='delMediaBtn' title='Delete' onClick={(e)=> {
                                        e.preventDefault()
                                        deleteFile(ind , 1)
                                    }}><HighlightOffIcon /></button>
                                        <figure className='m-0'>
                                            <img className='img-fluid rounded-3 m-0' src={itm} alt='' />
                                        </figure>
                                    </div>
                                    )
                                } )}
                               
                            </div> : ""}



                            <hr className='my-4' />
                            <div className='d-flex flex-wrap justify-content-end'>
                                <div className='px-2'>
                                    <button type='button' className='btn btn-outline-primary btn-md px-lg-5' onClick={()=> setnews(null)}>Discard</button>
                                </div>
                                <div className='px-2'>
                                    <button type='button' className='btn btn-outline-primary btn-md px-lg-5' onClick={()=> submit('no')}>Save Only</button>
                                </div>
                                <div className='px-2'>
                                    <button type='button' className='btn btn-primary btn-md px-lg-5' onClick={()=> submit('yes')}>Save and Publish</button>
                                </div>
                            </div>
                        </form>
                    </div>}
                </div>}
                </>
      
  )
}
