import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { URL1 } from '../../config';
import { useNavigate } from 'react-router-dom';


export const AppliedPop = ({setpopup , msg}) => {
    const Data = useSelector(state => state)
    let navigate = useNavigate()
    

  return (
    <div>
        <Modal
          size="md"
          show={true}
          onHide={() => setpopup(false)}
          centered
          >
            <Modal.Body>
              <div className='text-center'>
                <h6 className='mb-4'>{msg}</h6>
                <Button className='mx-3' variant="primary" type="button" size="lg" onClick={() => {
                    setpopup(false)
                    navigate("/");
                    }}>
                  OK
                </Button>
                
              </div>          
            </Modal.Body>
      </Modal> 
    </div>
  )
}