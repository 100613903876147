import React, { useEffect } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Footer from '../../Footer.js';
import AdmissionForm from './admission-form.js';
import ProgramList from './program-list.js';
import AdmissionToIIT from './admission-to-iit.js';
import AdmissionToISTC from './admission-to-istc.js';

function AdmissionPage () {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>

            <section className='p-4 py-5 text-center'>
                <img className='img-fluid' src='/admissions/banner-img.jpg' alt='Banner' /> 
            </section>  

            <AdmissionForm />    

            <section className='whiteBG p-3 p-lg-4'>
                <div className='container-lg'>
                    <div className='row g-3 gx-lg-5 align-items-center'>
                        <div className='col-12 col-lg-4'>
                            <div className='p-lg-4'>
                                <h1 className='leftBdr fsB mb-lg-4'>Our Programs</h1>
                                <p>Bhai Jaitajee Foundation India invites applications for BJF Headstart Program.</p>
                            </div>                            
                        </div>
                        <div className='col-12 col-lg-8'>
                            <div className='pt-4'>
                                <ProgramList />
                            </div>
                        </div>
                    </div>
                </div>
            </section> 

            <section className='p-3 p-lg-4'>
                <div className='container-lg'>
                    <h1 className='text-center fsB text-primary mb-5'>Admissions To</h1>
                    <div className='pb-4 hTabs'>
                        <Tabs defaultActiveKey="iit">
                            <Tab eventKey="iit" title="IIT/NIT/IIIT/IISER">
                                <AdmissionToIIT />
                            </Tab>
                            <Tab eventKey="istc" title="ISTC Under CSIO Chandigarh">
                                <AdmissionToISTC />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </section>    

            <Footer />
        </div>
    )
}

export default AdmissionPage