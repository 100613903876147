import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Loading } from '../loading';
import { useState } from 'react';

function DashLineChart({options , startDate , endDate }) {
 
    return (
        <>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </>
    )
}

export default DashLineChart