import React from 'react'

export const Loading = () => {
  return (
    <div className='loadingContainer py-4 text-center'>
        <div className="spinner-grow" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
        <div className='pt-2 text-secondary'>Loading</div>
    </div>    
  )
}