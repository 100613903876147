import React from 'react';

const fileUrl = {
    fdFile:
      '<iframe width="100%" height="766" scrolling="no" frameborder="no" src="/stats-info/BJFI-Balance-Sheet-2020-21.pdf"></iframe>'
};
function Iframe(props) {
    return (
        <div
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}

function BS202021() {
    return (
        <div>  
            <Iframe iframe={fileUrl["fdFile"]} />
        </div>
    )
}

export default BS202021