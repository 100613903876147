import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Footer from '../../Footer';
import Amritsar from './amritsar';
import Chandigarh from './chandigarh';
import Faridkot from './faridkot';
import Hoshiarpur from './hoshiarpur';
import Instructions from './instructions';
import Ludhiana from './ludhiana';
import Mansa from './mansa';
import Patiala from './patiala';
import RampuraPhul from './rampura-phul';

function BJFWrittenTestRolls2023() {

    return (
        <>
            <div className='inrPageTopPad'>

                <section className='titleBanner bgBlue py-5'>
                    <div className='container-lg'>
                        <h1 className='mb-2'>BJF Stars 2023-25 Written Test Roll Numbers</h1>
                        <p className='mb-0'>The applicants are requested to check their roll numbers & test center address. Roll numbers are being assigned to the rest of the applicants too and will be uploaded in the last week of January 2023.</p>
                    </div>
                </section>

                <section className='contLayout py-3 py-lg-5'>
                    <div className='container-lg'>
                        <Tab.Container id="genSideNav" defaultActiveKey="01">
                            <div className='row g-0 normVTabs'>
                                <div className='col-12 col-md-3'>
                                    <div className='pe-md-4 h-100'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item><Nav.Link eventKey="01">Amritsar</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="02">Chandigarh</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="03">Faridkot</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="04">Hoshiarpur</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="05">Ludhiana</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="06">Mansa</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="07">Patiala</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="08">Rampura Phul</Nav.Link></Nav.Item>
                                            <Nav.Item><Nav.Link eventKey="09">Instructions</Nav.Link></Nav.Item>
                                        </Nav>
                                    </div>
                                </div>
                                <div className='col-12 col-md-9 pe-2'>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="01">
                                            <Amritsar />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="02">
                                            <Chandigarh />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="03">
                                            <Faridkot />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="04">
                                            <Hoshiarpur />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="05">
                                            <Ludhiana />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="06">
                                            <Mansa />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="07">
                                            <Patiala />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="08">
                                            <RampuraPhul />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="09">
                                            <Instructions />
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </div>
                        </Tab.Container>
                    </div>
                </section>

            </div>
            <Footer />
        </>
    )
}

export default BJFWrittenTestRolls2023