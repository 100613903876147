import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./css/apply.css";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Footer from "../../components/Footer";

import FamilyDetails from "./FamilyDetails";
import PersonalDetails from "./PersonalDetails";

import EducationDetails11 from "./EducationDetails11";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL1 } from "../../config";
import { useSelector } from "react-redux";
import {checkValidation , formatData} from "../../util/Validation"
import {Loading} from "../../loading"
import Center_Location from "./center_Location";


//save and next -> validate all fields if yes -> next tab and enable t1 ,t2 , stop here
//another tab  t1 ,t2 ,t3

function Apply({srcImg , detail}) {
  console.log(detail)
  const [value, setValue] = React.useState("one");
  const [screen, setScreen] = useState({"one" : false , "two" : false , "three" : false , "four" : false});
  const Data = useSelector((state) => state);
  let navigate = useNavigate();
  const [load, setload] = useState(false)

  useEffect(() => {
    document.body.className = 'whiteHdr';
    return () => { document.body.className = ''; }
  });

  const [data, setdata] = useState(detail);
  console.log(data , "From DEE")

  const [Errors, setErrors] = useState(
    {
      "student_detail": {
        "Name": "",
        "DOB": "",
        "Gender":"" ,
        "Category": "", 
        "Phone_Number": "",
        "Email": "",
        "Addr1": "",
        "Addr2" : "",
        "District" : "",
        "State" : "",
        "Pincode" : "",
        "Location" : "",
        "Reference" : ""
    },
    "education": {
        "School_Name" : "",
        "Board" : "",
        "School_Address" : "" ,
        "School_Address2" : "",
        "Pincode" : "",
        "Overall_Percentage_9" : "" ,
        "Science_Marks_9" : "",
        "Maths_Marks_9" : ""
    },
    "family_detail": {
        "Father_Name" : "",
        "Mother_Name" : "",
        "Father_Qualification" : "",
        "Mother_Qualification" : "",
        "Father_Occupation" : "",
        "Mother_Occupation" : "",
        "Family_Income" : "",
        "Agriculture_Land" : ""
    },
    "center_location" : ""
 }
)

  const renderForms = () => {
    if (value === "one") {
      return (
        <PersonalDetails srcImg1={srcImg} setdata={setdata} data={data} Errors={Errors} setErrors={setErrors} />
      );
    } 
    else if (value === "two") {
      return (
        <EducationDetails11 setdata={setdata} data={data} Errors={Errors} setErrors={setErrors}/>
      );
    } else if (value === "three") {
      return (
        <FamilyDetails setdata={setdata} data={data} Errors={Errors} setErrors={setErrors} />
      );
    }
    else if (value === "four") {
      return (
        <Center_Location setdata={setdata} data={data} Errors={Errors} setErrors={setErrors} />
      );
    }
  };


  function changeView(e) {
    
      let tf1 = checkValidation(setErrors, data ,'student_detail');
      let tf2=  checkValidation(setErrors, data ,'education_11');
      let tf3 = checkValidation(setErrors, data ,'family_detail');
      let tf4 = false 
      if(data['center_location']== "" ){
        tf4 = true 
      }
      if (!tf1 && !tf2 && !tf3 && !tf4){
        submitForm()
      }    
  }

  
  async function submitForm() {
    try {
      setload(true)
      let res = await fetch(URL1 + "/edit_online_application", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Data.auth["access_token"],
        },
        body: JSON.stringify(data),
      });
      let resJson = await res.json();
      console.log(resJson)
      if(res.status == 200) {
        console.log(resJson.message)
      }
      setload(false)
      
    } catch (error) {
      setload(false)
    }
    
  }

  console.log(data , "DATA_FIR")

  

  return (
    <div>
      <div className="inrPageTopPad p-0">
      {load ? <Loading/> :
          <section className='contLayout py-4 py-lg-5'>
              <div className='container-lg'>
                <div className="hdrTitle mb-4">
                  <h3>BJF Stars Application form for Class XI - medical & nonmedical stream</h3>
                </div>
                <div className="adMtabsWrap">
                  <Tabs
                      value={value}
                      onChange={(e,nv) => { setValue(nv)}}
                      textColor="secondary"
                      indicatorColor="secondary"
                      aria-label="secondary tabs example"
                    >
                      <Tab value="one" label="Personal Details" disabled={screen["one"]} />
                      <Tab value="two" label="Educational Details" disabled={screen["two"]} />
                      <Tab value="three" label="Family Details"  disabled={screen["three"]} />
                      <Tab value="four" label="Center Location"  disabled={screen["four"]} />
                    </Tabs>
                    {renderForms()}
                </div>
                <div className='pt-3 footBtn text-end bdrT'>
                      <Button variant="primary" type="button" size="md" onClick={changeView}>Save Changes</Button>
                </div>

              </div>
          </section>}
      </div>
      
    </div>
  );
}

export default Apply;
