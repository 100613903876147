import React, { useEffect } from 'react';
import BJFStdList from './list-students';
import ResultFilter from './filter';
import GraphsArea from './graphsArea';
import Footer from '../../Footer';
import { URL1 } from '../../../config';
import { useState } from 'react';
import { Loading } from '../../../loading';

function BJFResultsUpdated() {

    const [load, setload] = useState(false)
    const [results, setresults] = useState(null)
    const [filterResults, setfilterResults] = useState(null)
    

    async function getresults(params) {
        try {
            setload(true)
            let res = await fetch(URL1 + '/get_alumni' , {method: "GET"})
            let resJson = await res.json()
            if(res.status == 200){
                setresults(resJson.data)
                setfilterResults(resJson.data)
                
            }
            else{
                setload(false)
                setresults(resJson.data)
                setfilterResults(resJson.data)
            }
            
        } catch (error) {
            setload(false)
            setresults([])
            setfilterResults([])
        }
    }

    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    useEffect(() => {
        getresults()
    }, [])
    

    return (
        <>
        {results !=null ?
            <div className='inrPageTopPad results'>

                <section className='contLayout bgLight py-3 py-lg-4'> 
                    <div className='container-lg position-relative'>                        
                        <ResultFilter results={results} setfilterResults={setfilterResults}/>
                        <BJFStdList filterResults={filterResults} />
                        <GraphsArea filterResults={filterResults} />
                    </div>
                </section>

                <Footer />
            </div> : <Loading/>}
        </>
    )
}

export default BJFResultsUpdated