import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { SideNav } from './adminSideNav';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "./css/adminUI.css";
import { URL1 } from '../config';
import { useSelector } from 'react-redux';
import { useState , useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeletePopup } from './deletePopup';
import { Loading } from '../loading_small';
import InfiniteScroll  from "react-infinite-scroll-component"

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import { useLocation } from 'react-router-dom';

export const OnlineApp = () => {

  let location = useLocation();
  console.log(location.state , "LOC")

  const Data = useSelector(state => state)
  let navigate = useNavigate();
  const [smShow, setSmShow] = useState(false);
  const [ld, setld] = useState([])

  const [elms, setelms] = useState([])
  const [filteredItems, setfilteredItems] = useState([])
  const [isEditable, setisEditable] = useState(false)
  const [idx, setidx] = useState(null)
  const [loaddd, setloaddd] = useState(true)
  const [Prog, setProg] = useState(() => {
    if (location.state !=null){
      return location.state.filter
    }
    else{
      return "0"
    }
  })

  console.log(Prog , "NEW")
  const [startDate, setstartDate] = useState(null)
  const [stopDate, setstopDate] = useState(null)
  const [hasFilter, sethasFilter] = useState(() => {
    if (location.state !=null){
      return true
    }
    else{
      return false
    } })
  const [hsM, sethsM] = useState(true)
  const [currPage, setCurrPage] = useState(0);

    

  async function getData(pg_no) {
    try{
      let res = await fetch(URL1 + `/get_online_applications?page_no=${pg_no}` , {
        method : "GET" , 
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Data.auth["access_token"],
        },
      })
      let resJson = await res.json()
      let ar = resJson.data

      var cp = [...ld]
      if (idx!=null){
        cp[idx] = false
      }

      if (ar.length ==0){
        sethsM(false)
        setloaddd(false)
        setld(cp)
        return []
      }

      ar.sort((a,b)=> {
        if(a[0] < b[0]){
          return -1
        }
        
        else{
          return 1
        }
      })
      setld([...cp , ...Array(ar.length).fill(false)])
      setloaddd(false)
      return ar 
    }
    catch (err) {
      setloaddd(false)
      setld(cp)
      sethsM(false)
      return [] 
    }
    
  }

  let fetchData = async () => {
    let ar = await getData(currPage)
    console.log(ar , "returned")
    

    setCurrPage(currPage+1)
    // setelms([...elms , ...ar] )

    // filterD([...elms , ...ar])
    setelms(ar)
    filterD(ar , startDate , stopDate , Prog)
    // setTimeout(() => {
      
    //   setelms([...elms , ...ar] )
    //   filterD([...elms , ...ar])
      
    // }, 1500);
  };
  

  function filterD(xd , startDate , stopDate , Prog ) {
    try {
      // console.log(elms,'FRTGER' , Prog )
      // let xd = [...elms]
      console.log(xd , Prog , "KKKKKKKKKKKKKk")
      if (Prog !='0'){
        xd = xd.filter(itm => itm[2] == Prog)
      }
      console.log(xd , "ht")
      console.log(startDate, stopDate, "gggggggg")
      if (startDate != null){

        xd = xd.filter(itm => new Date(itm[6]) >= new Date(startDate) )
      }
      if (stopDate != null){

        xd = xd.filter(itm =>new Date(itm[6]) <= new Date(stopDate))

      }
      setfilteredItems(xd)
      
    } catch (error) {
      
    }
    
  }

  useEffect(() => {
    fetchData()
    
  }, [])

  async function changeStatus(id ,status , idr) {
    try{
      var cp = [...ld]
      cp[idr] = true
      setld(cp)

      let res = await fetch(URL1 + `/change_online_application_status?application_id=${id}&status=${status}` , {
        method : "GET" , 
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + Data.auth["access_token"],
        },
      })
      let resJson = await res.json()
      if (res.status ==200){
        let tmp = [...filteredItems]
        tmp[idr][5] = status
        setfilteredItems(tmp)
      }
      cp = [...ld]
        cp[idr] = false
        setld(cp)

    }
    catch (err) {
      var cp = [...ld]
      cp[idr] = false
      setld(cp)
    }
    
  }

  console.log(elms , "elms")
  console.log(ld , "loads")
  

  return (
    <div className='inrPageTPAdmin'>
      <div className='row g-0'>
        <div className='col-12 col-lg-3 bdrR'>
          <div className='pt-4 ps-3'>
          <SideNav active={'onlineforms'} />
          </div>
        </div>
        <div className='col-12 col-lg-9'>
          {smShow ? <DeletePopup id={idx} setSmShow={setSmShow} fetchData={fetchData} type={'ext'}/> : 
          <div className='p-4'>
            
            <div className='d-flex flex-wrap justify-content-end py-2 position-relative'>
              {/* <button type="button" className="backBtn" title="Back" onClick={() => navigate(-1)}><ArrowBackIcon /></button> */}
              {hasFilter ? 
              <div className='d-flex align-items-center px-1'>
                <Button variant="primary" type="button" size="sm" onClick={() => {
                  sethasFilter(false)
                  setstartDate(null)
                  setstopDate(null)
                  setProg("0")
                  filterD(elms , null , null , "0")


                  }}>
                    <ClearIcon />Clear Filter
                </Button>
                </div> : "" }
                <div className='d-flex align-items-center px-1'>
                <label className='mx-2 my-0 fs-6 fw-normal text-nowrap'>Choose Program:</label>
                <Form.Select size='md' value={Prog} onChange={(e) => setProg(e.target.value)}>
                    <option value="0">All</option>
                    <option value="10">BJF Headstart</option>
                    <option value="11">BJF Stars</option>
                    <option value="neet">BJF Repeaters</option>
                </Form.Select>
                </div>
                <div className='d-flex align-items-center px-1'>
                <label className='mx-2 my-0 fs-6 fw-normal'>From:</label>
                {/* <InputGroup>
                    <Form.Control size="sm"
                    placeholder='DD/MM/YYYY'
                    aria-describedby="calFrom"
                    type='date'
                    onChange={(e) => {
                      sethasFilter(true)
                      setstartDate(e.target.value) }}
                    value={startDate}
                    />
                </InputGroup>  */}

                    <InputGroup className='dtW'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={startDate}
                                onChange={(date) => setstartDate(date)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            {/* <input value={startDate} defaultValue={startDate} className='form-control' type="date" placeholder='DD/MM/YYYY' onChange={(e)=> handleInpChange(e)}/> */}
                      </InputGroup>
                </div>
                <div className='d-flex align-items-center px-1'>
                <label className='mx-2 my-0 fs-6 fw-normal'>To:</label>
                {/* <InputGroup>
                    
                    <Form.Control size="sm"
                    placeholder='DD/MM/YYYY'
                    aria-describedby="calTo" 
                    type='date'
                    onChange={(e) => {
                      sethasFilter(true)
                      setstopDate(e.target.value)}}
                    value={stopDate}
                    />
                </InputGroup> */}
                        <InputGroup className='dtW'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                inputFormat="DD/MM/YYYY"
                                value={stopDate}
                                onChange={(date) => setstopDate(date)}
                                renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </InputGroup>
                </div>
                
                <div className='d-flex align-items-center px-1'>
                <Button variant="primary" type="button" size="md" onClick={() =>{
                  sethasFilter(true)
                  filterD(elms , startDate , stopDate , Prog )} }>
                   Apply
                </Button>
                </div>  

                {/* <div className='timeStamp'>
                  <span>December 5, 2022</span>
                </div>                 */}
            </div>

            <div className='cListTable'>  
            { loaddd ? <div className='pt-5 text-center'> <Loading/></div>  :        
          //   <InfiniteScroll
          //   dataLength={filteredItems.length}
          //   scrollThreshold={"100px"}
          //   next={fetchData}
          //   hasMore={hsM}
          //   loader={<Loading/>}
          // >       
                <table className='table cTable w1350'>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className='col-2'>Name</th>
                            <th className='col-2'>Batch</th>
                            <th className='col-2'>Email</th>
                            <th className='col-1'>Phone</th>
                            <th className='col-4'>&nbsp;</th>
                            
                        </tr>
                    </thead>   
                    <tbody>
                    
                      {filteredItems.length ==0 ? <tr><td colSpan={6} className="text-center">No results for your related search</td></tr> : filteredItems.map((itm , ind) => {
                        return (
                          <tr>
                            <td>
                              <span className='avatar me-3' style={{backgroundImage: `url(${itm[7]})`}}></span>
                            </td>
                            <td className='col-2'>{itm[1]}</td>
                            { itm[2]== '10' ? <td className='col-2'>BJF Headstart</td> : "" }
                            { itm[2]== '11' ? <td className='col-2'>BJF Stars</td> : "" }
                            { itm[2]== 'neet' ? <td className='col-2'>BJF Repeaters</td> : "" }
                    
                            <td className='col-2'>{itm[3]}</td>
                            <td className='col-1'>{itm[4]}</td>

                            
                             <td className='col-4'>
                              {itm[5] =='Approved' ?
                              <button type='button' className='actStat approved' >Approved</button> : 
                              <button type='button' className='actStat approve' onClick={()=> {changeStatus(itm[0], 'Approved' , ind)}}>Approve</button>}
                              {
                                itm[5]=='Rejected' ? 
                                <button type='button' className='actStat rejected' >Rejected</button> :
                                <button type='button' className='actStat reject' onClick={()=> {changeStatus(itm[0], 'Rejected' , ind )}}>Reject</button>
                              }
                          
                              <button type='button' className='delBtn' title='Delete' onClick={() => { 
                                setSmShow(true)
                                setidx(itm[0])
                                

                                }}></button>
                              <button type='button' className='viewBtn' title='View' onClick={()=> {navigate('/viewForm' , {"state":{"id" :itm[0] , "pic" : itm[7] }}) }}></button>
                              {ld[ind] ? <Loading/> : "" }
                            </td>
                            
                        </tr>
                        )})}
                     
                    </tbody>                 
                </table> }

            </div>

          </div> }
        </div>
      </div>
    </div>
  )
}
