import React from 'react';
import { SideNav } from './adminSideNav';
import "./css/adminUI.css";
import { Loading } from '../loading_small';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { URL1 } from '../config';
import { AnnouncementPop } from './announcementPop';
import { set } from 'react-hook-form';
import { useEffect } from 'react';

export const EditAnnouncement = ({announcement , setannouncement ,fetchData}) => {  

    const Data = useSelector(state => state)
    const [load, setload] = useState(false)
    const [pop, setpop] = useState(false)
    const [msg, setmsg] = useState("")
    const [anIDD, setanIDD] = useState(() => {
        if (announcement == -1 ) {
            return -1
        }
        else{
            return 1
        }
    })
    
    const [pbl, setpbl] = useState(() => {
        if (announcement == -1 ) {
            return -1
        }
        else{
            return announcement.icon
        }
    })

    const [annData, setannData] = useState({
        "title": "",
        "description": "",
        "btn_link": "",
        "btn_name": "",
        "icon": "",
        "publish": ""
    })

    const [Errors, setErrors] = useState({
        "title": "",
        "description": "",
        "btn_link": "",
        "btn_name": "",
        "icon": "",
        "publish": ""
    })

    useEffect(() => {
      if (announcement !=-1) {
        setannData({
        "an_id": announcement.an_id,
        "title": announcement.title,
        "description": announcement.description,
        "btn_link": announcement.btn_link,
        "btn_name": announcement.btn_name,
        "icon": announcement.icon,
        "publish": announcement.publish
        })
      }
    }, [])

    console.log(annData , pbl , announcement , anIDD ,'GPPP')
    


    


    const checkValidation = (setErrors, data ,key) => {
        let tf=false
        console.log(data, key , "FFFFFFFf")
        if( data[key] =="" ){
            tf= true
          setErrors(p => (
            {
              ...p ,
                [key]: "This field is required"
              
            }
          ))                  
        }
        else if (data[key].length <3 ){
            tf= true
            setErrors(p => (
              {
                ...p ,
                  [key]: "It should have atleast 3 characters"
              }
            ))
          }
          else{
            setErrors(p => (
              {
                ...p ,
                  [key]: ""
              }
            ))
        
          }
          return tf
        }

    const checkAll = (setErrors , data) => {
        console.log("here it comes" , data)
        let tf = false
        let ks = Object.keys(data)
        for (var k in ks ){
            console.log(ks[k] , "hhhhhhhhh")
            if (ks[k] == 'an_id' || ks[k] == 'publish' || ks[k] == 'icon' ) {
                continue
            }
            if (checkValidation(setErrors , data , ks[k])){
                tf= true 
            }
        }
        console.log(tf , "hhhhhhhhhhhhh")
        return tf
    }


        const keyUp = (e) => {
            let val=e.target.value
            let id = e.target.id;
            let dummy = {...annData}
            dummy[id] = val
            
            checkValidation(setErrors, dummy, id)
          };
        
          function handleChange(e) {
            let val=e.target.value
            let id = e.target.id;
            let dummy = {...annData}
            dummy[id] = val
            setannData(dummy)
            
          }

          function handleIcon(e) {
            let id = e.target.id 
            let dummy = {...annData}
            dummy['icon'] = id
            setannData(dummy)
            setpbl(id)
          }

    async function submit(publish) {   
        console.log("GOTO")
        if (anIDD == -1){
            try{
            
                let dt = {...annData}
                
                if (checkAll(setErrors , dt )){
                    
                }
                else{
                    if (dt['icon'] == ""){
                        dt['icon'] = 'mic'
                    }
                    dt['publish'] = publish
                    setload(true)
                    let res = await fetch(URL1 + '/post_announcement' , {
                    method : "POST" , 
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + Data.auth["access_token"],
                    },
        
                    body : JSON.stringify(dt)
                    })
                    if (res.status == 200) {
                        setload(false)
                        setpop(true)
                        setmsg("Successfuly Executed")
                    }
    
                }
                
            }
            catch (err) {      
                console.log(err)  
                setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
            }

        }
        else{
            try{
                let dt = {...annData}
                
                if (checkAll(setErrors , dt )){
                    
                }
                else{
                    
                    if (dt['icon'] == ""){
                        dt['icon'] = 'mic'
                    }
                    dt['publish'] = publish
                    setload(true)
                    let res = await fetch(URL1 + '/edit_announcement' , {
                    method : "POST" , 
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + Data.auth["access_token"],
                    },
        
                    body : JSON.stringify(dt)
                    })
                    if (res.status == 200) {
                        setload(false)
                        setpop(true)
                        setmsg("Successfuly Executed")
                    }
    
                }
                
            }
            catch (err) {      
                console.log(err)  
                setload(false)
                setpop(true)
                setmsg("Error Occured while processing")
            }
        }
        
        
        
          
        
    }


  return (<>
        {pop ? <AnnouncementPop setpopup={setpop} setann={setannouncement} msg={msg} fetchData={fetchData}/> :
        <div className='col-12 col-lg-9'>
            {load ? <div className='p-4 text-center'><Loading/><div className='small'>Loading...</div></div> : 
            <div className='p-4 editAnnouncement'>
                <h4 className='mb-4'>Add/Edit Announcement</h4>
                <form name='announcement'>
                    <div className='mb-4'>
                        <p className='mb-2'><strong>Announcement Title</strong></p>
                        <input type="text" className='form-control py-2' 
                        placeholder=""                      
                        value={annData["title"]}
                        id="title"
                        onChange={handleChange}
                        onKeyUp={keyUp}
                        />
                        <small className="logTxtDnger">{Errors.title}</small>
                    </div>
                    <div className='mb-4'>
                        <p className='mb-2'><strong>Description</strong></p>
                        <textarea rows='5' cols='10' className='form-control py-2' 
                        placeholder=""                      
                        value={annData["description"]}
                        id="description"
                        onChange={handleChange}
                        onKeyUp={keyUp}></textarea>
                        <small className="logTxtDnger">{Errors.description}</small>
                    </div>
                    <div className='mb-4'>
                        <div className='row'>
                            <div className='col-12 col-lg-8 pb-4 pb-lg-0'>
                                <p className='mb-2'><strong>Button Link</strong></p>
                                <input type="text" className='form-control py-2'
                                placeholder=""                      
                                value={annData["btn_link"]}
                                id="btn_link"
                                onChange={handleChange}
                                onKeyUp={keyUp}
                                />
                                <small className="logTxtDnger">{Errors.btn_link}</small>

                            </div>
                            <div className='col-12 col-lg-4'>
                                <p className='mb-2'><strong>Button Name</strong></p>
                                <input type="text" className='form-control py-2' 
                                placeholder=""                      
                                value={annData["btn_name"]}
                                id="btn_name"
                                onChange={handleChange}
                                onKeyUp={keyUp}
                                />
                                <small className="logTxtDnger">{Errors.btn_name}</small>

                            </div>
                        </div>
                    </div>
                    <div className='mb-2'>
                        <p className='mb-2'><strong>Choose Icon</strong></p>
                        <div className='d-flex flex-wrap text-center choose' >
                            <div className='py-2 px-4'>
                                <div className='chooseIcon'>
                                    <input type='radio' id='mic' name='chooseIcon' onChange={handleIcon} checked={pbl == 'mic'} />
                                    <span className='annIco mic'></span>
                                </div>  
                                <div className='small pt-1'>Events</div>                              
                            </div>
                            <div className='py-2 px-4'>
                                <div className='chooseIcon'>
                                    <input type='radio' id='speaker' name='chooseIcon' onChange={handleIcon} checked={pbl == 'speaker'}/>
                                    <span className='annIco speaker'></span>
                                </div>  
                                <div className='small pt-1'>Announcement</div>                               
                            </div>
                            <div className='py-2 px-4'>
                                <div className='chooseIcon'>
                                    <input type='radio' id='scholar'  name='chooseIcon' onChange={handleIcon} checked={pbl == 'scholar'}/>
                                    <span className='annIco scholar'></span>
                                </div> 
                                <div className='small pt-1'>Scholarship</div>                                
                            </div>
                            <div className='py-2 px-4'>
                                <div className='chooseIcon'>
                                    <input type='radio' id='brainstorm'  name='chooseIcon' onChange={handleIcon} checked={pbl == 'brainstorm'}/>
                                    <span className='annIco brainstorm'></span>
                                </div> 
                                <div className='small pt-1'>Brainstorming</div>                                
                            </div>
                            <div className='py-2 px-4'>
                                <div className='chooseIcon'>
                                    <input type='radio' id='intern' name='chooseIcon' onChange={handleIcon} checked={pbl == 'intern'}/>
                                    <span className='annIco intern'></span>
                                </div> 
                                <div className='small pt-1'>Internship</div>                                
                            </div>
                            <div className='py-2 px-4'>
                                <div className='chooseIcon'>
                                    <input type='radio' id='program' name='chooseIcon' onChange={handleIcon} checked={pbl == 'program'}/>
                                    <span className='annIco program'></span>
                                </div>   
                                <div className='small pt-1'>Program</div>                              
                            </div>
                        </div>
                    </div>
                    <p className='m-0 text-secondary small'>Note: the name given under icon will not appear on the announcements screen, it is given just for the identification of icon.</p>
                    <hr className='my-4' />
                    <div className='d-flex flex-wrap justify-content-end'>
                        <div className='px-2'>
                            <button type='button' className='btn btn-outline-primary btn-md px-lg-5' onClick={()=> setannouncement(null)}>Discard</button>
                        </div>
                        <div className='px-2'>
                            <button type='button' className='btn btn-outline-primary btn-md px-lg-5' onClick={()=> submit('no')} >Save Only</button>
                        </div>
                        <div className='px-2'>
                            <button type='button' className='btn btn-primary btn-md px-lg-5' onClick={()=> submit('yes')} >Save and Publish</button>
                        </div>
                    </div>
                </form>
            </div> } 
        </div> }
          </>

      
  )
}
