import React from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { URL1 } from '../../../config'
import { Loading } from '../../../loading'
import { AppliedPopup } from '../../appliedPopup'
import { Link } from 'react-router-dom'

function AdmissionForm() {

  const Data = useSelector(state => state)
  const [load, setload] = useState(false)
  const [success, setsuccess] = useState(false)
  const [msg, setmsg] = useState("")

  const [fields, setfields] = useState({
    name: "",
    email: "",
    mobile: "",
    location: "",
    clas: ""


  })

  const [Errors, setErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    location: "",
    clas: ""


  })

  async function CallMe() {


  }

  const checkValidation = (setErrors, data, key) => {
    let tf = false
    if (data[key] == "") {
      setErrors(p => (
        {
          ...p,

          [key]: "This field is required"

        }
      ))
      tf = true

    }
    else if (key == 'name' && /^[A-Za-z\s]*$/.test(data[key]) == false) {
      setErrors(p => (
        {
          ...p,

          [key]: "name can only have alphabets and space"

        }
      ))
      tf = true
    }

    else if (key == 'mobile' && data[key].length != 10) {
      setErrors(p => (
        {
          ...p,
          [key]: "Phone no must contain 10 digits"
        }
      ))
    }

    else if (key == 'email' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data[key]) == false) {
      setErrors(p => (
        {
          ...p,

          [key]: "Please Enter valid Email"

        }
      ))
      tf = true
    }
    // else if (data[key].length <3 ){
    //   setErrors(p => (
    //     {
    //       ...p ,

    //         [key]: "It should have atleast 3 characters"

    //     }
    //   ))
    //   tf= true
    // }
    else {
      setErrors(p => (
        {
          ...p,

          [key]: ""

        }
      ))


    }

    return tf

  }

  const keyUp = (e) => {
    let val = e.target.value
    let id = e.target.id;
    let dummy = { ...fields }
    dummy[id] = val

    checkValidation(setErrors, dummy, id)
  };

  function handleChange(e) {
    let val = e.target.value
    let id = e.target.id;
    let dummy = { ...fields }
    dummy[id] = val
    setfields(dummy)
  }



  async function handleSubmit(e) {
    e.preventDefault()
    try {
      let tf = false
      let dummy = { ...fields }
      let ck = ['name', 'email', 'clas', 'mobile', 'location']
      for (let i = 0; i < ck.length; i++) {
        if (checkValidation(setErrors, dummy, ck[i])) {
          tf = true
        }

      }
      console.log('hereeee', tf)
      if (tf) {
        return
      }
      else {
        setload(true)
        let res = await fetch(URL1 + "/request_call", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + Data.auth["access_token"],
          },
          body: JSON.stringify(dummy),
        });
        let resJson = await res.json();
        if (res.status == 200) {
          console.log(resJson)
          setload(false)
          setsuccess(true)
          setmsg("Form Uploaded Successfully")



        }
        else {
          console.log(resJson)
          setload(false)
          setsuccess(true)
          setmsg("Error Occured , Please Try Again")
        }
      }


    } catch (error) {


    }

  }

  console.log(Errors)

  return (
    <section className='afterBanForm pb-5'>
      {load ? <Loading /> :
        <div className='container-lg text-center px-lg-5'>
          {success ? <AppliedPopup setpopup={setsuccess} msg={msg} /> :
            <div className='formBox'>
              <h5 className='text-center mb-3 text-primary'>Admission Open</h5>
              <div name='admissionOpen'>
                <div className='row g-2 gy-lg-3 gx-lg-5'>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div className='mb-2 mb-md-0'>
                      <input id='name' type='text' className='form-control py-2' placeholder='Enter Name' required onChange={handleChange} onKeyUp={keyUp} />
                      <small className="logTxtDnger">{Errors.name}</small>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div className='mb-2 mb-md-0'>
                      <input id='email' type='email' className='form-control py-2' placeholder='Enter Email' required onChange={handleChange} onKeyUp={keyUp} />
                      <small className="logTxtDnger">{Errors.email}</small>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div className='mb-2 mb-md-0'>
                      <input id='mobile' type="number" className='form-control py-2' placeholder='Enter Mobile Number' required onChange={handleChange} onKeyUp={keyUp} />
                      <small className="logTxtDnger">{Errors.mobile}</small>
                    </div>
                  </div>

                  <div className="col-12 col-md-6 col-lg-4">
                    <select className="form-select" name="clas" id="clas" selected={fields['clas']} onChange={(e) => {
                      handleChange(e)
                      keyUp(e)
                    }}  >
                      <option disabled selected value> -- select class -- </option>
                      <option value="X">X</option>
                      <option value="XI">XI</option>
                      <option value="XII">XII</option>
                      <option value="NEET">NEET</option>
                    </select>
                    <small className="logTxtDnger">{Errors.clas}</small>
                  </div>


                  <div className='col-12 col-md-6 col-lg-4'>
                    <div className='mb-2 mb-md-0'>
                      <input id='location' type='text' className='form-control py-2' placeholder='Enter Location' required onChange={handleChange} onKeyUp={keyUp} />
                      <small className="logTxtDnger">{Errors.location}</small>
                    </div>
                  </div>
                  <div className='col-12 col-md-6 col-lg-4'>
                    <div className='d-grid'>
                      <button className='btn btn-primary py-2' onClick={handleSubmit}>Request a call back</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>}
        </div>}
      <div className='pt-4 text-center position-relative' style={{zIndex: '2'}}>
        <a href="/pdf/BJF-Stars-2024-26-Notification-for-Admission.pdf" download="BJF Stars 2024-26 Notification for Admission" 
          className="btn btn-outline-secondary text-white">
          BJF Stars 2024-26 Notification for Admission
        </a>
      </div>
    </section>
  )
}

export default AdmissionForm