import React, { useEffect } from 'react';
import BJFNavodayaBanner from './banner.js';
import CoachingResults from './coaching-result-table.js';
import Footer from '../../../Footer.js';


function BJFNavodaya() {
    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>

            <BJFNavodayaBanner />

            <section className='py-5 text-primary'>
                <div className='container-lg'>
                    <h3 className='pb-5 text-center'>BJF MISSION FOR "ADMISSIONS TO NAVODAYA SCHOOLS IN PUNJAB"</h3>

                    <div className='highLClassBox mb-5'>
                        <div className='row g-0'>
                            <div className='col-12 col-md-6'>
                                <div className='styleBox sbOne'>
                                    <p className='m-0'>For students of <span>Class 5</span></p>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>
                                <div className='styleBox sbTwo'>
                                    <p className='m-0'>to join at Navodaya <span>Class 6</span></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='mw800p px-0'>
                        <p className='mb-3 text-justify'>Under this program, Class 5 students of rural areas are given free coaching on Sundays &amp; all holidays for four months (50-60 days a year), for the entrance test to join Jawahar Navodaya Vidyalayas (JNVs) in Class 6. The purpose is to help students raise their general levels in Mathematics, Punjabi and Mental Ability and also deserving candidates to go to JNVs</p>
                        <p className='mb-3 text-justify'>Every year BJFI sets up training centers in clusters of villages where students get
                            free study material and special coaching on weekends and public holidays for in
                            Mathematics, Mental Ability and Language.</p>
                        <p className='mb-0 text-justify'>BJF Navodaya Mission has registered 14,653 students till 2023 out of which 1,031
                            have qualified the JNV Entrance. This year, 2675 students have been registered in
                            94 centers across 14 districts of Punjab.</p>
                    </div>
                </div>
            </section>

            <section className='grayBoxTB twoFold'>
                <div className='container-lg p-0 px-lg-3'>
                    <div className='row g-0 align-items-center'>
                        <div className='col-12 col-lg-6'>
                            <div className='p-4 py-lg-5'>
                                <h1 className='text-white'>Two fold objective<br />of BJFI</h1>
                            </div>
                        </div>
                        <div className='col-12 col-lg-6 text-primary'>
                            <div className='px-4 px-lg-5 py-lg-5'>
                                <div className='py-3'>
                                    <p><label className='d-inline-block uLStyle fw-bold'>For Bright students:</label></p>
                                    <p className='m-0'>Preparing them for competitive test conducted by CBSE for entrance at JNV, where the national success rate is 1:22</p>
                                </div>
                                <div className='py-4'>
                                    <p><label className='d-inline-block uLStyle fw-bold'>For Average students:</label></p>
                                    <p className='m-0'>It helps every student to strengthen their basic foundation in maths and science..</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='py-5 grayBoxTB text-primary'>
                <div className='container-lg p-0 px-lg-3'>
                    <div className='hrCaption'><span>BJFI has provided coaching from 2013-14 till now as follows</span></div>
                    <CoachingResults />
                    <p>BJFI supported students joining JNV (the first batch has reached grade 9) are in regular touch with the Foundation for mentoring and motivation. They are expected to be good candidates for BJFI's future two-year Comprehensive Programs.</p>
                </div>
            </section>            

            <section className='contLayout py-5 text-primary'>
                <div className='mw800p px-lg-3'>
                    <div className='row gx-lg-5 bdrV mb-0'>
                        <div className='col-12 col-lg-5'>
                            <div className='pe-lg-4'>
                                <img className='img-fluid' src='/admissions/BJF_Navodaya/cont-img.svg' alt="" />
                            </div>
                        </div>
                        <div className='col-12 col-lg-7'>
                            <div className='ps-lg-4'>
                                <p><strong>In the districts where BJF has set up centers, more than half of the seats in JNVs have gone to BJF supported rural children.</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <div className='text-center py-4'>
                <a href="/pdf/app-forms/v2/BJF-Navodaya-Application-Form.pdf" download="Application Form" class="btn btn-outline-secondary btn-lg">Click here to download the application form</a>
                <p class="mt-3 small text-mute">To apply for this program visit the campus onsite.</p>
            </div>


            <Footer />
        </div>
    )
}

export default BJFNavodaya