import React from 'react';

function LogSideBox () {
    let today = new Date();
    let yearStamp = today.getFullYear();

    return (
        <div>
            <section className='sideBoxLog'>
                <div className='floatedLogo'></div>
                <label className='copyStamp'>Copyright &copy; {yearStamp} Bhai Jaitajee Foundation India. All rights reserved.</label>
            </section>
        </div>
    )
}

export default LogSideBox