import React from 'react';


function BJFNavodayaBanner() {
    return (
        <div>
            <section className='py-5 navyBanner'>
                <div className='bannerWrap lg'>
                    <h1 className='mb-4'>BJF Navodaya Mission</h1>
                    <p className='small'>Jawahar Navodaya Vidyalayas (JNVs) are state-run free Residential Schools, one in every district of India (22 in Punjab). These are located in Rural areas and provide free education including board and lodging.</p>
                    <p className='small'>The annual intake in grade 6 in JNVs is 80 students per school. Considering that students selected in JNV schools get totally free schooling, boarding &amp; lodging, worth about Rs 60K to Rs 70K per year per child, each selected child benefits nearly Rs 5 lakhs during their 7-year stay at JNV Schools (6th to 12th class).</p>

                    <div className='py-4'>
                        <a download href="/pdf/app-forms/v2/BJF-Navodaya-Application-Form.pdf" className="btn btn-primary btn-lg">Click here to download the application form</a>
                    </div>
                    <p className='m-0 small text-yellow'>To apply for this program visit the campus onsite.</p>
                </div>
            </section>
        </div>
    )
}

export default BJFNavodayaBanner