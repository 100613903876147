import React from 'react';
import Footer from '../Footer.js';
import { useEffect } from 'react';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { useState } from 'react';
import { URL1 } from '../../config.js';
import { AppliedPop } from '../admissions/appliedPop.js';

function ContactUs() {
    const [load, setload] = useState(false)
    const [pop, setpop] = useState(false)
    const [msg, setmsg] = useState("")

    const [data, setdata] = useState({
        name : "" , 
        email : "" , 
        message: ""
    })

    const [Errors, setErrors] = useState({
        name : "" , 
        email : "" , 
        message: ""
    })

    async function submitIt(e){
        e.preventDefault()
        try {
            console.log(data , "DATAA")
            if(checkAll(setErrors , data)) {
                console.log("errorrrrrrrrrrrr")
                return 

            }
            setload(true)
            console.log(data , "DATAA")
            let res= await fetch(URL1+'/contant_us' ,{
                method: "POST" , 
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify(data)

            })
            let resJson = await res.json()
            if(res.status == 200){
                setmsg("Submit Successfully")
                setpop(true)
            }
            else{
                setmsg("Error Occured")
                setpop(true)
            }

            setload(false)

        } catch (error) {
            setmsg("Error Occured")
            setpop(true)
            setload(false)
        }
    }

    const checkValidation = (setErrors, data ,key) => {
        let tf=false
         if( data[key] =="" ){
            tf= true
          setErrors(p => (
            {
              ...p ,
                [key]: "This field is required"
              
            }
          ))                  
        }
        else if(key == 'name' && /^[A-Za-z\s]*$/.test(data[key]) ==false ){
            setErrors(p => (
              {
                ...p ,
                
                  [key]: "name can only have alphabets and space"
                
              }
            ))
            tf= true
          }
          else if (key == 'email' && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data[key]) ==false ){
            setErrors(p => (
              {
                ...p ,
                
                  [key]: "Please Enter valid Email"
                
              }
            ))
            tf= true
          }
          else{
            setErrors(p => (
              {
                ...p ,
                  [key]: ""
              }
            ))
        
          }
          console.log(tf , 'dd')
          return tf
        }

        const checkAll = (setErrors , data) => {
            let tf = false
            let ks = Object.keys(data)
            for (var k in ks ){
                if (checkValidation(setErrors , data , ks[k])){
                    tf= true 
                }
            }
            return tf
        }


    const keyUp = (e) => {
        let val=e.target.value
        let id = e.target.id;
        let dummy = {...data}
        dummy[id] = val
        
        checkValidation(setErrors, dummy, id)
      };
    
      function handleChange(e) {
        let val=e.target.value
        let id = e.target.id;
        let dummy = {...data}
        dummy[id] = val
        setdata(dummy)
    
      }

    useEffect(() => {
        document.body.className = 'whiteHdr';
        return () => { document.body.className = ''; }
    });

    return (
        <div className='inrPageTopPad'>            

            <section className='contLayout py-3 py-lg-5'>
                {pop  ? <AppliedPop setpopup={setpop}  msg={msg} /> : 
                <div className='container-lg'>
                    <div className='row g-4 gx-lg-5 px-2 px-lg-0'>
                        <div className='col-12 col-lg-4'>
                            <img className='img-fluid' src="/contact/contact-img.jpg" alt=""/>
                        </div>
                        <div className='col-12 col-lg-8'>
                            <h1 className='mb-5 contactTitle'>Contact Us</h1>
                            <div className='row g-4 gx-lg-5'>
                                <div className='col-12 col-lg-8'>
                                    <div className='contForm pe-lg-5'>
                                        <form name='contactForm'>
                                            <div className='mb-4'>
                                                <label>Full Name</label>
                                                <input id='name' value={data.name} type="text" className='txtFld' placeholder='Write your full name here' onKeyUp={keyUp} onChange={handleChange} />
                                                <small className="logTxtDnger">{Errors.name}</small>
                                            </div>
                                            <div className='mb-4'>
                                                <label>Email</label>
                                                <input id='email' value={data.email} type="text" className='txtFld' placeholder='Write your Email here' onKeyUp={keyUp} onChange={handleChange}  />
                                                <small className="logTxtDnger">{Errors.email}</small>
                                            </div>
                                            <div className='mb-4'>
                                                <label>Message</label>
                                                <textarea id='message' value={data.message} rows="3" cols="6" className='txtFld' placeholder='Add your message or doubt here' onKeyUp={keyUp} onChange={handleChange} ></textarea>
                                                <small className="logTxtDnger">{Errors.message}</small>
                                            </div>
                                            <div className='d-grid pt-4'>
                                                <button type='submit' className='btn btn-primary btn-lg rounded-pill' onClick={submitIt}>Contact Us</button>
                                            </div>
                                        </form>
                                    </div>                                    
                                </div>
                                <div className='col-12 col-lg-4 conactInfo'>
                                    <div className='mb-4'>
                                        <h3>Contact</h3>
                                        <p className='m-0'><a href="mailto:bjfindia@gmail.com">bjfindia@gmail.com</a></p>
                                        <p className='m-0'><a href="mailto:indiabjf@gmail.com">indiabjf@gmail.com</a></p>
                                        <p className='m-0'><a href="tel:01725078997">0172-5078997</a>, <a href="tel:01725078995">5078995</a></p>
                                    </div>
                                    <div className='mb-4'>
                                        <h3>Based in</h3>
                                        <p className='m-0'>SECOND FLOOR, SGGS BHAWAN, PLOT NO 1, MADHYA MARG, SECTOR 28 -A, CHANDIGARH, INDIA</p>
                                    </div>
                                    <div className='mb-4 pt-2 social'>
                                        <a href="http://facebook.com/bjfindia" target="_blank"><FacebookIcon /></a>
                                        <a href="https://in.linkedin.com/in/bjf-india-899471126" target="_blank"><LinkedInIcon /></a>
                                        <a href="https://twitter.com/bjfichd?lang=en" target="_blank"><TwitterIcon /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            </section>

            <Footer />
        </div>
    )
}

export default ContactUs