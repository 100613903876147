import React from 'react';


function BJFRepeatersBanner () {
    return (
        <div>
            <section className='py-5 text-center navyBanner'>
                <div className='bannerWrap'>
                    <h1 className='mb-4'>BJF REPEATERS</h1>
                    <div className='pt-4'>
                        <a href="/admission/apply-online2" className="btn btn-primary btn-lg">Apply Online</a>
                        <a href="/pdf/Download-Forms-2024/BJF Repeaters Batch Application Form.pdf" download="Application Form" class="btn btn-lg mx-3 btn-outline-secondary">Download Form</a>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BJFRepeatersBanner