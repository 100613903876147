import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { URL1 } from '../config'
import { useNavigate } from 'react-router-dom';
import React from "react";
import FileUploadIcon from '@mui/icons-material/FileUpload';


export const ExportPopup = ({setpop}) => {
    const Data = useSelector(state => state)
    let navigate = useNavigate()

    async function download_D(type) {
        let res = await fetch(URL1 + `/download_raw_data?type=${type}` , {
            method : "GET" , 
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + Data.auth["access_token"],
            },
          })
          
        let blb = await res.blob()
        const url = window.URL.createObjectURL(blb);
        window.open(url);
        setpop(false)
          
          
    }

    


    

  return (
    <div>
        <Modal
          size="md"
          show={true}
          onHide={() => setpop(false)}
          centered
          >
            <Modal.Body>              
              <div className='text-center'>
                <p><strong>Click on the required program below to export data:</strong></p>
                <Button className='mx-2 my-1' variant="primary" type="button" size="md" onClick={() => {
                    download_D('10')
                    }}>
                  <FileUploadIcon /> BJF Headstart
                </Button>

                <Button className='mx-2 my-1' variant="primary" type="button" size="md" onClick={() => {
                    download_D('11')
                    }}>
                  <FileUploadIcon /> BJF Stars
                </Button>

                <Button className='mx-2 my-1' variant="primary" type="button" size="md" onClick={() => {
                    download_D('neet')
                    }}>
                  <FileUploadIcon /> BJF Repeaters
                </Button>

               
                {/* <Button className='mx-3' variant="primary" type="button" size="lg" onClick={() => {
                    setpop(false)
                    }}>
                  D2
                </Button>
                <Button className='mx-3' variant="primary" type="button" size="lg" onClick={() => {
                    setpop(false)
                    }}>
                  D3
                </Button> */}
                
              </div>          
            </Modal.Body>
      </Modal> 
    </div>
  )
}
