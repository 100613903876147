import React from 'react';

function IndianDonation() {

    return (
        <div>  
            <div className='py-0'>
                <p>We will accept Indian Donation in our account with HDFC Bank Limited, Sector 8-C Chandigarh.</p>
                <p>Anyone who is willing to give donation through NEFT, please provide us with your address and PAN card number.</p>
                <div className='py-2'>
                    <img className='img-fluid' src="/donation/12ag-1107x1536.jpg" alt="" />
                </div>
                <p><span style={{backgroundColor: "#f4f59f"}}>The name of the foundation has been corrected as <strong>BHAI JAITAJEE FOUNDATION</strong></span></p>

                <p><strong>Bank Particulars are:</strong></p>
            
                <div className='pt-2'>
                    <table className='table w-auto spacing mb-0'>
                        <tr>
                            <td>Account Name:</td>
                            <td><strong className='text-primary'>Bhai Jaitajee Foundation</strong></td>
                        </tr>
                        <tr>
                            <td>Branch:</td>
                            <td><strong className='text-primary'>HDFC Bank Limited, SCO 78-79, Sector 8-C Chandigarh</strong></td>
                        </tr>
                        <tr>
                            <td>Account Number:</td>
                            <td><strong className='text-primary'>01071450000358</strong></td>
                        </tr>
                        <tr>
                            <td>IFSC Code:</td>
                            <td><strong className='text-primary'>HDFC0000107</strong></td>
                        </tr>
                        <tr>
                            <td>PAN:</td>
                            <td><strong className='text-primary'>AABTB6895K</strong></td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default IndianDonation